import React, { useRef, useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import Axe from "../assets/images/Axe.svg";

const AutocompleteGoogleInput = ({
  required,
  className,
  placeholder,
  onPlaceSelect,
  currentIndex,
  name,
  selectedValue,
}) => {
 
  const autocompleteRef = useRef(null);
  const handlePlaceSelect = (event, actionType) => {
    const autocompleteInstance = autocompleteRef.current;
    const place = autocompleteInstance?.getPlace();
    const lat = place?.geometry?.location.lat();
    const lng = place?.geometry?.location.lng();
    const address = place?.formatted_address;
    if (onPlaceSelect) {
      onPlaceSelect({ lat, lng, address }, event, currentIndex, name);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <form>
        <Autocomplete
          onLoad={(auto) => (autocompleteRef.current = auto)}
          onPlaceChanged={handlePlaceSelect}
        >
          <input
            defaultValue={selectedValue}
            required={required}
            type="area"
            className={className ? className : "google-autocomlete-input"}
            placeholder={placeholder}
            onChange={(e) => handlePlaceSelect(e)}
          />
        </Autocomplete>
        {/* <img
          onClick={(e) => handlePlaceSelect(e, "clear")}
          style={{ position: "absolute", right: "7px", bottom: "12px" }}
          src={Axe}
          alt="im"
        /> */}
        <div className="invalid-feedback font-11">
          Please enter your address
        </div>
      </form>
    </div>
  );
};

export default AutocompleteGoogleInput;
