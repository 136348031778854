import React, { useState, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import Form from "react-bootstrap/Form";
import ic_filter from "../../assets/images/ic_filter.svg";
import { Link } from "react-router-dom";
import approved from "../../assets/images/ic_completed.svg";
// import ic_pending from "../../assets/images/icons/ic_pending.svg";
// import ic_completed from "../../assets/images/ic_completed.svg";
import ic_right_arrow from "../../assets/images/ic_right_arrow.svg";

import ic_action from "../../assets/images/pet-shop-img/ActionArrow.svg";
import arrowleft from "../../assets/images/pet-shop-img/arrowleft.svg";
import RightArrowGrey from "../../assets/images/pet-shop-img/RightArrowGrey.svg";
import ProductList1 from "../../assets/images/pet-shop-img/ProductList (1).svg";
import ProductList2 from "../../assets/images/pet-shop-img/ProductList (2).svg";
import Review1 from "../../assets/images/pet-shop-img/Review (1).svg";
import Review2 from "../../assets/images/pet-shop-img/Review (3).svg";
import Review3 from "../../assets/images/pet-shop-img/Review (2).svg";
import no_review_icon from "../../assets/images/icons/no_review_icon.svg";
import green_check_icon from "../../assets/images/icons/green_check_icon.svg";

import cancel from "../../assets/images/ic_rejected.svg";
import pending from "../../assets/images/ic_pending.svg";
import Star from "../../assets/images/pet-shop-img/Star.svg";
import { Nav, Tab } from "react-bootstrap";
import cat from "../../assets/images/cat.png";
import { AppContext } from "../../context/AppContext";
import ApiConfig from "../../api/ApiConfig";
import {
  getWithAuthCall,
  simpleGetCallWithToken,
  simplePostAuthCall,
} from "../../api/ApiServices";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import Loader from "../../sharedComponent/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
export const getDate = (inputDate) => {
  let date = new Date(inputDate);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  var formattedDate = `${day < 10 ? 0 : ""}${day}-${month < 10 ? 0 : ""
    }${month}-${year}`;

  return formattedDate;
};
const Order = () => {
  const [appDetails, setAppDetails] = useState(false);
  const [deliviryDeatils, setDeliviryDeatils] = useState("All");
  console.log(appDetails);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const { setLoading, userData, loading, socket, subscriptionAPIPayload } = useContext(AppContext);
  console.log("subscriptionAPIPayload order,", subscriptionAPIPayload)
  const [page, setPage] = useState(1);
  const [last_page, setlast_page] = useState(false);
  const [TotalData, setTotalData] = useState(0);
  const [ProductNameFilter, setProductNameFilter] = useState("");
  const [BrandFilter, setProductBrandFilter] = useState("");
  const [OrderList, setOrderList] = useState([]);
  const [OrderDetails, setOrderDetails] = useState([]);
  const [payment_details, setpayment_details] = useState([]);
  const [Orderprescription, setOrderprescription] = useState({});
  const [OrderprescriptionTable, setOrderprescriptionTable] = useState([]);
  const [Orderproduct_summary, setOrderproduct_summary] = useState([]);
  const [OrderproductViste, setOrderproductViste] = useState([]);
  const [OrderproductVisteToday, setOrderproductVisteToday] = useState([]);

  const [OrderproductInvice, setOrderproductInvice] = useState([]);
  const [OrderproductReview, setOrderproductReview] = useState([]);
  const [OrderproductStatus, setOrderproductStatus] = useState([]);
  const [DorupDownStatus, setDorupDownStatus] = useState([]);
  const [StatusId, setStatusId] = useState("");
  const [TotalViste, SetTotalViste] = useState("");

  useEffect(() => {
    if (ProductNameFilter === "") {
      setLoading(true);
      getDetails(page);
    } else {
      getDetails(page);
    }
  }, [ProductNameFilter, BrandFilter]);

  const getDetails = (currentPage) => {
    simplePostAuthCall(
      ApiConfig.ORDER_DETALIS +
      userData.type_of_pet_shop_id +
      "&page=" +
      currentPage +
      "&filter=" +
      ProductNameFilter, JSON.stringify(subscriptionAPIPayload)
    )
      .then((data) => {
        // setClintDetails(data?.data);

        if (data.success) {
          setLoading(false);
          let total = data.total ? data.total : 0;
          setTotalData(total);
          setlast_page(data.last_page);

          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              animal_name: eachteam.animal_name || "",
              phone_number: eachteam.phone_number || "",
            };
          });

          if (currentPage === 1) {
            data.data && setOrderList(data.data);
            setLoading(false);
          } else {
            setOrderList([...OrderList, ...LearningData]);
            setLoading(false);
          }
        } else {
          setOrderList([]);
          setlast_page(false);
          notifyError(data.message)
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const getViste = (id) => {
    let newRequestBody = JSON.stringify({
      customer_id: id,
      pet_shop_id: Number(userData.type_of_pet_shop_id),
    });
    simplePostAuthCall(ApiConfig.ORDER_BY_VISTE, newRequestBody)
      .then((data) => {
        // setClintDetails(data?.data);

        if (data.success) {
          setOrderproductViste(data.history);
          setOrderproductVisteToday(data.todaysOrders);
          setOrderproductInvice(data.invoices);
          SetTotalViste(data.total);
        } else {
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDetailsByeId = (id) => {
    setLoading(true);

    setStatusId(id);
    simpleGetCallWithToken(
      ApiConfig.ORDER_DETALIS_BY_ID + id + "/" + userData.type_of_pet_shop_id
    )
      .then((data) => {
        if (data.success) {
          setOrderDetails(data.data.order_details);
          setpayment_details(data.data.payment_details);
          //   setOrderprescription(data?.prescription);
          //   setOrderprescriptionTable(data.prescription?.prescriptions);
          setOrderproduct_summary(data.product_summary);
          setOrderproductReview(data.data.review);
          setOrderproductStatus(data.status);
          getViste(data.data.customer_id);
          getSellTypes(id);
        } else {
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  useEffect(() => {
    getSellTypes();
  }, []);

  const getSellTypes = (id) => {
    getWithAuthCall(
      `${ApiConfig.GET_STATUS_DROPDOWN}?orderId=${id}&pharmacy_id=${userData.type_of_pet_shop_id}`
    )
      .then((res) => {
        if (res.success === false) {
          setDorupDownStatus([
            { status: "Select Status", status_id: "" },
          ]);
        } else {
          setDorupDownStatus([
            { status: "Select Status", status_id: "" },
            ...res.data,
          ]);
        }
      })
      .catch((err) => {
        console.log("catch error ,", err);
      });
  };

  const getStatusUpdate = (status_id) => {
    let newRequestBody = JSON.stringify({
      orderId: StatusId,
      pet_shop_registration_id: userData.pet_shop_registration_id,
      pet_shop_id: Number(userData.type_of_pet_shop_id),
      status: status_id,
    });
    simplePostAuthCall(ApiConfig.UPDATE_STATUS, newRequestBody)
      .then((data) => {
        if (data.success) {
          getSellTypes(StatusId);
          notifySuccess(data.message);
          getDetailsByeId(StatusId);
        } else {
          notifyError(data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPageLoad = () => {
    // debugger
    setPage(page + 1);
    if (last_page === false) {
      getDetails(page + 1);
    } else {
    }
  };
  // console.log("DorupDownStatus length,", DorupDownStatus.length);
  // console.log(
  //   "isSta,,,",
  //   DorupDownStatus.length === 1 && DorupDownStatus[0]?.status_id
  // );

  useEffect(() => {
    socket && socket.on("notification", (data) => {
      getDetails(page);
    })
  }, [])

  return (
    <InfiniteScroll
      dataLength={OrderList?.length && OrderList?.length}
      next={onPageLoad}
      // hasMore={true}
      hasMore={OrderList.length ? OrderList.length !== TotalData : false}
      scrollableTarget="scroll-table"
      loader={
        <h4
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginLeft: "20px",
          }}
        ></h4>
      }
    >
      {loading === true ? (
        <Loader />
      ) : (
        <motion.div
          variants={aninations}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.3 }}
          className="appointment-wrapper reports_wrapper"
          id="cx-main"
        >
          <div className="reportsTabs">
            {appDetails === false ? (
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <div className="search-filter-wrapper">
                    <div className="search-filter-left">
                      <input
                        type="text"
                        className="form-control serach-input1 me-4"
                        placeholder="Search by Name, ID..."
                        value={ProductNameFilter}
                        onChange={(e) => {
                          setProductNameFilter(e.target.value);
                        }}
                      />

                      {/* <button className="fltr-btn">
                        <img src={ic_filter} alt="" />
                      </button> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="table-wrapper table_wrapper_pagination dasboard-cust-tb-wrapper">
                    <table className="defaultTable">
                      <thead>
                        <tr>
                          <th>Sr No. </th>
                          <th>Order ID</th>
                          <th>Order Date</th>
                          <th>Customer</th>
                          <th>Item</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th className="center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {OrderList && OrderList.length > 0 ? (
                          OrderList.map((itemlist, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{itemlist.pet_shop_order_id}</td>
                                <td>{getDate(itemlist.pet_shop_order_date)}</td>
                                <td>{`${itemlist.first_name} ${itemlist.last_name}`}</td>
                                <td>{itemlist.no_of_items}</td>
                                <td>₹{itemlist.total_amount}</td>
                                {itemlist.status === "ready to dispatch" ? (
                                  <>
                                    <td className="status_complete">
                                      <img
                                        src={pending}
                                        className="checks tableImg"
                                        alt=""
                                      />
                                      ready to dispatch
                                    </td>
                                  </>
                                ) : itemlist.status === "order dispatched" ? (
                                  <td className="status_complete">
                                    <img
                                      src={pending}
                                      className="checks tableImg"
                                      alt=""
                                    />
                                    order dispatched
                                  </td>
                                ) : itemlist.status === "order placed" ||
                                  itemlist.status ===
                                  "delivery person assigned" ? (
                                  <>
                                    <td className="status_complete">
                                      <img
                                        src={approved}
                                        className="checks tableImg"
                                        alt=""
                                        v
                                      />
                                      {itemlist.status}
                                    </td>
                                  </>
                                ) : itemlist.status === "order cancelled" ? (
                                  <>
                                    <td className="status_complete">
                                      <img
                                        src={cancel}
                                        className="checks tableImg"
                                        alt=""
                                      />
                                      cancelled
                                    </td>
                                  </>
                                ) : itemlist.status === "order confirmed" ? (
                                  <>
                                    <>
                                      <td className="status_complete">
                                        <img
                                          src={approved}
                                          className="checks tableImg"
                                          alt=""
                                        />
                                        Order confirmed
                                      </td>
                                    </>
                                  </>
                                ) : itemlist.status === "order delivered" ? (
                                  <>
                                    <>
                                      <td className="status_complete">
                                        <img
                                          src={approved}
                                          className="checks tableImg"
                                          alt=""
                                        />
                                        Order delivered
                                      </td>
                                    </>
                                  </>
                                ) : (
                                  <>
                                    <td className="status_complete">
                                      <img
                                        src={pending}
                                        className="checks tableImg"
                                        alt=""
                                      />
                                      invalid
                                    </td>
                                  </>
                                )}
                                <td className="center">
                                  <Link
                                    onClick={() => {
                                      setAppDetails("complete");
                                      getDetailsByeId(
                                        itemlist.pet_shop_order_id
                                      );
                                    }}
                                  >
                                    <img src={ic_action} alt="" />
                                  </Link>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                    {OrderList.length ? (
                      ""
                    ) : (
                      <p
                        style={{
                          fontSize: "20px",
                          marginTop: "200px",
                        }}
                        className=" text-center justify-content-center align-items-center"
                      >
                        Oops! It doesn't look like there are any Order?
                      </p>
                    )}
                    {/* <div className="pagination_wrapper">
                                    <p>Showing 1-10 of 100</p>
                                </div> */}
                  </div>
                </div>
              </div>
            ) : (
              <div className="OrderInner">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="OrederInnerHead">
                      <img
                        src={arrowleft}
                        alt=""
                        onClick={() => {
                          setAppDetails(false);
                        }}
                      />
                      <p className="resultOrder">
                        Showing result for Order Id :<span>#{StatusId}</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-8 mt-4">
                    <div className="orderInnerTabSection">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="1"
                      >
                        <Nav
                          variant="pills"
                          id="newTabMai"
                          className="tob_nav_pills d-flex"
                        >
                          <Nav.Item>
                            <Nav.Link eventKey="1">Order Details</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="2">Status</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="3">Payment Details</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="4">Review & Ratings</Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="1">
                            <div className="innerContain">
                              <div className="row">
                                <div className="col-lg-6   borderLeft respFlexy">
                                  <table>
                                    {OrderDetails && OrderDetails.length > 0 ? (
                                      OrderDetails.map((itemlist, index) => {
                                        return (
                                          <>
                                            <tr>
                                              <td className="labelName">
                                                Order ID
                                              </td>
                                              <td className="ContainName">
                                                #{itemlist.pet_shop_order_id}{" "}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                Order Date
                                              </td>
                                              <td className="ContainName">
                                                {getDate(
                                                  itemlist.pet_shop_order_date
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                Delivery Date
                                              </td>
                                              <td className="ContainName">
                                                {getDate(
                                                  itemlist.delivery_date
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                No. of Items
                                              </td>
                                              <td className="ContainName">
                                                {itemlist.no_of_items}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName pb-0">
                                                Total Amount
                                              </td>
                                              <td className="ContainName pb-0">
                                                ₹{itemlist.total_amount}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </table>
                                </div>
                                <div className="col-lg-6 col-md-12  respFlexy">
                                  <table>
                                    {OrderDetails && OrderDetails.length > 0 ? (
                                      OrderDetails.map((itemlist, index) => {
                                        return (
                                          <>
                                            <tr>
                                              <td className="labelName">
                                                Customer Name
                                              </td>
                                              <td className="ContainName">
                                                {itemlist.customer_first_name}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                Contact Number
                                              </td>
                                              <td className="ContainName">
                                                {itemlist.contact_number}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                Email ID
                                              </td>
                                              <td className="ContainName">
                                                {itemlist.email_id}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                {/* Pet Type */}
                                              </td>
                                              <td className="ContainName">
                                                {/* NAn */}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName pb-0">
                                                {/* Pet Age */}
                                              </td>
                                              <td className="ContainName pb-0">
                                                {/* Nan */}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="2">
                            <div className="headerStatus">
                              <div className="orederID">
                                <p>Order Id</p>
                                <p className="orderNumber">#{StatusId}</p>
                              </div>

                              <div className="col-lg-6 d-flex justify-content-end">
                                <select
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      getStatusUpdate(e.target.value);
                                    }
                                  }}
                                  class="form-select"
                                  aria-label="Default select example"
                                  value={DorupDownStatus && DorupDownStatus.length ? DorupDownStatus[0].status_id : ""}
                                >
                                  {DorupDownStatus &&
                                    DorupDownStatus.length &&
                                    DorupDownStatus.map((sellType, index) => {
                                      return (
                                        <option
                                          key={"delivery" + index}
                                          value={sellType.status_id}
                                        >
                                          {sellType.status}
                                        </option>
                                      );
                                    })}
                                </select>
                                {/* <button className="cx-btn-dark">
                                        Update
                                      </button> */}
                              </div>
                            </div>
                            <div className="table-wrapper RemportTAbele table_wrapper_pagination dasboard-cust-tb-wrapper OrderHadingTabel p-0">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>User</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {OrderproductStatus &&
                                    OrderproductStatus.length > 0 ? (
                                    OrderproductStatus.map(
                                      (itemlist, index) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>{getDate(itemlist.date)}</td>
                                              <td>{itemlist.status}</td>
                                              <td>{itemlist.user}</td>
                                            </tr>
                                          </>
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="3">
                            <div className="innerContain">
                              <div className="row">
                                <div className="col-lg-6 borderLeft">
                                  <table>
                                    {payment_details &&
                                      payment_details.length > 0 ? (
                                      payment_details.map((itemlist, index) => {
                                        return (
                                          <>
                                            <tr>
                                              <td className="labelName">
                                                Order ID
                                              </td>
                                              <td className="ContainName">
                                                #{itemlist.pet_shop_order_id}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                Order Date
                                              </td>
                                              <td className="ContainName">
                                                {getDate(
                                                  itemlist.pet_shop_order_date
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                Delivery Date
                                              </td>
                                              <td className="ContainName">
                                                {getDate(
                                                  itemlist.delivery_date
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                No. of Items
                                              </td>
                                              <td className="ContainName">
                                                {itemlist.no_of_items}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName pb-0">
                                                Total Amount
                                              </td>
                                              <td className="ContainName pb-0">
                                                ₹{itemlist.total_amount}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </table>
                                </div>
                                <div className="col-lg-6 ps-4">
                                  <table>
                                    {payment_details &&
                                      payment_details.length > 0 ? (
                                      payment_details.map((itemlist, index) => {
                                        return (
                                          <>
                                            <tr>
                                              <td className="labelName">
                                                Mode Of Payment
                                              </td>
                                              <td className="ContainName">
                                                {itemlist.payment_mode}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                Transaction ID
                                              </td>
                                              <td className="ContainName">
                                                #{itemlist.transaction_id}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                Transaction Date
                                              </td>
                                              <td className="ContainName">
                                                {getDate(
                                                  itemlist.transaction_date
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                Payment Status
                                              </td>
                                              <td className="ContainName">
                                                {/* <img
                                      src={pending}
                                      className="checks tableImg"
                                      alt=""
                                    /> */}
                                                {itemlist.paymet_status}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="4">
                            {OrderproductReview &&
                              OrderproductReview.length > 0 ? (
                              OrderproductReview.map((itemlist, index) => {
                                return (
                                  <>
                                    <div className="review_ratings_wrapper">
                                      <div className="review_title_wrapper">
                                        <p className="review_title">
                                          {itemlist.review_title}
                                        </p>
                                        <div className="rating_star_wrapper">
                                          <img src={Star} alt="" />
                                          <span>{itemlist.rating}</span>
                                        </div>
                                      </div>
                                      <div className="review_description">
                                        <p className="review_text">
                                          {itemlist.review}
                                        </p>
                                        {/* <div className="review_img_wrapper">
                              <img src={review1} alt="" />
                              <img src={review2} alt="" />
                              <img src={review3} alt="" />
                              <img src={review4} alt="" />
                            </div> */}
                                      </div>
                                    </div>
                                    <div className="review_ratings_wrapper no_reviews">
                                      <img src={no_review_icon} alt="" />
                                      <p className="no_review_text">
                                        No Reviews Found...
                                      </p>
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                    <div className="ProductSummaryMain">
                      <div className="ProductSummaryHeading">
                        Product Summary{" "}
                      </div>
                      <div className="table-wrapper RemportTAbele table_wrapper_pagination dasboard-cust-tb-wrapper OrderHadingTabel p-0">
                        <table>
                          <thead>
                            <tr>
                              <th>Sr. No</th>
                              <th>Particular</th>
                              <th>Quantity</th>
                              <th>Rate</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Orderproduct_summary &&
                              Orderproduct_summary.length > 0 ? (
                              Orderproduct_summary.map((itemlist, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        <label htmlFor="" className="photo">
                                          <img
                                            src={itemlist.product_image_path}
                                            alt=""
                                          />

                                          {itemlist.product}
                                        </label>
                                      </td>
                                      <td>{itemlist.quantity}</td>
                                      <td>₹{itemlist.product_rate}</td>
                                      <td>₹{itemlist.amount}</td>
                                    </tr>
                                  </>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* {deliviryDeatils === "All" ?
                                    <div className="Button_Bottom">
                                        <button className="cx-btn-Light" onClick={() => { setDeliviryDeatils("DeliveryPending") }} >Reject</button>
                                        <button className="cx-btn-dark" onClick={() => { setDeliviryDeatils("DeliveryCencal") }} >Accept</button>
                                    </div> : null}
                                {deliviryDeatils === "DeliveryPending" ?
                                    <div className="DeliveryPending">
                                        <img src={pending} alt="" />
                                        <p>This order is in progress. Delivery Pending</p>
                                    </div> : null}
                                {deliviryDeatils === "DeliveryCencal" ?
                                    <div className="DeliveryCencal">
                                        <img src={cancel} alt="" />
                                        <p>This Order has been cancelled by the customer.</p>
                                    </div> : null} */}
                  </div>
                  <div className="col-lg-4 mt-4">
                    <div className="right">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="1"
                      >
                        <Nav
                          variant="pills"
                          id="newTabMai"
                          className="tob_nav_pills"
                        >
                          <Nav.Item>
                            <Nav.Link eventKey="1">
                              Previous Orders <span>({TotalViste})</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="2">
                              Invoices <span>({TotalViste})</span>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="1">
                            <div className="visits-tab-wrapper">
                              <div className="today-heading">
                                <label htmlFor="" className="key">
                                  Today
                                </label>
                                <div className="line"></div>
                              </div>
                              <>
                                {OrderproductVisteToday &&
                                  OrderproductVisteToday.length > 0 ? (
                                  OrderproductVisteToday.map(
                                    (itemlist, index) => {
                                      return (
                                        <>
                                          <div className="app-time-date active mainBoxy">
                                            {itemlist.visits &&
                                              itemlist.visits.length > 0 ? (
                                              itemlist.visits.map(
                                                (list, index) => {
                                                  return (
                                                    <>
                                                      <div
                                                        className="left-app-time-date boxyFormat"
                                                        onClick={() => {
                                                          getDetailsByeId(
                                                            list.pet_shop_order_id
                                                          );
                                                        }}
                                                      >
                                                        <div className="firstDiv">
                                                          <label
                                                            htmlFor=""
                                                            className="value"
                                                          >
                                                            Order ID : #
                                                            {
                                                              list.pet_shop_order_id
                                                            }
                                                          </label>
                                                          <br />
                                                          <label
                                                            htmlFor=""
                                                            className="status"
                                                          >
                                                            ₹{list.total_amount}{" "}
                                                            |{" "}
                                                            <img
                                                              src={
                                                                green_check_icon
                                                              }
                                                              alt=""
                                                            />{" "}
                                                            {list.status}
                                                          </label>
                                                        </div>
                                                        <div className="right-app-time-date">
                                                          <img
                                                            src={ic_right_arrow}
                                                            alt=""
                                                          />
                                                        </div>
                                                        {/* <div>
                                                        </div> */}
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <>No data </>
                                            )}
                                          </div>
                                        </>
                                      );
                                    }
                                  )
                                ) : (
                                  <p className="scheule_tody">
                                    No Schedule Today
                                  </p>
                                )}

                                {OrderproductViste &&
                                  OrderproductViste.length > 0 ? (
                                  OrderproductViste.map((itemlist, index) => {
                                    return (
                                      <>
                                        <div className="today-heading">
                                          <label
                                            htmlFor=""
                                            className="key line-style"
                                          >
                                            {getDate(itemlist.visit_date)}
                                          </label>

                                          {/* <div className="line"></div> */}
                                        </div>

                                        <div className="scrollerTabs">
                                          {itemlist.visits &&
                                            itemlist.visits.length > 0 ? (
                                            itemlist.visits.map((list, index) => {
                                              return (
                                                <>
                                                  <div
                                                    className="app-time-date"
                                                    onClick={() => {
                                                      getDetailsByeId(
                                                        list.pet_shop_order_id
                                                      );
                                                    }}
                                                  >
                                                    <div className="left-app-time-date">
                                                      <label
                                                        htmlFor=""
                                                        className="value"
                                                      >
                                                        Order ID : #
                                                        {list.pet_shop_order_id}
                                                      </label>
                                                      <div>
                                                        <label
                                                          htmlFor=""
                                                          className="status"
                                                        >
                                                          ₹ {list.total_amount} |{" "}
                                                          <img
                                                            src={green_check_icon}
                                                            alt=""
                                                          />{" "}
                                                          {list.status}
                                                        </label>
                                                      </div>
                                                    </div>
                                                    <div className="right-app-time-date">
                                                      <img
                                                        src={ic_right_arrow}
                                                        alt=""
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            })
                                          ) : (
                                            <></>
                                          )}
                                        </div >
                                      </>
                                    );
                                  })
                                ) : (
                                  <>
                                    <p className="scheule_tody">No history</p>
                                  </>
                                )}
                              </>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="2">
                            <div className="invoices-tab-wrapper">
                              <div className="table-wrapper heightCalc">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Sr. No</th>
                                      <th>Date</th>
                                      <th>Amount</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {OrderproductInvice &&
                                      OrderproductInvice.length > 0 ? (
                                      OrderproductInvice.map(
                                        (itemlist, index) => {
                                          return (
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>
                                                {getDate(itemlist.visit_date)}
                                              </td>
                                              <td>₹{itemlist.total_amount}</td>
                                              <td>
                                                {/* <img
                                                        src={pending}
                                                        className="status-ic"
                                                        alt=""
                                                      /> */}
                                                <label
                                                  htmlFor=""
                                                  className="pending-txt"
                                                >
                                                  {itemlist.status}
                                                </label>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </motion.div>
      )}
    </InfiniteScroll>
  );
};

export default Order;
