import React, { useState } from "react";
import yourLogo from "../../assets/images/mainLogo.svg";
import edu_icon from "../../assets/images/icons/edu_icon.svg";
import bank_icon from "../../assets/images/icons/bank_icon.svg";
import bank_img from "../../assets/images/pet-shop-img/wallet-front-color.svg";
import UploadPP_icon from "../../assets/images/UploadPP_icon.svg";
import { DarkButton } from "../commonComponents/commonComp";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { simplePostAuthCallNoTokan } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifySuccess } from "../../sharedComponent/notify";
import Form from "react-bootstrap/Form";

const BankDetails = () => {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState();
  const [signature, setSignature] = useState();
  const [deleteImg, setDeleteImg] = useState(true);
  const [bankData, setBankData] = useState({
    pet_shop_registration_id: localStorage.getItem("pet_shop_registration_id"),
    bank_name: "",
    account_number: null,
    account_holder: "",
    ifsc_code: null,
  });
  const changeEveValue = (e) => {
    setBankData({
      ...bankData,
      [e.target.name]: e.target.value,
    });
  };
  function handleFileInput(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  function handleFileInput2(e) {
    setSignature(URL.createObjectURL(e.target.files[0]));
    setDeleteImg(true);
  }

  const handleDeleteImg = () => {
    setDeleteImg(!deleteImg);
  };

  const options = [
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
    { value: "Marathi", label: "Marathi" },
    { value: "Urdu", label: "Urdu" },
  ];

  const [selected, setSelected] = useState(null);

  const handleChange = (e) => {
    setSelected(e.value);
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      adBankDetails(event);
    }
    setValidated(true);
  };

  const adBankDetails = (event) => {
    event.preventDefault();
    let payload = {
      pet_shop_registration_id: bankData.pet_shop_registration_id,
      bank_name: bankData.bank_name,
      account_number: bankData.account_number,
      account_holder: bankData.account_holder,
      ifsc_code: bankData.ifsc_code,
    };
    simplePostAuthCallNoTokan(
      ApiConfig.ADD_BANK_DETAILS,
      JSON.stringify(payload)
    ).then((res) => {
      if (res.success === true) {
        notifySuccess(res.message);
        navigate("/ClinicInfo");
      }
    });
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>

        <div className="authMain">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <img src={yourLogo} className="logoImg" alt="" />
              <div className="signinForm signUp general_info">
                <h1 className="signInTxt">Bank Details</h1>

                <Form.Group className="inputBox">
                  <Form.Label htmlFor="" className="emailLabel">
                    Bank
                  </Form.Label>
                  <img src={bank_icon} className="mailImg" alt="" />
                  {/* <Form.Select
                    required
                    name="bank_name"
                    value={bankData.bank_name}
                    onChange={changeEveValue}
                    className="form-select formInput"
                  >
                    <option value="">Select Bank</option>
                    <option value="SBI">SBI</option>
                    <option value="HDFC">HDFC</option>
                    <option value="IDBI">IDBI</option>
                  </Form.Select> */}
                  <Form.Control
                    required
                    name="bank_name"
                    value={bankData.bank_name}
                    onChange={changeEveValue}
                    className="formInput"
                    placeholder="Enter Bank Name"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Provide Bank Name
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="inputBox">
                  <Form.Label htmlFor="" className="emailLabel">
                    Account Number
                  </Form.Label>
                  <img src={bank_icon} className="mailImg" alt="" />
                  <Form.Control
                    type="text"
                    required
                    name="account_number"
                    value={bankData.account_number}
                    onChange={changeEveValue}
                    className="form-control formInput"
                    placeholder="Enter your  account number"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Provide Account Number
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="inputBox">
                  <Form.Label htmlFor="" className="emailLabel">
                    Account Holder Name
                  </Form.Label>
                  <img src={bank_icon} className="mailImg" alt="" />
                  <Form.Control
                    required
                    name="account_holder"
                    value={bankData.account_holder}
                    onChange={changeEveValue}
                    type="text"
                    className="form-control formInput"
                    placeholder="Enter your acoount holder's name"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Provide Account Holder Name
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="inputBox">
                  <Form.Label htmlFor="" className="emailLabel">
                    IFSC / SWIFT Code
                  </Form.Label>
                  <img src={bank_icon} className="mailImg" alt="" />
                  <Form.Control
                    required
                    name="ifsc_code"
                    value={bankData.ifsc_code}
                    onChange={changeEveValue}
                    type="text"
                    className="form-control formInput"
                    placeholder="Enter IFSC / SWIFT Code"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Provide IFSC / SWIFT Code
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="login btn-width-100">
                  <DarkButton
                    onClick={() => navigate("/SignUp")}
                    classbtn={
                      "cx-btn-light me-3  btn-width-45 btn-height font-17 mb-5"
                    }
                    btn={"Back"}
                  />
                  <DarkButton
                    // onClick={() => navigate("/ShopInfo")}
                    type="submit"
                    classbtn={
                      "cx-btn-dark  btn-height btn-width-50 font-17 mb-5"
                    }
                    btn={"Save & Proceed"}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="landingImg">
                <p className="contactNumber">+91 9509932234</p>
                <img src={bank_img} alt="" />
                <p className="containstart">Banking Details</p>
                <p className="letsstart">Share to collect fees !</p>
              </div>
            </div>
          </div>
        </div>
      </Form>

    </>
  );
};

export default BankDetails;
