import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import earn_icon from "../../assets/images/icons/earn_icon.svg";
import patient_icon from "../../assets/images/icons/patient_icon.svg";
import total_appoint_icon from "../../assets/images/icons/total_appoint_icon.svg";
import complete_icon from "../../assets/images/icons/complete_icon.svg";
import cancelled_icon from "../../assets/images/icons/cancelled_icon.svg";
import revenue_icon from "../../assets/images/icons/revenue_icon.svg";
import export_icon from "../../assets/images/icons/export_icon.svg";
import ic_completed from "../../assets/images/ic_completed.svg";
import InProcessReport from "../../assets/images/pet-shop-img/InProcessReport.svg";
import ic_rejected from "../../assets/images/ic_rejected.svg";
import cat from "../../assets/images/cat.png";
import { motion } from "framer-motion";
import { DonutChart } from "../commonComponents/commonComp";
import { Link } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import {
  simpleGetCallWithToken,
  simplePostAuthCall,
} from "../../api/ApiServices";
import { AppContext } from "../../context/AppContext";
import cancel from "../../assets/images/ic_rejected.svg";
import pending from "../../assets/images/ic_pending.svg";
import ic_pending from "../../assets/images/ic_pending.svg";
import Loader from "../../sharedComponent/Loader";
import Chart from "react-apexcharts";
import moment from "moment";

const SalesReports = () => {
  const { setLoading, userData, loading } = useContext(AppContext);
  const { user_id } = userData;
  const [currentTab, setCurrentTab] = useState("1");

  const [viewChange, setviewChange] = useState(true);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const [customersdetails, setcustomersdetails] = useState({});
  const [customersdetailsmt, setcustomersdetailsmt] = useState({});
  const [TotalData, setTotalData] = useState({});
  const [TotalDatamt, setTotalDatamt] = useState({});
  const [customersdetailsList, setcustomersdetailsList] = useState([]);
  const [customersdetailsListmt, setcustomersdetailsListmt] = useState([]);
  const todaysDate = new Date();
  useEffect(() => {
    getReportDetails();
    getReportDetailsMonthe();
    fetchPaymentAnalytics(todaysDate, todaysDate);
  }, []);

  const getReportDetails = () => {
    setLoading(true);
    let newRequestBody = JSON.stringify({
      pet_shop_id: userData.type_of_pet_shop_id,
    });

    simplePostAuthCall(ApiConfig.SELES_DALIY_REPORT, newRequestBody)
      .then((res) => {
        if (res.success) {
          setcustomersdetails(res.customers);
          setcustomersdetailsList(res.customersList);
          setTotalData(res.data);
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getReportDetailsMonthe = () => {
    setLoading(true);
    let newRequestBody = JSON.stringify({
      pet_shop_id: userData.type_of_pet_shop_id,
    });

    simplePostAuthCall(ApiConfig.SELES_MONTHE_REPOT, newRequestBody)
      .then((res) => {
        if (res.success) {
          setcustomersdetailsmt(res.customers);
          setcustomersdetailsListmt(res.customersList);
          setTotalDatamt(res.data);
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [paymentAnalytics, setpaymentAnalytics] = useState({
    withdrawPercentage: 0,
    receivedPercentyage: 0,
  });

  const tempArr = Object.keys(paymentAnalytics).map((key) =>
    Number(paymentAnalytics[key])
  );
  const arr = tempArr && tempArr.map((ele) => parseFloat(ele.toFixed(2)));

  const [donut, setDonut] = useState({
    options: {
      dataLabels: {
        enabled: false,
      },
      colors: ["#FFB200", "#01B529", "#5500DF"],
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
      labels: ["Withdrawl", "Received"],
    },
    series: arr,

    style: {
      // colors: ["#FFB200", "#01B529", "#5500DF"],
    },
  });

  const fetchPaymentAnalytics = async (fromDate, toDate) => {
    // FETCH_PAYMENT_ANALYTICS_DETAILS
    const userId = user_id && user_id?.split("_")[1];
    const paymentAnalyticsPayload = {
      user_id: userId,
      from_date: moment(fromDate).format("YYYY-MM-DD"),
      to_date: moment(toDate).format("YYYY-MM-DD"),
    };
    try {
      const res = await simplePostAuthCall(
        `${ApiConfig.FETCH_PAYMENT_ANALYTICS_DETAILS}`,
        JSON.stringify(paymentAnalyticsPayload)
      );

      const { success, data } = res;
      if (success && data) {
        setpaymentAnalytics({
          withdrawPercentage: data?.WithdrawPercentage,
          receivedPercentyage: data?.ReceviedPercentage,
        });
      }
      // const  {}
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.3 }}
      className="appointment-wrapper reports_wrapper"
      id="cx-main"
    >
      {loading === true ? (
        <Loader />
      ) : (
        <div className="reportsTabs">
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <Nav
              variant="pills"
              id="newTabMai"
              className="tob_nav_pills d-flex"
              onSelect={(selectedKey) => setCurrentTab(`${selectedKey}`)}
            >
              <Nav.Item>
                <Nav.Link
                  eventKey="1"
                  onClick={() => {
                    fetchPaymentAnalytics(todaysDate, todaysDate);
                  }}
                >
                  Daily
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="2"
                  onClick={() => {
                    const firstDayOfMonth = new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      1
                    );
                    const lastDayOfMonth = new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() + 1,
                      0
                    );
                    fetchPaymentAnalytics(firstDayOfMonth, lastDayOfMonth);
                  }}
                >
                  Monthly
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Link to="#" className="export_button">
              <img src={export_icon} alt="" />
            </Link>
            <Tab.Content>
              <Tab.Pane eventKey="1">
                <div className="dashboard_wrapper" id="reports-wrapper">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dashboard_statistics_wrapper">
                            <div className="CustonTonSectionMain">
                              <div className="CustonTonSection">
                                <div className="stat_item">
                                  <img src={total_appoint_icon} />
                                  <div className="stat_text stat_4">
                                    <label>Total Order's</label>
                                    <p className="custom_color">
                                      {TotalData.total_orders}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="CustonTonSection">
                                <div className="stat_item">
                                  <img src={complete_icon} />
                                  <div className="stat_text stat_1">
                                    <label>Completed</label>
                                    <p>{TotalData.completed}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="CustonTonSection">
                                <div className="stat_item">
                                  <img src={InProcessReport} />
                                  <div className="stat_text stat_YO">
                                    <label>Pending</label>
                                    <p>{TotalData.pending}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="CustonTonSection">
                                <div className="stat_item">
                                  <img src={cancelled_icon} />
                                  <div className="stat_text stat_5">
                                    <label>Cancelled</label>
                                    <p className="custom_color1">
                                      {TotalData.cancelled}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="CustonTonSection">
                                <div className="stat_item">
                                  <img src={revenue_icon} />
                                  <div className="stat_text stat_6">
                                    <label>Total Revenue</label>
                                    <p>₹{TotalData.total_revenue}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 mb-3">
                      <div className="dashboard_wrapperreport">
                        <div className="table-wrapper table_wrapper_pagination dasboard-cust-tb-wrapper">
                          <table>
                            <thead>
                              <tr>
                                <th>Sr. No</th>
                                <th>Name</th>
                                <th>Transaction ID</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {customersdetailsList &&
                                customersdetailsList.length > 0 ? (
                                customersdetailsList.map((itemlist, index) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          <label htmlFor="" className="photo">
                                            <img
                                              src={itemlist.profile_image_path}
                                              alt=""
                                            />
                                            {`${itemlist.first_name} ${itemlist.last_name}`}
                                          </label>
                                        </td>
                                        <td>{itemlist.transaction_id}</td>
                                        <td>{itemlist.date}</td>
                                        <td>₹{itemlist.total_amount}</td>
                                        <td>
                                          {/* <img
                                  src={ic_completed}
                                  className="status-ic"
                                  alt=""
                                /> */}
                                          {/* {itemlist.status} */}
                                          {itemlist.status ===
                                            "ready to dispatch" ||
                                            itemlist.status ===
                                            "order dispatched" ? (
                                            <>
                                              <td className="status_complete">
                                                <img
                                                  src={ic_pending}
                                                  className="checks tableImg"
                                                  alt=""
                                                />
                                                ready to dispatch
                                              </td>
                                            </>
                                          ) : itemlist.status ===
                                            "order placed" ||
                                            itemlist.status ===
                                            "delivery person assigned" ? (
                                            <>
                                              <td className="status_complete">
                                                <img
                                                  src={ic_completed}
                                                  className="checks tableImg"
                                                  alt=""
                                                />
                                                Pending
                                              </td>
                                            </>
                                          ) : itemlist.status ===
                                            "order cancelled" ? (
                                            <>
                                              <td className="status_complete">
                                                <img
                                                  src={cancel}
                                                  className="checks tableImg"
                                                  alt=""
                                                />
                                                cancelled
                                              </td>
                                            </>
                                          ) : itemlist.status ===
                                            "order confirmed" ? (
                                            <>
                                              <>
                                                <td className="status_complete">
                                                  <img
                                                    src={ic_completed}
                                                    className="checks tableImg"
                                                    alt=""
                                                  />
                                                  Order confirmed
                                                </td>
                                              </>
                                            </>
                                          ) : itemlist.status ===
                                            "order delivered" ? (
                                            <>
                                              <>
                                                <td className="status_complete">
                                                  <img
                                                    src={ic_completed}
                                                    className="checks tableImg"
                                                    alt=""
                                                  />
                                                  Order delivered
                                                </td>
                                              </>
                                            </>
                                          ) : (
                                            <>
                                              <td className="status_complete">
                                                <img
                                                  src={pending}
                                                  className="checks tableImg"
                                                  alt=""
                                                />
                                                invalid
                                              </td>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                          {customersdetailsList.length ? (
                            ""
                          ) : (
                            <p
                              style={{
                                fontSize: "20px",
                                marginTop: "200px",
                              }}
                              className=" text-center justify-content-center align-items-center"
                            >
                              Oops! It doesn't look like there are any Sales
                              Report?
                            </p>
                          )}
                        </div>
                        {/* <div className="pagination_wrapper">
                        <p>Showing 1-10 of 100</p>
                      </div> */}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="patient_stat_wrapper">
                        <div className="lc_top">
                          <div className="lct_header_wrapper">
                            <p>Customer’s</p>
                          </div>
                          <div className="pt_stat_item pts_1">
                            <div className="row">
                              <div className="col-md-4 col-sm-4">
                                <div className="left">
                                  <label htmlFor="">New Customers</label>
                                  <p>{customersdetails.new_customers}</p>
                                </div>
                              </div>
                              <div className="col-md-8 col-sm-8">
                                <div className="right">
                                  <div class="progress">
                                    <div
                                      class="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="15"
                                      aria-valuemin="0"
                                      aria-valuemax="0"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pt_stat_item pts_2">
                            <div className="row">
                              <div className="col-md-4 col-sm-4">
                                <div className="left">
                                  <label htmlFor="">Old Customers</label>
                                  <p>{customersdetails.old_customers}</p>
                                </div>
                              </div>
                              <div className="col-md-8 col-sm-8">
                                <div className="right">
                                  <div class="progress">
                                    <div
                                      class="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="10"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {arr.some((element) => element !== 0) && (
                        <div className="donut_chart_wrapper">
                          <div className="dc_header">
                            <p>Payment Analytics</p>
                          </div>
                          {/* <DonutChart
                          arr={arr}
                        /> */}
                          <div>
                            <Chart
                              options={donut.options}
                              series={arr}
                              type="donut"
                              // width="280"
                              height="250"
                            />
                          </div>
                          <div className="Chart_labels belowMgNon">
                            <div className="cl_item">
                              <p>Received</p>
                              <p>
                                {paymentAnalytics?.receivedPercentyage?.toFixed(
                                  2
                                )}
                                %
                              </p>
                            </div>
                            {/* <div className="cl_item">
                              <p>Subscriptions</p>
                              <p>&#8377;46</p>
                            </div> */}
                            <div className="cl_item">
                              <p>Withdrawl</p>
                              <p>
                                {paymentAnalytics?.withdrawPercentage?.toFixed(
                                  2
                                )}
                                %
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="2">
                <div className="dashboard_wrapper">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="dashboard_statistics_wrapper">
                            <div className="CustonTonSectionMain">
                              <div className="CustonTonSection">
                                <div className="stat_item">
                                  <img src={total_appoint_icon} />
                                  <div className="stat_text stat_4">
                                    <label>Total Order's</label>
                                    <p className="custom_color">
                                      {TotalDatamt.total_orders}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="CustonTonSection">
                                <div className="stat_item">
                                  <img src={complete_icon} />
                                  <div className="stat_text stat_1">
                                    <label>Completed</label>
                                    <p>{TotalDatamt.completed}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="CustonTonSection">
                                <div className="stat_item">
                                  <img src={InProcessReport} />
                                  <div className="stat_text stat_YO">
                                    <label>Pending</label>
                                    <p>{TotalDatamt.pending}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="CustonTonSection">
                                <div className="stat_item">
                                  <img src={cancelled_icon} />
                                  <div className="stat_text stat_5">
                                    <label>Cancelled</label>
                                    <p className="custom_color1">
                                      {TotalDatamt.cancelled}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="CustonTonSection">
                                <div className="stat_item">
                                  <img src={revenue_icon} />
                                  <div className="stat_text stat_6">
                                    <label>Total Revenue</label>
                                    <p>₹{TotalDatamt.total_revenue}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 mb-3">
                      <div className="dashboard_wrapperreport">
                        <div className="table-wrapper table_wrapper_pagination dasboard-cust-tb-wrapper">
                          <table>
                            <thead>
                              <tr>
                                <th>Sr. No</th>
                                <th>Name</th>
                                <th>Transaction ID</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {customersdetailsListmt &&
                                customersdetailsListmt.length > 0 ? (
                                customersdetailsListmt.map(
                                  (itemlist, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          <label htmlFor="" className="photo">
                                            <img
                                              src={itemlist.profile_image_path}
                                              alt=""
                                            />
                                            {`${itemlist.first_name} ${itemlist.last_name}`}
                                          </label>
                                        </td>
                                        <td>{itemlist.transaction_id}</td>
                                        <td>{itemlist.date}</td>
                                        <td>₹{itemlist.total_amount}</td>
                                        <td>
                                          {/* <img
                                  src={ic_completed}
                                  className="status-ic"
                                  alt=""
                                /> */}
                                          {/* {itemlist.status} */}
                                          {itemlist.status ===
                                            "ready to dispatch" ||
                                            itemlist.status ===
                                            "order dispatched" ? (
                                            <>
                                              <td className="status_complete">
                                                <img
                                                  src={ic_pending}
                                                  className="checks tableImg"
                                                  alt=""
                                                />
                                                ready to dispatch
                                              </td>
                                            </>
                                          ) : itemlist.status ===
                                            "order placed" ||
                                            itemlist.status ===
                                            "delivery person assigned" ? (
                                            <>
                                              <td className="status_complete">
                                                <img
                                                  src={ic_completed}
                                                  className="checks tableImg"
                                                  alt=""
                                                />
                                                Pending
                                              </td>
                                            </>
                                          ) : itemlist.status ===
                                            "order cancelled" ? (
                                            <>
                                              <td className="status_complete">
                                                <img
                                                  src={cancel}
                                                  className="checks tableImg"
                                                  alt=""
                                                />
                                                cancelled
                                              </td>
                                            </>
                                          ) : itemlist.status ===
                                            "order confirmed" ? (
                                            <>
                                              <>
                                                <td className="status_complete">
                                                  <img
                                                    src={ic_completed}
                                                    className="checks tableImg"
                                                    alt=""
                                                  />
                                                  Order confirmed
                                                </td>
                                              </>
                                            </>
                                          ) : itemlist.status ===
                                            "order delivered" ? (
                                            <>
                                              <>
                                                <td className="status_complete">
                                                  <img
                                                    src={ic_completed}
                                                    className="checks tableImg"
                                                    alt=""
                                                  />
                                                  Order delivered
                                                </td>
                                              </>
                                            </>
                                          ) : (
                                            <>
                                              <td className="status_complete">
                                                <img
                                                  src={pending}
                                                  className="checks tableImg"
                                                  alt=""
                                                />
                                                invalid
                                              </td>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                          {customersdetailsListmt.length ? (
                            ""
                          ) : (
                            <p
                              style={{
                                fontSize: "20px",
                                marginTop: "200px",
                              }}
                              className=" text-center justify-content-center align-items-center"
                            >
                              Oops! It doesn't look like there are any Sales
                              Report?
                            </p>
                          )}
                        </div>
                        {/* <div className="pagination_wrapper">
                        <p>Showing 1-10 of 100</p>
                      </div> */}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="patient_stat_wrapper">
                        <div className="lc_top">
                          <div className="lct_header_wrapper">
                            <p>Customer’s</p>
                          </div>
                          <div className="pt_stat_item pts_1">
                            <div className="row">
                              <div className="col-md-4 col-sm-4">
                                <div className="left">
                                  <label htmlFor="">New Customers</label>
                                  <p>{customersdetailsmt.new_customers}</p>
                                </div>
                              </div>
                              <div className="col-md-8 col-sm-8">
                                <div className="right">
                                  <div class="progress">
                                    <div
                                      class="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="15"
                                      aria-valuemin="0"
                                      aria-valuemax="0"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pt_stat_item pts_2">
                            <div className="row">
                              <div className="col-md-4 col-sm-4">
                                <div className="left">
                                  <label htmlFor="">Old Customers</label>
                                  <p>{customersdetailsmt.old_customers}</p>
                                </div>
                              </div>
                              <div className="col-md-8 col-sm-8">
                                <div className="right">
                                  <div class="progress">
                                    <div
                                      class="progress-bar"
                                      role="progressbar"
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="10"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {arr.some((element) => element !== 0) && (
                        <div className="donut_chart_wrapper">
                          <div className="dc_header">
                            <p>Payment Analytics</p>
                          </div>
                          {/* <DonutChart
                          arr={arr}
                        /> */}
                          <div>
                            <Chart
                              options={donut.options}
                              series={arr}
                              type="donut"
                              // width="280"
                              height="250"
                            />
                          </div>
                          <div className="Chart_labels belowMgNon">
                            <div className="cl_item">
                              <p>Received</p>
                              <p>
                                {paymentAnalytics?.receivedPercentyage?.toFixed(
                                  2
                                )}
                                %
                              </p>
                            </div>
                            {/* <div className="cl_item">
                              <p>Subscriptions</p>
                              <p>&#8377;46</p>
                            </div> */}
                            <div className="cl_item">
                              <p>Withdrawl</p>
                              <p>
                                {paymentAnalytics?.withdrawPercentage?.toFixed(
                                  2
                                )}
                                %
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      )}
    </motion.div>
  );
};

export default SalesReports;
