import { useState, useEffect } from 'react';

const useImageLoader = (imagePath, defaultImage) => {
  const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const image = new Image();
    image.src = imagePath;

    image.onload = () => {
      setLoading(false);
      setImageSrc(imagePath);
    };

    image.onerror = () => {
      setLoading(false);
    //   setImageSrc(defaultImage);
    };

    return () => {
      image.onload = null;
      image.onerror = null;
    };
  }, [imagePath, defaultImage]);

  return { loading, imageSrc };
};

export default useImageLoader;
