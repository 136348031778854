
import { BASE_URL } from "../../api/ApiConfig";
import { simplePostAuthCall, simplePostCall } from "../../api/ApiServices";
import mainLogo from "../../assets/images/headerIcons/mainLogo.svg";
import { notifyError } from "../../sharedComponent/notify";
import { loadCheckoutScript } from "./loadScript";

export const checkoutHandler = async (amount, orderDetails, type, callBack) => {
  const orderAmt = Number(amount);
  try {
    const scriptLoaded = await loadCheckoutScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!scriptLoaded) {
      alert("Please check your internet connection or reload the page");
      return;
    }
 
    const api = `${BASE_URL}api/paymentRoute/checkout`;

    const {
      orderData: { amount, razorPayApiKey, id },
    } = await simplePostCall(api, JSON.stringify({ amount: orderAmt }));

    const options = {
      razorPayApiKey,
      amount,
      currency: "INR",
      name: "ANIMALSOMATIC CLINIC",
      description: "Animal App payment",
      image: mainLogo,
      order_id: id,
      handler: async function (response) {
        const data = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
          orderDetails,
          type,
        };

        try {
          const result = await simplePostCall(
            `${BASE_URL}api/paymentRoute/paymentverification`,
            JSON.stringify(data)
          );
          const {
            success,
          } = result;
          if (success) {
            callBack()
          } else {
            notifyError("Payment failed, please try again");
          }
        } catch (error) {
          notifyError("Something went wrong, please try again");
          console.log("catch error,", error);
        }
      },
      prefill: {
        name: "ANIMALSOMATIC CLINIC",
        email: "info@animalsapp.in",
        contact: "9999999999",
      },
      notes: {
        address:
          "Animals App, Animalsomatic clinic, Near Ravi Steel, Kamde, Ratu Road, Ranchi, Jharkhand, India 835222",
      },
      theme: {
        color: "#1D7C7C",
      },
    };

    const razor = new window.Razorpay(options);
    razor.open();
  } catch (error) {
    console.log("razer pay catch error,", error);
  }
}