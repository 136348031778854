import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import wallet_icon from "../../assets/images/icons/wallet_icon.svg";
import export_icon from "../../assets/images/icons/export_icon.svg";
import ic_completed from "../../assets/images/ic_completed.svg";
import ic_rejected from "../../assets/images/ic_rejected.svg";
import cat from "../../assets/images/cat.png";
import ic_filter from "../../assets/images/ic_filter.svg";
import { motion } from "framer-motion";
import { DarkButton, DonutChart } from "../commonComponents/commonComp";
import { Link } from "react-router-dom";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import Chart from "react-apexcharts";

import ApiConfig from "../../api/ApiConfig";
import {
  simpleGetCall,
  simpleGetCallWithToken,
  simplePostAuthCall,
  simplePostCall,
} from "../../api/ApiServices";
import { AppContext } from "../../context/AppContext";
import Loader from "../../sharedComponent/Loader";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

const TransactionDetails = () => {
  const [viewChange, setviewChange] = useState(true);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const [ProductNameFilter, setProductNameFilter] = useState("");
  const [withdraw, setWithdraw] = useState(false);

  const { setLoading, userData, loading } = useContext(AppContext);
  const { user_id } = userData;
  const [Transactionsdetails, setTransactionsdetails] = useState([]);
  const [Upcomingdetails, setUpcomingdetails] = useState([]);
  const [TotalEarnings, setTotalEarnings] = useState("");
  const pha_id = localStorage.getItem("user_id");
  const [dropdownKey, setDropdownKey] = useState([]);
  const [withdrawDaat, setWithdrawDaat] = useState({
    user_id: pha_id,
    request_reason: "",
    request_amount: null,
    bank_id: null,
  });
  const todaysDate = new Date();

  const getBankListDrop = () => {
    setWithdraw(!withdraw);
    simpleGetCall(
      ApiConfig.GET_PAYMENT_DROPDOWN + `?user_id=${pha_id}&type=${"pet"}`
    )
      .then((res) => {
        const extractedData = res?.data?.map((item) => ({
          bankName: item.pet_shop_bank,
          bankId: item.pet_shop_bank_id,
        }));
        setDropdownKey(extractedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postWithdrawAmnt = () => {
    if (withdrawDaat.request_amount <= TotalEarnings) {
      if (withdrawDaat.request_amount !== null) {
        simplePostCall(
          ApiConfig.POST_WITHDRAW_REQUEST,
          JSON.stringify(withdrawDaat)
        )
          .then((res) => {
            notifySuccess(res?.message);
            setWithdraw(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        notifyError("All fields are required");
      }
    } else {
      notifyError("Selected amount is greater than your wallet amount");
    }
  };

  useEffect(() => {
    getDetailsUpcoming();
    if (ProductNameFilter === "") {
      setLoading(true);
      getDetails();
    } else {
      getDetails();
    }
  }, [ProductNameFilter]);

  useEffect(() => {
    getDetailsUpcoming();
    fetchPaymentAnalytics(todaysDate, todaysDate);
  }, []);

  const getDetails = () => {
    simpleGetCallWithToken(
      ApiConfig.TRANSACTION_RECENT_LIST +
        userData.type_of_pet_shop_id +
        "&filter=" +
        ProductNameFilter
    )
      .then((data) => {
        if (data.success) {
          setTransactionsdetails(data?.data);
          setTotalEarnings(data?.earning);
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const getDetailsUpcoming = () => {
    simpleGetCallWithToken(
      ApiConfig.TRANSACTION_UPCOMING_LIST + userData.type_of_pet_shop_id
    )
      .then((data) => {
        if (data.success) {
          setUpcomingdetails(data?.data);
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [paymentAnalytics, setpaymentAnalytics] = useState({
    withdrawPercentage: 0,
    receivedPercentyage: 0,
  });

  const tempArr = Object.keys(paymentAnalytics).map((key) =>
    Number(paymentAnalytics[key])
  );
  const arr = tempArr && tempArr.map((ele) => parseFloat(ele));

  const [donut, setDonut] = useState({
    options: {
      dataLabels: {
        enabled: false,
      },
      colors: ["#FFB200", "#01B529", "#5500DF"],
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
      labels: ["Withdrawl", "Received"],
    },
    series: arr,

    style: {
      // colors: ["#FFB200", "#01B529", "#5500DF"],
    },
  });

  const fetchPaymentAnalytics = async (fromDate, toDate) => {
    // FETCH_PAYMENT_ANALYTICS_DETAILS
    const userId = user_id && user_id?.split("_")[1];
    const paymentAnalyticsPayload = {
      user_id: userId,
      from_date: moment(fromDate).format("YYYY-MM-DD"),
      to_date: moment(toDate).format("YYYY-MM-DD"),
    };
    try {
      const res = await simplePostAuthCall(
        `${ApiConfig.FETCH_PAYMENT_ANALYTICS_DETAILS}`,
        JSON.stringify(paymentAnalyticsPayload)
      );

      const { success, data } = res;
      if (success && data) {
        setpaymentAnalytics({
          withdrawPercentage: data?.WithdrawPercentage,
          receivedPercentyage: data?.ReceviedPercentage,
        });
      }
      // const  {}
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.3 }}
      className="appointment-wrapper reports_wrapper transaction_details_wrapper"
      id="cx-main"
    >
      {loading === true ? (
        <Loader />
      ) : (
        <div className="transaction_details" id="TransactionDetails">
          <div className="search-filter-wrapper mb-4">
            <div
              className="search-filter-left"
              style={{
                width: "86%",
              }}
            >
              <div className="row">
                <div className="search-filter-left">
                  <input
                    type="text"
                    className="form-control serach-input"
                    placeholder="Search by product name..."
                    value={ProductNameFilter}
                    onChange={(e) => {
                      setProductNameFilter(e.target.value);
                    }}
                  />
                </div>
                {/* <div className="col-lg-1">
                <button className="fltr-btn">
                  <img src={ic_filter} alt="" />
                </button>
              </div> */}
              </div>
            </div>
          </div>
          <div className="wrapper-main">
            <div className="row">
              <div className="col-lg-8">
                <div className="table-wrapper table_wrapper_pagination py-4">
                  <div className="reportsTabs">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                      <Nav
                        variant="pills"
                        id="newTabMai"
                        className="tob_nav_pills d-flex"
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="1">Recent Transactions</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="2">Withdraw History</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Link to="#" className="export_button custom_border">
                        <img src={export_icon} alt="" />
                      </Link>
                      <Tab.Content>
                        <Tab.Pane eventKey="1">
                          <div className="dashboard_wrapper2 heightAdjuster">
                            <div className="table_wrapper_pagination dasboard-cust-tb-wrapper">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Sr. No</th>
                                    <th>Product</th>
                                    <th>Category</th>
                                    <th>Sale</th>
                                    <th>In Stock</th>
                                    <th>Revenue</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Transactionsdetails &&
                                  Transactionsdetails.length > 0 ? (
                                    Transactionsdetails.map(
                                      (itemlist, index) => {
                                        return (
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>
                                              <label
                                                htmlFor=""
                                                className="photo"
                                              >
                                                {itemlist.product}
                                              </label>
                                            </td>
                                            <td>{itemlist.product_type}</td>
                                            <td>{itemlist.sale}</td>
                                            <td>{itemlist.in_stock}</td>
                                            <td>₹{itemlist.revenue}</td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            {Transactionsdetails.length ? (
                              ""
                            ) : (
                              <p
                                style={{
                                  fontSize: "20px",
                                  marginTop: "0px",
                                }}
                                className=" text-center justify-content-center align-items-center"
                              >
                                Oops! It doesn't look like there are any
                                Transactions?
                              </p>
                            )}
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                          <div className="dashboard_wrapper">
                            <div className="table-wrapper table_wrapper_pagination p-0 dasboard-cust-tb-wrapper">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Sr. No</th>
                                    <th>Name</th>
                                    <th>Due Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Upcomingdetails &&
                                  Upcomingdetails.length > 0 ? (
                                    Upcomingdetails.map((itemlist, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>
                                            <label htmlFor="" className="photo">
                                              {itemlist.customer_name}
                                            </label>
                                          </td>
                                          <td> {itemlist.due_date}</td>
                                          <td>{itemlist.description}</td>
                                          <td>₹{itemlist.amount}</td>
                                          <td style={{ cursor: "pointer" }}>
                                            <img
                                              src={wallet_icon}
                                              className="status-ic"
                                              alt=""
                                            />
                                            Pay Now
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            {Upcomingdetails.length ? (
                              ""
                            ) : (
                              <p
                                style={{
                                  fontSize: "20px",
                                  marginTop: "0px",
                                }}
                                className=" text-center justify-content-center align-items-center"
                              >
                                Oops! It doesn't look like there are any
                                Transactions?
                              </p>
                            )}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="patient_stat_wrapper">
                  <div className="lc_top">
                    <div className="lct_header_wrapper">
                      <p>My Earnings</p>
                    </div>
                    <div className="earning_amount">
                      <p>₹{TotalEarnings}</p>
                      <p>Bank- State Bank Of India</p>
                      <p>*Early withdrawal charges may apply.</p>
                      <DarkButton
                        classbtn={
                          "cx-btn-dark me-auto  btn-width-100 btn-height font-17"
                        }
                        onClick={getBankListDrop}
                        btn={"Withdraw"}
                      />
                    </div>
                  </div>
                </div>

                <div className="donut_chart_wrapper">
                  <div className="dc_header">
                    <p>Payment Analytics</p>
                  <div className="selection">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          const {value} = e.target
                          const firstDayOfMonth = new Date(
                            todaysDate.getFullYear(),
                            todaysDate.getMonth(),
                            1
                          );
                          const lastDayOfMonth = new Date(
                            todaysDate.getFullYear(),
                            todaysDate.getMonth() + 1,
                            0
                          );
                          if(value == "1"){
                            fetchPaymentAnalytics(todaysDate, todaysDate);
                          } else if (value == "2"){
                            fetchPaymentAnalytics(firstDayOfMonth, lastDayOfMonth);
                          }
                        }}
                      >
                        <option value={"1"}>
                          Day
                        </option>
                        <option value={"2"}>
                          Month
                        </option>
                        {/* <option value="1">JAN</option>
                        <option value="2">FEB</option>
                        <option value="3">MAR</option>
                        <option value="3">APR</option>
                        <option value="3">MAY</option>
                        <option value="3">JUN</option>
                        <option value="3">JUL</option>
                        <option value="3">AUG</option>
                        <option value="3">SEP</option>
                        <option value="3">OCT</option>
                        <option value="3">NOV</option>
                        <option value="3">DEC</option> */}
                      </select>
                  </div>
                 
                  </div>
                  {/**
                    <DonutChart />
                     */}
                  <div>
                    {arr.some((element) => element !== 0) && (
                      <Chart
                        options={donut.options}
                        series={arr}
                        type="donut"
                        // width="280"
                        height="250"
                      />
                    )}
                  </div>
                  <div className="Chart_labels belowMgNon">
                    <div className="cl_item">
                      <p>Received</p>
                      <p>
                        {paymentAnalytics?.receivedPercentyage?.toFixed(2)}%
                      </p>
                    </div>
                    {/* <div className="cl_item">
                              <p>Subscriptions</p>
                              <p>&#8377;46</p>
                            </div> */}
                    <div className="cl_item">
                      <p>Withdrawl</p>
                      <p>{paymentAnalytics?.withdrawPercentage?.toFixed(2)}%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Modal Of withdraw */}
      <div id="withdrawModal">
        {withdraw && (
          <Modal show={withdraw} centered onHide={() => setWithdraw(false)}>
            <Modal.Header closeButton className="tittleHead">
              <Modal.Title className="tittleModal">
                Withdraw Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="formBox">
                <div className="formBody">
                  <label htmlFor="" className="labelForm">
                    Bank Id
                  </label>
                  <select
                    onChange={(e) =>
                      setWithdrawDaat({
                        ...withdrawDaat,
                        bank_id: e.target.value,
                      })
                    }
                    className="form-select"
                  >
                    <option value="">Select Bank</option>
                    {dropdownKey && dropdownKey?.length > 0
                      ? dropdownKey.map((data, index) => {
                          return (
                            <option key={index} value={data?.bankId}>
                              {data?.bankName}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
                <div className="formBody">
                  <label htmlFor="" className="labelForm">
                    Request Reason
                  </label>
                  <textarea
                    rows="2"
                    className="form-control"
                    onChange={(e) =>
                      setWithdrawDaat({
                        ...withdrawDaat,
                        request_reason: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
                <div className="formBody">
                  <label htmlFor="" className="labelForm">
                    Request Amount
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e) =>
                      setWithdrawDaat({
                        ...withdrawDaat,
                        request_amount: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="buttonBox">
                  <button className="submitBtn" onClick={postWithdrawAmnt}>
                    Submit
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </motion.div>
  );
};

export default TransactionDetails;

// import React, { useState } from "react";
// import Nav from "react-bootstrap/Nav";
// import { Tab, Tabs } from "react-bootstrap";
// import earn_icon from "../../assets/images/icons/earn_icon.svg";
// import patient_icon from "../../assets/images/icons/patient_icon.svg";
// import total_appoint_icon from "../../assets/images/icons/total_appoint_icon.svg";
// import complete_icon from "../../assets/images/icons/complete_icon.svg";
// import cancelled_icon from "../../assets/images/icons/cancelled_icon.svg";
// import wallet_icon from "../../assets/images/icons/wallet_icon.svg";
// import export_icon from "../../assets/images/icons/export_icon.svg";
// import ic_completed from "../../assets/images/ic_completed.svg";
// import ic_rejected from "../../assets/images/ic_rejected.svg";
// import cat from "../../assets/images/cat.png";
// import ic_filter from "../../assets/images/ic_filter.svg";
// import { motion } from "framer-motion";
// import { DarkButton, DonutChart } from "../commonComponents/commonComp";
// import { Link } from "react-router-dom";

// const TransactionDetails = () => {
//   const [viewChange, setviewChange] = useState(true);
//   const aninations = {
//     initial: { opacity: 0, x: 400 },
//     animate: { opacity: 1, x: 0 },
//     exit: { opacity: 0, x: 100 },
//   };
//   return (
//     <motion.div
//       variants={aninations}
//       initial="initial"
//       animate="animate"
//       exit="exit"
//       transition={{ duration: 0.3 }}
//       className="appointment-wrapper reports_wrapper transaction_details_wrapper"
//       id="cx-main"
//     >
//       <div className="transaction_details" id="TransactionDetails">
//         <div className="search-filter-wrapper mb-4">
//           <div className="search-filter-left">
//             <input
//               type="text"
//               className="form-control serach-input"
//               placeholder="Search by Name, Specialist, Ratings, Visits..."
//             />
//             <button className="fltr-btn">
//               <img src={ic_filter} alt="" />
//             </button>
//           </div>
//         </div>
//         <div className="wrapper-main">
//           <div className="row">
//             <div className="col-lg-8">
//               <div className="table-wrapper table_wrapper_pagination py-4">
//                 <div className="reportsTabs">
//                   <Tab.Container id="left-tabs-example" defaultActiveKey="1">
//                     <Nav variant="pills" id="newTabMai" className="tob_nav_pills d-flex">
//                       <Nav.Item>
//                         <Nav.Link eventKey="1">Recent Transactions</Nav.Link>
//                       </Nav.Item>
//                       <Nav.Item>
//                         <Nav.Link eventKey="2">Upcoming Payments</Nav.Link>
//                       </Nav.Item>

//                     </Nav>
//                     <Link to="#" className="export_button custom_border">
//                       <img src={export_icon} alt="" /></Link>
//                     <Tab.Content>
//                       <Tab.Pane eventKey="1">
//                         <div className="dashboard_wrapper">
//                           <div className="table-wrapper table_wrapper_pagination p-0 dasboard-cust-tb-wrapper">
//                             <table>
//                               <thead>
//                                 <tr>
//                                   <th>Sr. No</th>
//                                   <th>Name</th>
//                                   <th>Date</th>
//                                   <th>Transaction ID</th>
//                                   <th>Description</th>
//                                   <th>Amount</th>
//                                   <th>Status</th>
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 <tr>
//                                   <td>1</td>
//                                   <td>
//                                     <label htmlFor="" className="photo">
//                                       <img src={cat} alt="" />
//                                       Ronald Sharma
//                                     </label>
//                                   </td>
//                                   <td>02-02-2023</td>
//                                   <td>12345678900</td>
//                                   <td>Clinic</td>
//                                   <td>₹500</td>
//                                   <td>
//                                     <img
//                                       src={ic_completed}
//                                       className="status-ic"
//                                       alt=""
//                                     />
//                                     Completed
//                                   </td>

//                                 </tr>
//                                 <tr>
//                                   <td>2</td>
//                                   <td>
//                                     <label htmlFor="" className="photo">
//                                       <img src={cat} alt="" />
//                                       Ronald Sharma
//                                     </label>
//                                   </td>
//                                   <td>02-02-2023</td>
//                                   <td>12345678900</td>
//                                   <td>Video Call</td>
//                                   <td>₹500</td>
//                                   <td>
//                                     <img
//                                       src={ic_completed}
//                                       className="status-ic"
//                                       alt=""
//                                     />
//                                     Completed
//                                   </td>

//                                 </tr>
//                                 <tr>
//                                   <td>3</td>
//                                   <td>
//                                     <label htmlFor="" className="photo">
//                                       <img src={cat} alt="" />
//                                       Ronald Sharma
//                                     </label>
//                                   </td>
//                                   <td>02-02-2023</td>
//                                   <td>12345678900</td>
//                                   <td>Service Charge</td>
//                                   <td>₹500</td>
//                                   <td>
//                                     <img src={ic_rejected} className="status-ic" alt="" />
//                                     Cancelled
//                                   </td>

//                                 </tr>
//                                 <tr>
//                                   <td>4</td>
//                                   <td>
//                                     <label htmlFor="" className="photo">
//                                       <img src={cat} alt="" />
//                                       Ronald Sharma
//                                     </label>
//                                   </td>
//                                   <td>02-02-2023</td>
//                                   <td>12345678900</td>
//                                   <td>Clinic</td>
//                                   <td>₹500</td>
//                                   <td>
//                                     <img
//                                       src={ic_completed}
//                                       className="status-ic"
//                                       alt=""
//                                     />
//                                     Completed
//                                   </td>

//                                 </tr>
//                                 <tr>
//                                   <td>5</td>
//                                   <td>
//                                     <label htmlFor="" className="photo">
//                                       <img src={cat} alt="" />
//                                       Ronald Sharma
//                                     </label>
//                                   </td>
//                                   <td>02-02-2023</td>
//                                   <td>12345678900</td>
//                                   <td>Clinic</td>
//                                   <td>₹500</td>
//                                   <td>
//                                     <img
//                                       src={ic_completed}
//                                       className="status-ic"
//                                       alt=""
//                                     />
//                                     Completed
//                                   </td>

//                                 </tr>
//                                 <tr>
//                                   <td>6</td>
//                                   <td>
//                                     <label htmlFor="" className="photo">
//                                       <img src={cat} alt="" />
//                                       Ronald Sharma
//                                     </label>
//                                   </td>
//                                   <td>02-02-2023</td>
//                                   <td>12345678900</td>
//                                   <td>Clinic</td>
//                                   <td>₹500</td>
//                                   <td>
//                                     <img src={ic_rejected} className="status-ic" alt="" />
//                                     Cancelled
//                                   </td>

//                                 </tr>
//                               </tbody>
//                             </table>
//                           </div>
//                           <div className="pagination_wrapper">
//                             <p>Showing 1-10 of 100</p>
//                           </div>
//                         </div>
//                       </Tab.Pane>
//                       <Tab.Pane eventKey="2">
//                         <div className="dashboard_wrapper">
//                           <div className="table-wrapper table_wrapper_pagination p-0 dasboard-cust-tb-wrapper">
//                             <table>
//                               <thead>
//                                 <tr>
//                                   <th>Sr. No</th>
//                                   <th>Name</th>
//                                   <th>Due Date</th>
//                                   <th>Description</th>
//                                   <th>Amount</th>
//                                   <th>Action</th>
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 <tr>
//                                   <td>1</td>
//                                   <td>
//                                     <label htmlFor="" className="photo">
//                                       <img src={cat} alt="" />
//                                       Ronald Sharma
//                                     </label>
//                                   </td>
//                                   <td>02-02-2023</td>
//                                   <td>Service Charge</td>
//                                   <td>₹500</td>
//                                   <td style={{ cursor: "pointer" }}>
//                                     <img
//                                       src={wallet_icon}
//                                       className="status-ic"
//                                       alt=""
//                                     />
//                                     Pay Now
//                                   </td>

//                                 </tr>
//                               </tbody>
//                             </table>

//                           </div>
//                           <div className="pagination_wrapper">
//                             <p>Showing 1-10 of 100</p>
//                           </div>
//                         </div>
//                       </Tab.Pane>
//                     </Tab.Content>
//                   </Tab.Container>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-4">
//               <div className="patient_stat_wrapper">
//                 <div className="lc_top">
//                   <div className="lct_header_wrapper">
//                     <p>My Earnings</p>
//                   </div>
//                   <div className="earning_amount">
//                     <p>₹35,000</p>
//                     <p>Bank- State Bank Of India</p>
//                     <p>*Early withdrawal charges may apply.</p>
//                     <DarkButton
//                       classbtn={
//                         "cx-btn-dark me-auto  btn-width-100 btn-height font-17"
//                       }
//                       btn={"Withdraw"}
//                     />

//                   </div>
//                 </div>
//               </div>

//               <div className="donut_chart_wrapper">
//                 <div className="dc_header">
//                   <p>Payment Analytics</p>
//                   <div className="selection">
//                     <select
//                       className="form-select"
//                       aria-label="Default select example"
//                     >
//                       <option selected disabled>
//                         Month
//                       </option>
//                       <option value="1">JAN</option>
//                       <option value="2">FEB</option>
//                       <option value="3">MAR</option>
//                       <option value="3">APR</option>
//                       <option value="3">MAY</option>
//                       <option value="3">JUN</option>
//                       <option value="3">JUL</option>
//                       <option value="3">AUG</option>
//                       <option value="3">SEP</option>
//                       <option value="3">OCT</option>
//                       <option value="3">NOV</option>
//                       <option value="3">DEC</option>
//                     </select>
//                   </div>
//                 </div>
//                 <DonutChart />
//                 <div className="Chart_labels">
//                   <div className="cl_item">
//                     <p>Received</p>
//                     <p>25%</p>
//                   </div>
//                   <div className="cl_item">
//                     <p>Subscriptions</p>
//                     <p>25%</p>
//                   </div>
//                   <div className="cl_item">
//                     <p>Withdrawl</p>
//                     <p>50%</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </motion.div>
//   );
// };

// export default TransactionDetails;
