import React, { useContext, useEffect, useState } from "react";
import expert_plan_icon from "../../assets/images/icons/expert_plan_icon.svg";
import premium_plan_icon from "../../assets/images/icons/premium_plan_icon.svg";
import professional_plan_icon from "../../assets/images/icons/professional_plan_icon.svg";
import professional_pro_plan_icon from "../../assets/images/icons/professional_pro_plan_icon.svg";
import modal_close_btn from "../../assets/images/icons/modal_close_btn.svg";
import { motion } from "framer-motion";
import { DarkButton, DonutChart } from "../commonComponents/commonComp";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  postMultipartWithAuthCall,
  simpleGetCallWithToken,
  simplePostAuthCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import { checkoutHandler } from "./checkout";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import moment from "moment";
import { ddmmyy } from "../../context/constants/dateformats";
import Loader from "../../sharedComponent/Loader";

const Subscription = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [manageModal, setManageModal] = useState(1);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const { userData, subscriptionAPIPayload, setSubscriptionAPIPayLoad } =
    useContext(AppContext);
  const { user_id } = userData;
  const [currentPlan, setCurrentPlan] = useState([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      setIsLoading(true);
      const res = await simpleGetCallWithToken(
        `${ApiConfig.SUBSCRIPTIONS_APIS}?user_id=${user_id}`
      );
      const { data, success, current_plan } = res;
      if (success) {
        data && setSubscriptionPlans(data);
        current_plan && setCurrentPlan(current_plan);
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setIsLoading(false);
    }
  };

  const afterPaymentSuccess = () => {
    notifySuccess("You have successfully purchased our plan");
    localStorage.setItem("is_login", true);
    window.location.reload();
    // setSubscriptionAPIPayLoad({
    //   ...subscriptionAPIPayload,
    //   is_login : true
    // })
  };
  const requestSubScription = async (subscription_id, subscription_price) => {
    const payLoad = {
      subscription_id: subscription_id,
      mode_of_payment_id: 1,
      transaction_date: new Date(),
      payment_status_id: 36,
      user_id: user_id,
    };
    try {
      const res = await simplePostAuthCall(
        ApiConfig.REQUEST_SUBSCRIPTIONS,
        JSON.stringify(payLoad)
      );
      const { success, orderDetails, message } = res;
      if (success) {
        if (subscription_price) {
          checkoutHandler(
            subscription_price,
            orderDetails,
            "doc-sub",
            afterPaymentSuccess
          );
        }
      } else {
        notifyError(message);
      }
    } catch (error) {
      console.log("catch error of request subscription,", error);
    }
  };

  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.3 }}
      className="Subscription_wrapper"
      id="cx-main"
    >
      <div className="Subscription">
        {currentPlan && currentPlan.length > 0 && (
          <div className="subscription_title">
            <p> Current Plan</p>
          </div>
        )}

        {currentPlan.map((item, index) => {
          return (
            <div className="subscription_item">
              <div className="row">
                <div className="col-lg-10">
                  <div className="sub_item_left">
                    <img src={professional_plan_icon} alt="" />
                    <div className="title_text_wrapper">
                      <p className="plan_title">{item?.subscription_name}</p>
                      {item?.subscription_date && <p className="sub_date">
                        Subscription Date :{" "}
                        <span>
                          {
                            ddmmyy(item?.subscription_date)
                            // moment(item?.subscription_date, "DD-MM-YYYY").format("DD-MM-YYYY")
                          }
                        </span>
                      </p>}
                      <p className="sub_date">
                        Description :{" "}
                        <span>
                          {item?.subscription_description}
                        </span>
                      </p>
                    </div>
                    <div className="renew_date">
                      <p>
                        Renewal Date :{" "}
                        <span>
                          {
                            ddmmyy(item?.subscription_valid_upto)
                            // moment(item?.subscription_valid_upto, "DD-MM-YYYY").format("DD-MM-YYYY")
                          }
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                {/*
                       <div className="col-lg-2">
                        <div className="sub_item_right">
                          <Link
                            to="#"
                            onClick={() => {
                              setManageModal(1);
                              handleShow();
                            }}
                          >
                            View Details
                          </Link>
                        </div>
                      </div> 
                      */}
              </div>
            </div>
          );
        })}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="subscription_title">
              <p>Recommended Plan</p>
            </div>
            {subscriptionPlans &&
              subscriptionPlans.map((item, idx) => {
                return (
                  <div className="subscription_item1" key={idx + "1212"}>
                    <div className="row">
                      <div className="col-lg-9">
                        <div className="sub_item_left">
                          <img src={professional_pro_plan_icon} alt="" />
                          <div className="title_text_wrapper">
                            <p className="plan_title">
                              {item?.subscription_name}
                            </p>
                            <p className="sub_date mb-2">
                              {item?.subscription_description}
                              {/* <Link
                        onClick={() => {
                          setManageModal(2);
                          handleShow();
                        }}
                      >
                        Read more
                      </Link> */}
                            </p>
                            {item?.subscription_price &&
                            Number(item?.subscription_price) ? (
                              <p className="plan_title">
                                ₹{item?.subscription_price}
                              </p>
                            ) : null}
                            {item?.duration_months &&
                            Number(item?.duration_months) &&
                            item?.subscription_price &&
                            Number(item?.subscription_price) ? (
                              <div style={{ display: "flex", gap: "5px" }}>
                                <span>Duration : </span>{" "}
                                <p className="plan_title">
                                  {item?.duration_months} months
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {item?.duration_months &&
                        Number(item?.duration_months) &&
                        item?.subscription_price &&
                        Number(item?.subscription_price) ? (
                          <div className="col-lg-3">
                            <div className="sub_item_right">
                              <DarkButton
                                classbtn={
                                  "cx-btn-dark me-auto  btn-width-100 btn-height1 font-14"
                                }
                                btn={"Upgrade Plan"}
                                onClick={() =>
                                  requestSubScription(
                                    item?.subscription_id,
                                    item?.subscription_price
                                  )
                                }
                              />
                            </div>
                          </div>
                        ) : null}
                    </div>
                  </div>
                );
              })}
          </>
        )}

        {/* <div className="subscription_item1">
          <div className="row">
            <div className="col-lg-9">
              <div className="sub_item_left">
                <img src={premium_plan_icon} alt="" />
                <div className="title_text_wrapper">
                  <p className="plan_title">Premium Plan</p>
                  <p className="sub_date mb-2">
                    Aliquam porta nisl dolor, molestie pellentesque elit
                    molestie in. Morbi metus neque, ...{" "}
                    <Link
                      onClick={() => {
                        setManageModal(3);
                        handleShow();
                      }}
                    >
                      Read more
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sub_item_right">
                <DarkButton
                  classbtn={
                    "cx-btn-dark me-auto  btn-width-100 btn-height1 font-14"
                  }
                  btn={"Upgrade Plan"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="subscription_item1">
          <div className="row">
            <div className="col-lg-9">
              <div className="sub_item_left">
                <img src={expert_plan_icon} alt="" />
                <div className="title_text_wrapper">
                  <p className="plan_title">Expert Plan</p>
                  <p className="sub_date mb-2">
                    Aliquam porta nisl dolor, molestie pellentesque elit
                    molestie in. Morbi metus neque, ...{" "}
                    <Link
                      onClick={() => {
                        setManageModal(4);
                        handleShow();
                      }}
                    >
                      Read more
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sub_item_right">
                <DarkButton
                  classbtn={
                    "cx-btn-dark me-auto  btn-width-100 btn-height1 font-14"
                  }
                  btn={"Upgrade Plan"}
                />
              </div>
            </div>
          </div>
        </div> */}
      </div>

      {/* Modal Start */}
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="common_modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="modal_header_wrapper">
              <div className="modal_left">
                <img
                  src={
                    manageModal == 1
                      ? professional_plan_icon
                      : manageModal == 2
                      ? professional_pro_plan_icon
                      : manageModal == 3
                      ? premium_plan_icon
                      : expert_plan_icon
                  }
                  alt=""
                />
                <p>
                  {manageModal == 1
                    ? "Professional Plan"
                    : manageModal == 2
                    ? "Professional Pro Plan"
                    : manageModal == 3
                    ? "Premium Plan"
                    : "Expert Plan"}
                </p>
              </div>
              <div className="modal_close_btn">
                <img src={modal_close_btn} alt="" onClick={handleClose} />
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_body_inner_wrapper">
            <p className="description">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, to Sed ut perspiciatis unde
              omnis iste natus error sit voluptatem accusantium doloremque
              laudantium, to Sed ut perspiciatis unde omnis iste natus error sit
              voluptatem accusantium doloremque laudantium, to
            </p>
            <div className="benefits_wrapper">
              <p className="benefit_tittle">Benefits</p>

              <ul>
                <li>Benefits 1</li>
                <li>Benefits 2</li>
                <li>Benefits 3</li>
                <li>Benefits 4</li>
                <li>Benefits 5</li>
                <li>Benefits 6</li>
                <li>Benefits 7</li>
                <li>Benefits 8</li>
                <li>Benefits 9</li>
                <li>Benefits 10</li>
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={handleClose}>Close</Button> */}
          <div className="modal_btn_wrapper">
            <DarkButton
              classbtn={"cx-btn-light btn-width-50 btn-height1 font-14 me-3"}
              onClick={handleClose}
              btn={manageModal == 1 ? "Cancel Plan" : "Cancel"}
            />{" "}
            <DarkButton
              classbtn={"cx-btn-dark me-auto  btn-width-50 btn-height1 font-14"}
              btn={"Upgrade Plan"}
            />
          </div>
        </Modal.Footer>
      </Modal>

      {/* Modal End */}
    </motion.div>
  );
};

export default Subscription;
