import React, { useState } from "react";
import yourLogo from "../../assets/images/mainLogo.svg";
import edu_icon from "../../assets/images/icons/edu_icon.svg";
import exp_icon from "../../assets/images/icons/exp_icon.svg";
import regi_icon from "../../assets/images/icons/regi_icon.svg";
import sign_icon from "../../assets/images/icons/sign_icon.svg";
import clinic_img_1 from "../../assets/images/clinic_img_1.svg";
import clinic_img_2 from "../../assets/images/clinic_img_2.svg";
import clinic_img_3 from "../../assets/images/clinic_img_3.svg";
import clinic_img_4 from "../../assets/images/clinic_img_4.svg";
import Service_icon from "../../assets/images/icons/Service_icon.svg";
import upload_remove from "../../assets/images/icons/upload_remove.svg";
import delete_upload from "../../assets/images/icons/delete_upload.svg";
import upload_success from "../../assets/images/icons/upload_success.svg";
import upload_doc_icon from "../../assets/images/icons/upload_doc_icon.svg";
import upload_icon from "../../assets/images/icons/upload_icon.svg";
import upload_doc_img from "../../assets/images/upload_doc_img.svg";
import { DarkButton } from "../commonComponents/commonComp";
import Select from "react-select";

const UploadDocs = () => {
  const [file, setFile] = useState();
  const [signature, setSignature] = useState();
  const [deleteImg, setDeleteImg] = useState(true);

  function handleFileInput(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  function handleFileInput2(e) {
    setSignature(URL.createObjectURL(e.target.files[0]));
    setDeleteImg(true);
  }

  const handleDeleteImg = () => {
    setDeleteImg(!deleteImg);
  };

  const options = [
    {
      value: "OPD (Allopathy & Homeopathy)",
      label: "OPD (Allopathy & Homeopathy)",
    },
    { value: "Dental facility", label: "Dental facility" },
    { value: "Ward/ Indoor", label: "Ward/ Indoor" },
    { value: "Minor OT/ Dressing Room", label: "Minor OT/ Dressing Room" },
    { value: "Laboratory services", label: "Laboratory services" },
  ];

  const [selected, setSelected] = useState(null);

  const handleChange = (e) => {
    setSelected(e.value);
  };
  // const [selectedOption, setSelectedOption] = useState("");

  // const handleChange1 = (selectedOption) => {
  //   setSelectedOption(selectedOption.value);
  // };

  return (
    <>
      <div className="authMain">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <img src={yourLogo} className="logoImg" alt="" />
            <div className="signinForm signUp general_info">
              <h1 className="signInTxt mb-5">Upload Documents</h1>
              <div className="docs_wrapper">
                <form className="form-container" enctype="multipart/form-data">
                  <div className="upload-files-container">
                    <div className="drag-file-area">
                      <span className="material-icons-outlined upload-icon">
                        <img src={upload_icon} />
                      </span>
                      <h3 className="dynamic-message">Drop your files here. <br />or</h3>
                      <label className="label">
                        <span className="browse-files">
                          <input type="file" className="default-file-input" />
                          <span className="browse-files-text"> Browse</span>
                        </span>
                      </label>
                    </div>
                    <span className="cannot-upload-message">
                      <span className="material-icons-outlined">error</span> Please
                      select a file first
                      <span className="material-icons-outlined cancel-alert-button">
                        cancel
                      </span>
                    </span>
                    <div className="file-block">
                      <div className="file-info">
                        <span className="material-icons-outlined file-icon">
                          description
                        </span>
                        <span className="file-name"> </span> |
                        <span className="file-size"> </span>
                      </div>
                      <span className="material-icons remove-file-icon">
                        delete
                      </span>
                      <div className="progress-bar"> </div>
                    </div>
                  </div>
                </form>

                <div className="uploading_item">
                  <img src={upload_doc_icon} />
                  <div className="progress_wrapper">
                    <p className="doc_name">clinic_lisence.pdf</p>
                    <progress value="32" max="100" className="blue">32</progress>
                  </div>
                  <img src={delete_upload} />
                </div>
                <div className="uploading_item">
                  <img src={upload_doc_icon} />
                  <div className="progress_wrapper">
                    <p className="doc_name">clinic_lisence.pdf</p>
                    <progress value="32" max="100" className="green">32</progress>
                  </div>
                  <img src={delete_upload} />
                </div>
                <div className="uploading_item">
                  <img src={upload_doc_icon} />
                  <div className="progress_wrapper">
                    <p className="doc_name">clinic_lisence.pdf</p>
                    {/* <progress value="32" max="100" className="blue">32</progress> */}
                  </div>
                  <img src={upload_success} />
                  <img src={upload_remove} className="remove" />

                </div>
              </div>

              <div className="login btn-width-100">
                <DarkButton
                  classbtn={
                    "cx-btn-light me-3  btn-width-45 btn-height font-17 mb-5"
                  }
                  btn={"Back"}
                />
                <DarkButton
                  classbtn={"cx-btn-dark  btn-height btn-width-50 font-17 mb-5"}
                  btn={"Save & Proceed"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="landingImg">
              <img src={upload_doc_img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadDocs;
