import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';
import Logo from "../assets/images/mainLogo.svg";
import dashboard from "../assets/images/sidebarIcons/dashboard.svg";
import dashboardActive from "../assets/images/sidebarIcons/dashboardActive.svg";
import doctors from "../assets/images/sidebarIcons/Doctors.svg";
import doctorsActive from "../assets/images/sidebarIcons/DoctorsActive.svg";
import Innvt from "../assets/images/sidebarIcons/Inventari.svg";
import InnvtActive from "../assets/images/sidebarIcons/InventaryActive.svg";
import Order from "../assets/images/pet-shop-img/Order.svg";
import OrderActive from "../assets/images/pet-shop-img/OrderActive.svg";
import PharmacyActive from "../assets/images/sidebarIcons/PharmacyActive.svg";
import vacinators from "../assets/images/sidebarIcons/tabler_vaccine.svg";
import vacinatorsActive from "../assets/images/sidebarIcons/tabler_vaccineActive.svg";
import Ambulance from "../assets/images/sidebarIcons/Ambulance.svg";
import AmbulanceActive from "../assets/images/sidebarIcons/AmbulanceActive.svg";
import deliveryBoy from "../assets/images/sidebarIcons/DeliveryBoy.svg";
import deliveryBoyActive from "../assets/images/sidebarIcons/DeliveryBoyActive.svg";
import double_arrow from "../assets/images/double_arrow.svg";
import activeImg from "../assets/images/sidebarIcons/highlightImg.svg";
import Accordion from 'react-bootstrap/Accordion';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import CustomerIcon1 from "../assets/images/pet-shop-img/CustomerIcon (1).svg"
import CustomerIcon2 from "../assets/images/pet-shop-img/CustomerIcon (2).svg"
const Sidebar = () => {
  const { sidebar, setSidebar } = useContext(AppContext);
  const location = useLocation();
  const currentRoute = location.pathname;

  const [pharmacy, setPharmacy] = useState(false);
  const [vetPets, setVetPets] = useState(false);
  const [adminS, setAdminS] = useState(false);
  const [report, setReport] = useState(false);

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  useEffect(() => {
    isMobile ? setSidebar(false) : setSidebar(true);
    return () => { };
  }, [isMobile]);

  const mobileSidebarOff = () => {
    isMobile ? setSidebar(false) : setSidebar(true);
  };
  return (
    <>
      <div className="cx-sideBar">
        <div className={
          sidebar ? "cx-sidebar-wrapper" : "cx-sidebar-wrapper sidebarActive"
        }>
          <>
            <div className="logoMain">
              {
                sidebar ? <img src={Logo} className='sm-logo' alt="" /> : <img src={Logo} className='bg-logo' alt="" />
              }

            </div>

            <div className="arrow-wrapper">
              <button onClick={() => setSidebar(!sidebar)}>
                <img src={double_arrow} alt="" />
              </button>
            </div>

            {/* Dashboard */}
            <Accordion >
              <div className="sidebarLinks">
                <Link to="/Dashboard" className='targetLink' onClick={mobileSidebarOff}>
                  <div className="dashboardLink">
                    <img src={currentRoute === "/Dashboard" ? dashboardActive : dashboard} alt="" />
                    <p className={currentRoute === "/Dashboard" ? "activeTxt" : "deActiveTxt"}>Dashboard</p>
                    <img src={currentRoute === "/Dashboard" ? activeImg : null} className='activelogo' alt="" />
                  </div>
                </Link>
              </div>
              {/* Customer Details */}
              <div className="sidebarLinks">
                <Link to="/Inventory" className='targetLink' onClick={mobileSidebarOff}>
                  <div className="dashboardLink">
                    <img src={currentRoute === "/Inventory"
                      ? InnvtActive : Innvt} alt="" />
                    <p className={currentRoute === "/Doctors" ||
                      currentRoute === "/Inventory" ? "activeTxt" : "deActiveTxt"}>Inventory</p>
                    <img src={currentRoute === "/Inventory" ||
                      currentRoute === "/Inventory" ? activeImg : null} className='activelogo' alt="" />
                  </div>
                </Link>
              </div>
              {/* Reports */}
              <div className="sidebarLinks">
                <Link to="/Order" className='targetLink' onClick={mobileSidebarOff}>
                  <div className="dashboardLink">
                    <img src={currentRoute === "/Order" ? OrderActive : Order} alt="" />
                    <p className={currentRoute === "/Order" ? "activeTxt" : "deActiveTxt"}>Orders</p>
                    <img src={currentRoute === "/Order" ? activeImg : null} className='activelogo' alt="" />
                  </div>
                </Link>
              </div>
              {/* Appointments */}
              <Accordion.Item eventKey="0">
                <Accordion.Header onClick={() => {
                  setPharmacy(!pharmacy);
                  setAdminS(false);
                  setReport(false)
                  setVetPets(false);
                }}>
                  <Link to="#" className='targetLink'>
                    <div className="sidebarLinks">
                      <div className="dashboardLink">
                        <img src={currentRoute === "/SalesReports" || currentRoute === "/InventoryReports"
                          ? vacinatorsActive : vacinators} alt="" />
                        <p className={currentRoute === "/SalesReports" || currentRoute === "/InventoryReports" ? "activeTxt" : "deActiveTxt"}>Reports</p>
                        <img src={currentRoute === "/SalesReports" ||
                          currentRoute === "/InventoryReports" ? activeImg : null} className='activelogo' alt="" />
                        {/* <img src={pharmacy ? arrowAActive : rightArrow
                        } className='arrowSetting' alt="" /> */}
                      </div>
                    </div>
                  </Link>

                </Accordion.Header>
                <Accordion.Body>
                  <div className="menu_items">
                    <Link to="/SalesReports" className='menuLink' onClick={mobileSidebarOff}>
                      <div className={currentRoute === "/SalesReports" ? "activeTxt" : "deActiveTxt"}>
                        Sales
                      </div>
                    </Link>
                    <Link to="/InventoryReports" className='menuLink' onClick={mobileSidebarOff}>
                      <div className={currentRoute === "/InventoryReports" ? "activeTxt" : "deActiveTxt"}>
                        Inventory
                      </div>
                    </Link>
                  </div>
                </Accordion.Body>
              </Accordion.Item>


              {/* Transaction Details */}
              <div className="sidebarLinks">
                <Link to="/TransactionDetails" className='targetLink' onClick={mobileSidebarOff}>
                  <div className="dashboardLink">
                    <img src={currentRoute === "/TransactionDetails" ? AmbulanceActive : Ambulance} alt="" />
                    <p className={currentRoute === "/TransactionDetails" ? "activeTxt" : "deActiveTxt"}>Transaction Details</p>
                    <img src={currentRoute === "/TransactionDetails" ? activeImg : null} className='activelogo' alt="" />
                  </div>
                </Link>
              </div>
              {/* Customers */}
              <div className="sidebarLinks">
                <Link to="/Customers" className='targetLink' onClick={mobileSidebarOff}>
                  <div className="dashboardLink">
                    <img src={currentRoute === "/Customers" ? CustomerIcon2 : CustomerIcon1} alt="" />
                    <p className={currentRoute === "/Customers" ? "activeTxt" : "deActiveTxt"}>Customers</p>
                    {/* <img src={currentRoute === "/Customers" ? CustomerIcon1 : null} className='activelogo' alt="" /> */}
                  </div>
                </Link>
              </div>
              {/* Subscription */}
              <div className="sidebarLinks">
                <Link to="/Subscription" className='targetLink' onClick={mobileSidebarOff}>
                  <div className="dashboardLink">
                    <img src={currentRoute === "/Subscription" ? CustomerIcon2 : CustomerIcon1} alt="" />
                    <p className={currentRoute === "/Subscription" ? "activeTxt" : "deActiveTxt"}>Subscription</p>
                    {/* <img src={currentRoute === "/Customers" ? CustomerIcon1 : null} className='activelogo' alt="" /> */}
                  </div>
                </Link>
              </div>
            </Accordion>
          </>
        </div >
      </div >
    </>
  )
}

export default Sidebar
