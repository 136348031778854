import React from 'react'

const PaginationComp = () => {
  return (
    <div className='pagination-wrapper'>
        <label htmlFor="">Showing 1-10 of 100</label>
    </div>
  )
}

export default PaginationComp