import React, { useContext, useState } from "react";
import teleCall from "../../assets/images/icons/telephone.svg";
import insiderImg from "../../assets/images/icons/landigIcon.svg";
import mailBox from "../../assets/images/icons/emailSvg.svg";
import passBox from "../../assets/images/icons/lock.svg";
import eye from "../../assets/images/icons/eye.svg";
import yourLogo from "../../assets/images/mainLogo.svg";
import login_img from "../../assets/images/pet-shop-img/Login_img.svg";
import fb from "../../assets/images/fb.svg";
import apple from "../../assets/images/apple.svg";
import google from "../../assets/images/google.svg";
import { Link, Route, useNavigate } from "react-router-dom";
import { DarkButton } from "../commonComponents/commonComp";
import { AppContext } from "../../context/AppContext";
import {
  simplePostAuthCallNoTokan,
  simplePostCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import ic_show from "../../assets/images/ic_show_eyes.svg";
import Form from "react-bootstrap/Form";
import ic_left_green_arrow from "../../assets/images/ic_left_green_arrow.svg";
import telephone1 from '../../assets/images/icons/telephone1.svg'

const Login = ({ setLoggedIn }) => {
  const navigate = useNavigate();

  const { userData, setUserData, getProfileDet } = useContext(AppContext);
  const [passwordType, setPasswordType] = useState("password");
  const [validated, setValidated] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [isLoginWithOTP, setIsLoginWithOTP] = useState(false);
  const [loginWithOTPData, setLoginWithOTPData] = useState({
    phone_number: "",
    otp: "",
  });
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const changeEveVaue = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      loginUser(event);
    }
    setValidated(true);
  };

  const loginUser = (event) => {
    event.preventDefault();
    let loginPayLoad = isLoginWithOTP
      ? loginWithOTPData
      : {
          email: loginData?.email,
          password: loginData?.password,
        };
    const API_URL = isLoginWithOTP
      ? ApiConfig.LOGIN_WITH_OTP
      : ApiConfig.LOGIN_USER;
    simplePostAuthCallNoTokan(API_URL, JSON.stringify(loginPayLoad))
      .then((res) => {
        if (res.success === true) {
          // console.log("getUserDetails[0]", res.getUserDetails[0]);
          // return;+
          getProfileDet();
          localStorage.setItem("Token", res.token);
          localStorage.setItem("address", res?.data?.address);
          localStorage.setItem(
            "pet_shop_registration_id",
            res.data.pet_shop_registration_id
          );
          localStorage.setItem(
            "type_of_pet_shop_id",
            res.getUserDetails[0].pet_shop_id
          );
          localStorage.setItem("user_id", res.getUserDetails[0].user_id);
          localStorage.setItem(
            "pet_shop_image_path",
            res.getUserDetails[0].pet_shop_image_path
          );
          localStorage.setItem(
            "petshop_status_list",
            JSON.stringify(res.getUserDetails[0]?.json_agg)
          );
          localStorage.setItem(
            "subscription_id",
            JSON.stringify(res.getUserDetails[0]?.subscription_id)
          );
          localStorage.setItem(
            "subscription_valid_upto",
            JSON.stringify(res.getUserDetails[0]?.subscription_valid_upto)
          );
          localStorage.setItem(
            "is_login",
            JSON.stringify(res.is_login)
          );

          localStorage.setItem("isLoggedIn", true);
          // notifySuccess(res.message);
          setUserData({
            pet_shop_registration_id: res.data.pet_shop_registration_id,
            address: res?.data?.address,
            type_of_pet_shop_id: res.getUserDetails[0].pet_shop_id,
            user_id: res.getUserDetails[0].user_id,
            pet_shop_image_path: res.getUserDetails[0].pet_shop_image_path,
            isLoggedIn: true,
          });

          setLoggedIn(true);
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        notifyError(err.message);
      });
  };

  const startTimer = () => {
    // setSeconds(60);
    if (seconds > 0) {
      let interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  };

  const requestOTP = async () => {
    if (loginWithOTPData?.phone_number.length !== 10) {
      notifyError("Please enter valid mobile number");
      return;
    }
    try {
      const res = await simplePostCall(
        ApiConfig.SEND_OTP_FOR_LOGIN,
        JSON.stringify({ phone_number: loginWithOTPData?.phone_number })
      );
      const { success } = res;
      if (success) {
        notifySuccess("OTP sent your mobile number");
        startTimer();
      }
    } catch (error) {
      console.log("catch error from request OTP API,", error);
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="authMain">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <img src={yourLogo} className="logoImg" alt="" />
              <div className="signinForm">
                <h1 className="signInTxt">Sign in</h1>
                <p className="signInDesc">
                  If you don't have an account registered <br /> You can
                  <Link className="link_common" to="/SignUp">
                    {" "}
                    Register here !
                  </Link>
                </p>
                {!isLoginWithOTP ? (
                  <>
                    <div className="inputBox">
                      <label htmlFor="" className="emailLabel">
                        Email
                      </label>
                      <img src={mailBox} className="mailImg" alt="" />
                      <input
                        type="text"
                        name="email"
                        className="form-control formInput"
                        placeholder="Enter your email address"
                        value={loginData.email}
                        onChange={changeEveVaue}
                      />
                    </div>
                    <div className="inputBox">
                      <label htmlFor="" className="emailLabel">
                        Password
                      </label>
                      <img src={passBox} className="mailImg" alt="" />
                      {/* <img src={eye} className="eyeImg" alt="" /> */}
                      <div className="eyePosition" onClick={togglePassword}>
                        {passwordType === "password" ? (
                          <img src={ic_show} className="absolutEye" alt="" />
                        ) : (
                          <img src={eye} className="absolutEye" alt="" />
                        )}
                      </div>
                      <input
                        className="form-control formInput"
                        placeholder="Enter your Password"
                        type={passwordType}
                        name="password"
                        value={loginData.password}
                        onChange={changeEveVaue}
                      />
                      <div className="belowChecks">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            Remember me
                          </label>
                        </div>
                        <div className="forgetPass">
                          <Link to="/ForgotPass" className="">
                            Forgot Password ?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        cursor: "pointer",
                        marginBottom: "20px",
                      }}
                      onClick={() => {
                        setIsLoginWithOTP(false);
                      }}
                    >
                      <img src={ic_left_green_arrow} />{" "}
                    </div>
                    <div className="inputBox">
                      <div
                        style={{
                          display: "flex",

                          justifyContent: "space-between",
                        }}
                      >
                        <label htmlFor="" className="emailLabel">
                          Mobile Number
                        </label>
                        {seconds == 60 && (
                          <button
                            type="button"
                            className="requestBtn"
                            // disabled={!forgotData?.phone_number ? true : false}
                            onClick={requestOTP}
                          >
                            Request OTP
                          </button>
                        )}
                      </div>

                      <img src={telephone1} className="mailImg" alt="" />
                      <input
                        type="text"
                        name="phone_number"
                        className="form-control formInput"
                        placeholder="Enter your mobile number"
                        value={loginWithOTPData?.phone_number}
                        onChange={(e) => {
                          setLoginWithOTPData({
                            ...loginWithOTPData,
                            phone_number: e.target.value,
                          });
                        }}
                      />
                      {seconds !== 60 && (
                        <button
                          type="button"
                          className="resendotpbtn"
                          onClick={() => {
                            if (seconds == 0) {
                              // handleOtp();
                              requestOTP();
                            }
                          }}
                        >
                          Re-send OTP ({seconds})
                        </button>
                      )}
                    </div>
                    <div className="inputBox">
                      <label htmlFor="" className="emailLabel">
                        OTP
                      </label>
                      <img src={passBox} className="mailImg" alt="" />

                      <input
                        className="form-control formInput"
                        placeholder="Enter OTP..."
                        type={passwordType}
                        name="otp"
                        value={loginWithOTPData?.otp}
                        onChange={(e) => {
                          setLoginWithOTPData({
                            ...loginWithOTPData,
                            otp: e.target.value,
                          });
                        }}
                      />

                      <div className="belowChecks">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            Remember me
                          </label>
                        </div>
                        <div className="forgetPass">
                          <Link to="/ForgotPass" className="">
                            Forgot Password ?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="login btn-width-100">
                  <DarkButton
                    type="submit"
                    classbtn={"cx-btn-dark btn-width-100 btn-height font-17"}
                    btn={"Login"}
                  />
                </div>
                {!isLoginWithOTP && (
                  <div
                    onClick={() => {
                      setIsLoginWithOTP(true);
                    }}
                    style={{
                      textAlign: "center",
                      paddingBottom: "10px",
                      paddingTop: "15px",
                      cursor: "pointer",
                      color: "#000842",
                    }}
                  >
                    or continue with OTP
                  </div>
                )}
                {/* <div className="social-account-login">
                  <label htmlFor="">or continue with</label>
                  <div className="social-btns">
                    <Link className="fb">
                      <img src={fb} alt="" />
                    </Link>
                    <Link className="fb">
                      <img src={apple} alt="" />
                    </Link>
                    <Link className="fb">
                      <img src={google} alt="" />
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="landingImg">
                <p className="contactNumber">+91 9509932234</p>
                <img src={login_img} alt="" />
                <p className="containstart">Sign In to Pet Shop App</p>
                <p className="letsstart">Lets get started</p>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default Login;
