import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import InventoryBack from "../../assets/images/pet-shop-img/InventoryBack.svg";
import Star from "../../assets/images/pet-shop-img/Star.svg";
import ic_filter from "../../assets/images/ic_filter.svg";
import Add from "../../assets/images/pet-shop-img/Add_icon.svg";
import InventoryProduct from "../../assets/images/pet-shop-img/InventoryProduct.svg";
import InventoryProduc2 from "../../assets/images/pet-shop-img/InvenotyrBack2.svg";

import Form from "react-bootstrap/Form";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import {
  getWithAuthCall,
  simpleGetCallWithToken,
  simplePostAuthCall,
  simplePutAuthCall,
} from "../../api/ApiServices";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import Loader from "../../sharedComponent/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import Select from "react-select";
import { formatCurrency } from "../../common/common";

const Inventory = () => {
  const [productCat, setProductCat] = useState("All");

  const [ProductNameFilter, setProductNameFilter] = useState("");
  const [ProductNameFilterInvtrey, setProductNameInvtrey] = useState("");
  const [BrandFilter, setProductBrandFilter] = useState("");
  const [ProductBrandFilter, setProductCategoryFilter] = useState("");
  const [last_page, setlast_page] = useState(false);
  const [TotalData, setTotalData] = useState(0);
  const [Inventory, setInventory] = useState([]);
  const [InventoryCategory, setInventoryCategory] = useState([]);
  //   console.log(Inventory?.product_images[0].product_image_path);
  const [page, setPage] = useState(1);
  const [PageCategory, setPageCategory] = useState(1);
  const [FacilitiesType, setFacilitiesType] = useState([]);
  const [ProductType, setProductType] = useState([]);
  const [SelectedProduct, setSelectedProduct] = useState({});
  const { setLoading, userData, loading, validNumber, subscriptionAPIPayload } =
    useContext(AppContext);

  useEffect(() => {
    if (ProductNameFilter === "") {
      setLoading(true);
      getDetails(page);
    } else {
      getDetails(page);
    }
  }, [ProductNameFilter, BrandFilter]);

  const getDetails = (currentPage) => {
    simplePostAuthCall(
      ApiConfig.INVENTORY_PRODUCT_LIST +
        userData.type_of_pet_shop_id +
        "&product_name=" +
        ProductNameFilter +
        "&product_type=" +
        BrandFilter +
        "&product_brand=" +
        BrandFilter +
        "&product_id=" +
        "" +
        "&page=" +
        currentPage,
      JSON.stringify(subscriptionAPIPayload)
    )
      .then((data) => {
        // setClintDetails(data?.data);

        if (data.success) {
          setLoading(false);
          let total = data.total ? data.total : 0;
          setTotalData(total);
          setlast_page(data.last_page);

          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              animal_name: eachteam.animal_name || "",
              phone_number: eachteam.phone_number || "",
            };
          });

          if (currentPage === 1) {
            data.data && setInventory(data.data);
            setLoading(false);
          } else {
            setInventory([...Inventory, ...LearningData]);
            setLoading(false);
          }
        } else {
          setInventory([]);
          setlast_page(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  useEffect(() => {
    getDetailsProduct(PageCategory);
  }, [ProductNameFilterInvtrey]);

  useEffect(() => {}, [SelectedProduct]);
  const getDetailsProduct = (currentPage) => {
    simplePostAuthCall(
      ApiConfig.INVENTORY_PRODUCT_CATEGORYLIST +
        userData.type_of_pet_shop_id +
        "&product_type=" +
        ProductNameFilterInvtrey,
      JSON.stringify(subscriptionAPIPayload)
    )
      .then((data) => {
        if (data.success) {
          let total = data.total ? data.total : 0;
          setTotalData(total);
          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              animal_name: eachteam.animal_name || "",
              phone_number: eachteam.phone_number || "",
            };
          });

          if (currentPage === 1) {
            setInventoryCategory(data.data);
            // setLoading(false);
          } else {
            setInventoryCategory([...InventoryCategory, ...LearningData]);
            // setLoading(false);
          }
        } else {
          setInventoryCategory([]);
          setlast_page(false);
          notifyError(data.message);
        }
        // setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  useEffect(() => {
    getfacilities();
    getProduct();
  }, []);

  function getfacilities() {
    getWithAuthCall(ApiConfig.ADD_INVENTORY_DROPDOWN)
      .then((data) => {
        console.log(data);
        let Type = data.data;
        var grades = [];

        Type.map((grade, index) => {
          grades.push({
            label: grade.product_type,
            value: grade.product_type_id,
          });
        });
        setFacilitiesType(grades);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  function getProduct() {
    simplePostAuthCall(
      ApiConfig.ADD_PRODUCT_DROPDOWN,
      JSON.stringify(subscriptionAPIPayload)
    )
      .then((data) => {
        console.log(data);
        let Type = data.data;
        var grades = [];

        Type.map((grade, index) => {
          grades.push({
            label: grade.product,
            value: grade.product_id,
            product_brand: grade.product_brand,
            product_code: grade.product_code,
            product_type: grade.product_type,
            product_rate: grade.product_rate,
            product_images: grade.product_images,
          });
        });
        setProductType(grades);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const onPageLoad = () => {
    // debugger
    setPage(page + 1);
    if (last_page === false) {
      getDetails(page + 1);
    } else {
    }
  };

  function onTopicChange(selectedOption) {
    console.log("selectedOption", selectedOption);
    setSelectedProduct(selectedOption);
  }

  const addProductPost = () => {
    let newRequestBody = JSON.stringify({
      product_id: SelectedProduct.value,
      product_quantity: SelectedProduct.pharmacy_shop_product_quantity,
      pet_shop_id: userData.type_of_pet_shop_id,
    });
    simplePostAuthCall(ApiConfig.ADD_PRODUCT, newRequestBody)
      .then((data) => {
        // setClintDetails(data?.data);

        if (data.success) {
          setProductCat("All");
          getDetails(1);
          notifySuccess(data.message);
          setSelectedProduct({
            pharmacy_shop_product_quantity: "",
          });
        } else {
          notifyError(data.message);
        }
        // setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const EditProductPost = (id) => {
    let newRequestBody = JSON.stringify({
      product_quantity: SelectedProduct.pet_shop_product_quantity,
      pet_shop_product_id: id,
    });
    simplePostAuthCall(ApiConfig.PRODUCT_EDIT, newRequestBody)
      .then((data) => {
        // setClintDetails(data?.data);

        if (data.success) {
          setProductCat("All");
          getDetails(1);
          notifySuccess(data.message);
        } else {
          notifyError(data.message);
        }
        // setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  function getEditbyeDetails(id) {
    simpleGetCallWithToken(ApiConfig.EDIT_BYE_DETAILS + id)
      .then((res) => {
        setSelectedProduct(res.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const disabledPetProduct = async (productId, active, pet_shop_id) => {
    try {
      const payLoad = {
        product_id: productId,
        pet_shop_id: pet_shop_id,
        is_active: active,
      };

      const res = await simplePutAuthCall(
        `${ApiConfig.ENABLE_DISABLED}`,
        JSON.stringify(payLoad)
      );
      const { success, message } = res;
      if (success) {
        notifySuccess(
          active
            ? "Product enabled successfully"
            : "Product disabled successfully"
        );
        getDetails(1);
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };
  return (
    <InfiniteScroll
      dataLength={Inventory?.length}
      next={onPageLoad}
      // hasMore={true}
      hasMore={Inventory?.length !== TotalData}
      scrollableTarget="scroll-table"
      loader={
        <h4
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginLeft: "20px",
          }}
        ></h4>
      }
    >
      {loading === true ? (
        <Loader />
      ) : (
        <div className="appointment-wrapper" id="cx-main">
          <div className="reportsTabs">
            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
              <Nav variant="pills" id="newTabMai" className="tob_nav_pills">
                <Nav.Item>
                  <Nav.Link
                    eventKey="1"
                    onClick={() => {
                      setProductCat("All");
                    }}
                  >
                    Product List
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2">Product Category</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <div className="search-filter-wrapper">
                    {productCat === "Add" || productCat === "Edit" ? (
                      <></>
                    ) : (
                      <>
                        <div className="search-filter-left">
                          <input
                            type="text"
                            className="form-control serach-input1 me-4"
                            placeholder="Search by Name..."
                            value={ProductNameFilter}
                            onChange={(e) => {
                              setProductNameFilter(e.target.value);
                            }}
                          />
                          {/* <div className="selectBox">
                            <input
                              type="text"
                              className="form-control serach-input1 me-4"
                              placeholder="Search by Brand.."
                              value={BrandFilter}
                              onChange={(e) => {
                                setProductBrandFilter(e.target.value);
                              }}
                            />
                          </div> */}
                          {/* <button className="fltr-btn">
                            <img src={ic_filter} alt="" />
                          </button> */}
                        </div>

                        <button
                          className="Top_Add_Btn"
                          onClick={() => {
                            setProductCat("Add");
                          }}
                        >
                          <img src={Add} alt="" />
                        </button>
                      </>
                    )}
                  </div>
                  {productCat === "All" ? (
                    <div className="Main_Page_Scroll mt-4">
                      <div className="row">
                        {Inventory && Inventory.length > 0 ? (
                          Inventory.map((itemlist, index) => {
                            return (
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="INventorySingle">
                                  {itemlist.avg_rating && (
                                    <div className="rating">
                                      {itemlist.avg_rating === null
                                        ? 0
                                        : itemlist.avg_rating}{" "}
                                      <span style={{ marginLeft: "5px" }}>
                                        <img src={Star} />
                                      </span>
                                    </div>
                                  )}
                                  <div className="InventoryImage">
                                    <img
                                      src={itemlist?.product_image_path}
                                      alt=""
                                    />
                                  </div>
                                  <div className="ProductDetails">
                                    <div className="productName">
                                        <p className="name"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title={itemlist.product}
                                        >
                                          {itemlist.product}
                                        </p>
                                      {/* <button
                                        type="button"
                                        class="btn btn-secondary name"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Tooltip on top"
                                      >
                                      </button> */}

                                      <p className="brand">
                                        {itemlist.product_brand}{" "}
                                        {/* {itemlist.product_type} */}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="checkSwitch">
                                    <Form.Check
                                      type="switch"
                                      // ENABLE_DISABLED
                                      checked={itemlist?.is_active}
                                      onChange={(e) => {
                                        console.log(
                                          "e.target.checked,",
                                          e.target.checked
                                        );
                                        // if (itemlist?.is_active) {
                                        disabledPetProduct(
                                          itemlist?.product_id,
                                          e.target.checked,
                                          itemlist?.pet_shop_id
                                        );
                                        // } else {
                                        //   disabledPetProduct(
                                        //     itemlist?.product_id,
                                        //     false,
                                        //     itemlist?.pet_shop_id
                                        //   );
                                        // }
                                      }}
                                    />
                                  </div>
                                  <div className="discribtion">
                                    {itemlist.product_desc}
                                  </div>
                                  <div className="prising">
                                    <div className="QuantityDetails">
                                      <p className="Quantity">Quantity</p>
                                      <p className="QuantityCount">
                                        {itemlist.pet_shop_product_quantity}
                                      </p>
                                    </div>
                                    <div className="line"></div>
                                    <div className="PriceDetails">
                                      <p className="Price">Price</p>
                                      {itemlist.product_rate &&
                                        formatCurrency(itemlist.product_rate)}
                                    </div>
                                  </div>
                                  <div className="edit_btn">
                                    <button
                                      className="btn-width-100 cx-btn-dark-2"
                                      onClick={() => {
                                        setProductCat("Edit");
                                        getEditbyeDetails(
                                          itemlist.pet_shop_product_id
                                        );
                                      }}
                                    >
                                      Edit Product
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                      {Inventory.length ? (
                        ""
                      ) : (
                        <p
                          style={{
                            fontSize: "20px",
                            marginTop: "200px",
                          }}
                          className=" text-center justify-content-center align-items-center"
                        >
                          Oops! It doesn't look like there are any Inventory?
                        </p>
                      )}
                    </div>
                  ) : null}

                  {/* ////                          Edit  view                     /// */}
                  {productCat === "Edit" ? (
                    <>
                      <div className="Form_main mt-4">
                        <div className="Heading">Edit Your Project</div>
                        <div className="Scroll_With_Bottom_Btn">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                              <div className="common-input-section">
                                <label htmlFor="">Category</label>
                                <input
                                  type="text"
                                  placeholder="Product Name"
                                  value={SelectedProduct?.product_type}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                              <div className="common-input-section">
                                <label htmlFor="">Product</label>
                                <input
                                  type="text"
                                  placeholder="Product Brand"
                                  value={SelectedProduct?.product}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                              <div className="common-input-section">
                                <label htmlFor="">Brand</label>
                                <input
                                  type="text"
                                  placeholder="Product Brand"
                                  value={SelectedProduct?.product_brand}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                              <div className="common-input-section">
                                <label htmlFor="">Code</label>

                                <input
                                  type="text"
                                  placeholder="Product Brand"
                                  value={SelectedProduct?.product_code}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                              <div className="common-input-section">
                                <label htmlFor="">Quantity</label>
                                <input
                                  type="text"
                                  placeholder="Enter Quantity"
                                  value={
                                    SelectedProduct?.pet_shop_product_quantity
                                  }
                                  onChange={(e) => {
                                    if (validNumber.test(e.target.value)) {
                                      setSelectedProduct({
                                        ...SelectedProduct,
                                        pet_shop_product_quantity:
                                          e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                              <div className="common-input-section">
                                <label htmlFor="">Rate</label>
                                <input
                                  type="text"
                                  placeholder="Enter Rate of Product"
                                  value={SelectedProduct.product_rate}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="common-input-section">
                                <label htmlFor="">Product Preview</label>
                                <div className="InventoryProduct">
                                  {SelectedProduct?.product_images &&
                                  SelectedProduct?.product_images.length > 0 ? (
                                    SelectedProduct.product_images.map(
                                      (itemlist, index) => {
                                        return (
                                          <>
                                            {itemlist.product_image_path ===
                                              null ||
                                            itemlist.product_image_path ===
                                              "" ? (
                                              <img
                                                src={InventoryProduct}
                                                alt=""
                                                height="100px"
                                                width="100px"
                                              />
                                            ) : (
                                              <img
                                                src={
                                                  itemlist.product_image_path
                                                }
                                                alt=""
                                                height="100px"
                                                width="100px"
                                              />
                                            )}
                                          </>
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="Button_Bottom">
                        <button
                          className="cx-btn-Light"
                          onClick={() => {
                            setProductCat("All");
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="cx-btn-dark"
                          //  onClick={() => { setProductCat("All") }}
                          onClick={() => {
                            EditProductPost(
                              SelectedProduct.pet_shop_product_id
                            );
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </>
                  ) : null}

                  {productCat === "Add" ? (
                    <>
                      <div className="Form_main mt-4">
                        <div className="Heading">Add Your Project</div>
                        <div className="Scroll_With_Bottom_Btn">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                              <div className="common-input-section">
                                <label htmlFor="">Category</label>
                                <Select
                                  options={ProductType}
                                  disabled={productCat === "Add" ? false : true}
                                  onChange={onTopicChange}
                                  autoFocus={true}
                                  // placeholder={"Select Languages..."}
                                  placeholder={
                                    <div className="select-placeholder-text">
                                      Select Product...
                                    </div>
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                              <div className="common-input-section">
                                <label htmlFor="">Product</label>
                                <input
                                  type="text"
                                  placeholder="Product Brand"
                                  value={SelectedProduct?.product_type}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                              <div className="common-input-section">
                                <label htmlFor="">Brand</label>
                                <input
                                  type="text"
                                  placeholder="Product Brand"
                                  value={SelectedProduct.product_brand}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                              <div className="common-input-section">
                                <label htmlFor="">Code</label>
                                <input
                                  type="text"
                                  placeholder="Product Code"
                                  value={SelectedProduct.product_code}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                              <div className="common-input-section">
                                <label htmlFor="">Quantity</label>
                                <input
                                  type="text"
                                  placeholder="Enter Quantity"
                                  value={
                                    SelectedProduct.pharmacy_shop_product_quantity
                                  }
                                  onChange={(e) => {
                                    if (validNumber.test(e.target.value)) {
                                      setSelectedProduct({
                                        ...SelectedProduct,
                                        pharmacy_shop_product_quantity:
                                          e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                              <div className="common-input-section">
                                <label htmlFor="">Rate</label>
                                <input
                                  type="text"
                                  placeholder="Enter Rate of Product"
                                  value={SelectedProduct.product_rate}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="common-input-section">
                                <label htmlFor="">Product Preview</label>
                                <div className="InventoryProduct">
                                  {/* <img src={InventoryProduct} alt="" /> */}
                                  {SelectedProduct.product_images &&
                                  SelectedProduct.product_images.length > 0 ? (
                                    SelectedProduct.product_images.map(
                                      (itemlist, index) => {
                                        return (
                                          <>
                                            {itemlist.product_image_path ===
                                              null ||
                                            itemlist.product_image_path ===
                                              "" ? (
                                              <img
                                                src={InventoryProduct}
                                                alt=""
                                                height="100px"
                                                width="100px"
                                              />
                                            ) : (
                                              <img
                                                src={
                                                  itemlist.product_image_path
                                                }
                                                alt=""
                                                height="100px"
                                                width="100px"
                                              />
                                            )}
                                          </>
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                {Inventory.length ? (
                                  ""
                                ) : (
                                  <p
                                    style={{
                                      fontSize: "20px",
                                      marginTop: "200px",
                                    }}
                                    className=" text-center justify-content-center align-items-center"
                                  >
                                    Oops! It doesn't look like there are any
                                    Inventory?
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="Button_Bottom">
                        <button
                          className="cx-btn-Light"
                          onClick={() => {
                            setProductCat("All");
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="cx-btn-dark"
                          onClick={() => {
                            addProductPost();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </>
                  ) : null}
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <div className="search-filter-wrapper">
                    <div className="search-filter-left">
                      <>
                        <input
                          type="text"
                          className="form-control serach-input1 "
                          placeholder="Search by name..."
                          value={ProductNameFilterInvtrey}
                          onChange={(e) => {
                            setProductNameInvtrey(e.target.value);
                          }}
                        />
                        {/* <button className="fltr-btn">
                          <img src={ic_filter} alt="" />
                        </button> */}
                      </>
                    </div>
                  </div>
                  <div className="Main_Page_Scroll mt-4">
                    <div className="row">
                      {InventoryCategory && InventoryCategory.length > 0 ? (
                        InventoryCategory.map((itemlist, index) => {
                          return (
                            <div className="col-lg-3 col-md-6 col-sm-12">
                              <div className="INventorySingle">
                                <div className="InventoryImage mb-2">
                                  <img src={InventoryProduc2} alt="" />
                                </div>
                                <div className="ProductDetails">
                                  <div className="productName">
                                    <p className="name">{itemlist.category}</p>
                                  </div>
                                </div>
                                <div className="prising mb-0">
                                  <div className="QuantityDetails">
                                    <p className="Quantity LightColor">
                                      Products
                                    </p>
                                    <p className="QuantityCount">
                                      {itemlist.total_products}
                                    </p>
                                  </div>
                                  <div className="line"></div>
                                  <div className="PriceDetails">
                                    <p className="Price LightColor">Brands</p>
                                    <p className="PriceCount">
                                      {" "}
                                      {itemlist.total_brands}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* {Inventory.length ? (
                      ""
                    ) : (
                      <p
                        style={{
                          fontSize: "20px",
                          marginTop: "200px",
                        }}
                        className=" text-center justify-content-center align-items-center"
                      >
                        Oops! It doesn't look like there are any Inventory?
                      </p>
                    )} */}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      )}
    </InfiniteScroll>
  );
};

export default Inventory;
