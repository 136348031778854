import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { motion } from "framer-motion";
import { simpleGetCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import moment from "moment";
export const getDate = (inputDate) => {
  let date = new Date(inputDate);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  //dd-mm-yyyy
  //YYYY-MM-DD
  //MM-DD-YYYY
  //dd/mm/yyyy

  var formattedDate = `${day < 10 ? 0 : ""}${day}-${
    month < 10 ? 0 : ""
  }${month}-${year}`;

  return formattedDate;
};
const Notification = () => {
  const { sidebar, setSidebar, userData, noticationList, setnoticationList, setNotificationCount, socket } =
    useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const notificationsIdsArr = noticationList ? noticationList.map(ele => ele.notification_id) : []
  const readNotification = notification_ids => {
    socket &&
      socket.emit('notification read', { notification_id: notification_ids })
  }


  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "cx-main-wrapper" : "cx-main-wrapper cx-active"}
        id="cx-main"
      >
        <div className="notificationWrapper">
          <div className="insideTable">
            <table className="tableNoti">
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Name</th>
                  <th>Message</th>
                  <th>Time/ Date </th>
                  <th><span 
                      onClick={() => {
                        readNotification(notificationsIdsArr)
                        setTimeout(() => {
                          setnoticationList([])
                          setNotificationCount(0)
                        }, 1000)
                      }}
                      >Mark as Read All</span></th>
                </tr>
              </thead>
              <tbody>
                {noticationList && noticationList.length > 0 ? (
                  noticationList.map((itemlist, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{itemlist.notification_sender_name}</td>

                        <td>
                          <div
                            className="scrollerHeight text-truncate"
                            style={{ maxWidth: "400px" }}
                          >
                            {itemlist.notification_text}.
                          </div>
                        </td>
                        <td>
                          {getDate(itemlist.notification_date)}{" "} / {moment(itemlist.notification_time, 'hh:mm A').format("hh:mm")} 
                        </td>
                        <td>
                            {' '}
                            <span
                              onClick={() => {
                                readNotification([itemlist?.notification_id])
                                setTimeout(() => {
                                  const filteredData = noticationList.filter(innerItem => innerItem.notification_id !== itemlist?.notification_id)
                                  setnoticationList(filteredData)
                                  setNotificationCount(filteredData.length)
                                }, 1000)
                              }}
                            >
                              Mark as read
                            </span>
                          </td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
          {noticationList?.length ? (
            ""
          ) : (
            <p
              style={{
                fontSize: "20px",
                marginTop: "200px",
              }}
              className=" text-center justify-content-center align-items-center"
            >
              Oops! It doesn't look like there are any Notification?
            </p>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default Notification;
