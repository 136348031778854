import React, { useState } from "react";
import yourLogo from "../../assets/images/mainLogo.svg";
import teleCall from "../../assets/images/icons/telephone.svg";
import insiderImg from "../../assets/images/icons/landigIcon.svg";
import lock from "../../assets/images/icons/lock.svg";
import otp_icon from "../../assets/images/icons/otp_icon.svg";
import eye from "../../assets/images/icons/eye.svg";
import ResetPass_img from "../../assets/images/ResetPass.svg";
import fb from "../../assets/images/fb.svg";
import apple from "../../assets/images/apple.svg";
import ForgotPass_img from "../../assets/images/pet-shop-img/forgetPass.svg";
import google from "../../assets/images/google.svg";
import { Link, useNavigate } from "react-router-dom";
import { DarkButton } from "../commonComponents/commonComp";
import { simplePostAuthCallNoTokan } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import Form from "react-bootstrap/Form";
import ic_hide_eyes from "../../assets/images/ic_hide_eyes.svg";
import ic_show from "../../assets/images/ic_show_eyes.svg";
import ic_left_green_arrow from "../../assets/images/ic_left_green_arrow.svg";

const ResetPass = () => {
  const navigate = useNavigate();
  // const navigate = useNavigate("");
  const [passwordType, setPasswordType] = useState("password");
  const [confPasswordType, setConfPasswordType] = useState("password");
  const [validated, setValidated] = useState(false);
  const [resetData, setResetData] = useState({
    pet_shop_registration_id: localStorage.getItem("pet_shop_registration_id"),
    new_password: null,
    confirm_password: null,
  });
  console.log(resetData);

  const changeEveVaue = (e) => {
    setResetData({
      ...resetData,
      [e.target.name]: e.target.value,
    });
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const ConfirmPassword = () => {
    if (confPasswordType === "password") {
      setConfPasswordType("text");
      return;
    }
    setConfPasswordType("password");
  };
  const [errMsg, setErrMsg] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (resetData?.new_password !== resetData?.confirm_password) {
      return setErrMsg("Password does not match");
    }
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      resetPassword(event);
    }
    setValidated(true);
  };

  const resetPassword = (event) => {
    event.preventDefault();
    simplePostAuthCallNoTokan(
      ApiConfig.RESET_PASSWORD,
      JSON.stringify(resetData)
    )
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          localStorage.clear();
          navigate("/");
        }
      })
      .catch((err) => {
        notifyError(err);
      });
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="authMain">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <img src={yourLogo} className="logoImg" alt="" />
              <div className="signinForm">
                <h1 className="signInTxt">Reset your password ?</h1>
                <p className="signInDesc">
                You can reset your password Here
                  {/* If you forgot your password, Don't Worry ! <br /> You can
                  request
                  <Link className="link_common" to="/">
                    New here !
                  </Link> */}
                </p>
                <Form.Group className="inputBox">
                  <Form.Label htmlFor="" className="emailLabel">
                    Create new password
                  </Form.Label>
                  <img src={lock} className="mailImg" alt="" />{" "}
                  <div className="eyePosition" onClick={togglePassword}>
                    {passwordType === "password" ? (
                      <img src={ic_show} className="absolutEye" alt="" />
                    ) : (
                      <img src={ic_hide_eyes} className="absolutEye" alt="" />
                    )}
                  </div>
                  <Form.Control
                    required
                    name="new_password"
                    value={resetData.new_password}
                    onChange={changeEveVaue}
                    type={passwordType}
                    className="form-control formInput"
                    placeholder="Enter your new password"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter new password
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="inputBox">
                  <Form.Label htmlFor="" className="emailLabel">
                    Re-enter Password
                  </Form.Label>
                  <img src={lock} className="mailImg" alt="" />
                  <div className="eyePosition" onClick={ConfirmPassword}>
                    {confPasswordType === "password" ? (
                      <img src={ic_show} className="absolutEye" alt="" />
                    ) : (
                      <img src={ic_hide_eyes} className="absolutEye" alt="" />
                    )}
                  </div>
                  <Form.Control
                    required
                    name="confirm_password"
                    value={resetData.confirm_password}
                    onChange={(e) => {
                      changeEveVaue(e)
                      setErrMsg("")
                    }}
                    type={confPasswordType}
                    className="form-control formInput"
                    placeholder="Re-enter your Password"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter confirm password
                  </Form.Control.Feedback>
                  {errMsg && (
                    <div style={{ color: "#dc3545", fontSize: "0.875em" }}>
                      {errMsg}
                    </div>
                  )}
                </Form.Group>
                <div className="login btn-width-100">
                  <DarkButton
                    type="submit"
                    classbtn={"cx-btn-dark btn-width-100 btn-height font-17"}
                    btn={"Reset Password"}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="landingImg">
                <p className="contactNumber">+91 9509932234</p>
                <img src={ForgotPass_img} alt="" />
                <p className="containstart">Forgot Password ?</p>
                <p className="letsstart">No Worries ! We got your back.</p>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ResetPass;
