import React, { useState } from "react";
import yourLogo from "../../assets/images/mainLogo.svg";
import edu_icon from "../../assets/images/icons/edu_icon.svg";
import exp_icon from "../../assets/images/icons/exp_icon.svg";
import regi_icon from "../../assets/images/icons/regi_icon.svg";
import sign_icon from "../../assets/images/icons/sign_icon.svg";
import specilization_icon from "../../assets/images/icons/specilization_icon.svg";
import Service_icon from "../../assets/images/icons/Service_icon.svg";
import age_icon from "../../assets/images/icons/age_icon.svg";
import gender_icon from "../../assets/images/icons/gender_icon.svg";
import user_icon from "../../assets/images/icons/user_icon.svg";
import img_preview_delete_icon from "../../assets/images/icons/img_preview_delete_icon.svg";
import generalInfo from "../../assets/images/generalInfo.svg";
import UploadPP_icon from "../../assets/images/UploadPP_icon.svg";
import { DarkButton } from "../commonComponents/commonComp";
import Select from "react-select";

const GeneralInfo = () => {
  const [file, setFile] = useState();
  const [signature, setSignature] = useState();
  const [deleteImg, setDeleteImg] = useState(true);

  function handleFileInput(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  function handleFileInput2(e) {
    setSignature(URL.createObjectURL(e.target.files[0]));
    setDeleteImg(true);
  }

  const handleDeleteImg = () => {
    setDeleteImg(!deleteImg);
  };

  const options = [
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
    { value: "Marathi", label: "Marathi" },
    { value: "Urdu", label: "Urdu" },
  ];

  const [selected, setSelected] = useState(null);

  const handleChange = (e) => {
    setSelected(e.value);
  };
  // const [selectedOption, setSelectedOption] = useState("");

  // const handleChange1 = (selectedOption) => {
  //   setSelectedOption(selectedOption.value);
  // };

  return (
    <>
      <div className="authMain">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <img src={yourLogo} className="logoImg" alt="" />
            <div className="signinForm signUp general_info">
              <h1 className="signInTxt">General Information</h1>
              <div className="profile_upload">
                <div className="file-input">
                  <input
                    type="file"
                    name="file-input"
                    id="file-input"
                    className="file-input__input"
                    onChange={handleFileInput}
                  />
                  <label className="file-input__label" for="file-input">
                    <span className="file_preview">
                      <img src={file ? file : UploadPP_icon} alt="" />
                    </span>
                  </label>
                  <label className="file-input__label2" for="file-input">
                    <span>Upload Profile Picture</span>
                  </label>
                </div>
                {/* <div className={file ? "file_preview" : "file_preview2"}>
                  <img src={file} alt="" />
                </div> */}
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  First Name
                </label>
                <img src={user_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your First name"
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Last Name
                </label>
                <img src={user_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your Last name"
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Age
                </label>
                <img src={age_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter Age"
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Gender
                </label>
                <img src={gender_icon} className="mailImg" alt="" />
                <select className="form-select formInput">
                  <option value="">Select Gender</option>
                  <option value="">Male</option>
                  <option value="">Female</option>
                  <option value="">Other</option>
                </select>
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Services
                </label>
                <img src={Service_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your services"
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Specialization
                </label>
                <img src={specilization_icon} className="mailImg" alt="" />
                <select className="form-select formInput">
                  <option value="">Select your specialization</option>
                  <option value="">specialization 1</option>
                  <option value="">specialization 2</option>
                  <option value="">specialization 3</option>
                </select>
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Education
                </label>
                <img src={edu_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your education"
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Experience
                </label>
                <img src={exp_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your experience"
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Known Languages
                </label>
                <Select
                  className=""
                  isMulti
                  options={options}
                  // onChange={handleChange}
                  onChange={(e) => {
                    setSelected(e);
                  }}
                  autoFocus={true}
                  // placeholder={"Select Languages..."}
                  placeholder={
                    <div className="select-placeholder-text">
                      Select Languages...
                    </div>
                  }
                />
                <img src={exp_icon} className="mailImg" alt="" />
                <div className="mt-4">
                  {selected?.map((item, i) => {
                    return <p></p>;
                  })}
                </div>
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Registration No.
                </label>
                <img src={regi_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your registration number/ certificate no."
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Signature
                </label>
                <div className="file-input">
                  <input
                    type="file"
                    name="file-input"
                    id="file-input2"
                    className="file-input__input"
                    onChange={handleFileInput2}
                  />
                  <img src={sign_icon} className="mailImg2" alt="" />
                  <label className="file-input__label2" for="file-input2">
                    Upload Profile Picture
                  </label>
                </div>
              </div>
              {deleteImg && (
                <>
                  <div className={signature ? "sign_preview" : "d-none"}>
                    <img src={signature} alt="" />
                    <span>
                      <img
                        src={img_preview_delete_icon}
                        className="delete_icon"
                        onClick={handleDeleteImg}
                        alt=""
                      />
                    </span>
                  </div>
                </>
              )}

              <div className="login btn-width-100">
                <DarkButton
                  classbtn={
                    "cx-btn-light me-3  btn-width-45 btn-height font-17 mb-5"
                  }
                  btn={"Back"}
                />
                <DarkButton
                  classbtn={"cx-btn-dark  btn-height btn-width-50 font-17 mb-5"}
                  btn={"Save & Proceed"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="landingImg">
              <img src={generalInfo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralInfo;
