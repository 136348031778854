import React, { useState } from "react";
import yourLogo from "../../assets/images/mainLogo.svg";
import edu_icon from "../../assets/images/icons/edu_icon.svg";
import tele_icon from "../../assets/images/icons/tele_icon.svg";
import verification_success from "../../assets/images/pet-shop-img/succesVerifay.svg";
import UploadPP_icon from "../../assets/images/UploadPP_icon.svg";
import { DarkButton } from "../commonComponents/commonComp";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const VerificationSuccess = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [signature, setSignature] = useState();
  const [deleteImg, setDeleteImg] = useState(true);

  function handleFileInput(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  function handleFileInput2(e) {
    setSignature(URL.createObjectURL(e.target.files[0]));
    setDeleteImg(true);
  }

  const handleDeleteImg = () => {
    setDeleteImg(!deleteImg);
  };

  const options = [
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
    { value: "Marathi", label: "Marathi" },
    { value: "Urdu", label: "Urdu" },
  ];

  const [selected, setSelected] = useState(null);

  const handleChange = (e) => {
    setSelected(e.value);
  };
  // const [selectedOption, setSelectedOption] = useState("");

  // const handleChange1 = (selectedOption) => {
  //   setSelectedOption(selectedOption.value);
  // };

  return (
    <>
      <div className="authMain">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <img src={yourLogo} className="logoImg" alt="" />
            <div className="verification_wrapper">
              <div className="verification_inner">
                <h2 className="vfi_title">
                  LET BEGIN THE <br /> JOURNEY
                </h2>
                <p className="vfi_desc">
                  Your request has been approved successfully. Thank You For your visit.
                </p>
                <div className="login btn-width-100 d-flex justify-content-end">
                  <DarkButton
                    onClick={() => navigate("/")}
                    classbtn={"cx-btn-dark w-100  btn-height font-17 mb-5"}
                    btn={"Get Started !"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            {/* <div className="landingImg">
              <p className="tele"> <img src={tele_icon} alt="" /> +94 0116 789 754</p>
              <img src={verification_success} alt="" />
              <div className="text_wrapper">
                <h2 className="tw_title">Congratulation ! John Doe</h2>
                <p className="tw-sub_title">Lets Start the Journey...</p>
              </div>
            </div> */}
            <div className="landingImg">
              <p className="contactNumber">+91 9509932234</p>
              <img
                src={verification_success}
                alt=""
                style={{ width: "70%", margin: "auto" }}
              />
              <p className="containstart">Congratulation </p>
              <p className="letsstart">Lets Start the Journey...</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationSuccess;
