import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/main.scss";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Login from "./pages/Auth/Login";
import ForgotPass from "./pages/Auth/ForgotPass";
import ResetPass from "./pages/Auth/ResetPass";
import SignUp from "./pages/Auth/SignUp";
import GeneralInfo from "./pages/Auth/GeneralInfo";
import BankDetails from "./pages/Auth/BankDetails";
import VerificationUnderProcess from "./pages/Auth/VerificationUnderProcess";
import VerificationSuccess from "./pages/Auth/VerificationSuccess";
import Dashboard from "./pages/Dashboard/Dashboard";
import Sidebar from "./sharedComponent/Sidebar";
import Header from "./sharedComponent/Header";
import ClinicInfo from "./pages/Auth/ClinicInfo";
import UploadDocs from "./pages/Auth/UploadDocs";
import ClinicSchedule from "./pages/Auth/ClinicSchedule";
import CustomerDetails from "./pages/CustomerDetails/CustomerDetails";
import AtClinic from "./pages/Appointments/AtClinic";
import Video from "./pages/Appointments/Video";
import TransactionDetails from "./pages/TransactionDetails/TransactionDetails";
import Subscription from "./pages/Subscription/Subscription";
import MyProfile from "./pages/MyProfile/MyProfile";
import AddClinic from "./pages/MyProfile/AddClinic";
import AddTeam from "./pages/MyProfile/AddTeam";
import Inventory from "./pages/Inventory/Inventory";
import SalesReports from "./pages/Reports/SalesReports";
import InventoryReports from "./pages/Reports/InventoryReports";
import Order from "./pages/Order/Order";
import Customers from "./pages/Customers/Customers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notification from "./sharedComponent/Notification";
function App() {
  const location = useLocation();
  let currentRoute = location.pathname;
  const navigate = useNavigate();
  const [logedIn, setLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") ? localStorage.getItem("isLoggedIn") : false
  );
 
  useEffect(() => {

    if (!logedIn) {
      if (


        currentRoute.includes("ForgotPass") ||
        currentRoute.includes("ResetPass") ||
        currentRoute.includes("SignUp") ||
        currentRoute.includes("GeneralInfo") ||
        currentRoute.includes("BankDetails") ||
        currentRoute.includes("VerificationUnderProcess") ||
        currentRoute.includes("VerificationSuccess") ||
        currentRoute.includes("UploadDocs") ||
        currentRoute.includes("ClinicInfo") ||
        currentRoute.includes("ClinicSchedule")
      ) {
        navigate(currentRoute);
      } else navigate("/");
    } else {
      if (currentRoute !== "/") {
        navigate(currentRoute);
      } else navigate("/Dashboard");
    }
  }, [logedIn, currentRoute]);
  return (
    <>
      <ToastContainer />
      <div className="App">
        <div className="auth-container">
          <Routes>
            <Route path="/" element={<Login setLoggedIn={setLoggedIn} />} />
            <Route path="/ForgotPass" element={<ForgotPass />} />
            <Route path="/ResetPass" element={<ResetPass />} />
            <Route path="/SignUp" element={<SignUp />} />
            <Route path="/GeneralInfo" element={<GeneralInfo />} />
            <Route path="/BankDetails" element={<BankDetails />} />
            <Route
              path="/VerificationUnderProcess"
              element={<VerificationUnderProcess />}
            />
            <Route
              path="/VerificationSuccess"
              element={<VerificationSuccess />}
            />
            <Route path="/ClinicInfo" element={<ClinicInfo />} />
            <Route path="/UploadDocs" element={<UploadDocs />} />
            <Route path="/ClinicSchedule" element={<ClinicSchedule />} />
          </Routes>
        </div>
        <div className="main-wrapper">
          {currentRoute !== "/" &&
            currentRoute !== "/ForgotPass" &&
            currentRoute !== "/ResetPass" &&
            currentRoute !== "/SignUp" &&
            currentRoute !== "/GeneralInfo" &&
            currentRoute !== "/BankDetails" &&
            currentRoute !== "/VerificationUnderProcess" &&
            currentRoute !== "/VerificationSuccess" &&
            currentRoute !== "/ClinicInfo" &&
            currentRoute !== "/UploadDocs" &&
            currentRoute !== "/ClinicSchedule" && (
              <>
                <Sidebar />
                <Header setLoggedIn={setLoggedIn} />
              </>
            )}
          <Routes>
            {/* ============== DASHBOARD ============= */}
            <Route path="Dashboard" element={<Dashboard />} />
            <Route path="Notification" element={<Notification />} />
            {/* =============== Inventory =============*/}
            <Route path="Inventory" element={<Inventory />} />
            <Route path="CustomerDetails" element={<CustomerDetails />} />
            {/* ============== REPORTS ============= */}
            <Route path="SalesReports" element={<SalesReports />} />
            <Route path="InventoryReports" element={<InventoryReports />} />
            {/* ============== Order ============= */}
            <Route path="Order" element={<Order />} />
            {/* ============== Subscription ============= */}
            <Route path="Subscription" element={<Subscription />} />
            {/* ============== Customers ============= */}
            <Route path="Customers" element={<Customers />} />
            {/* ============== TransactionDetails ============= */}
            <Route path="TransactionDetails" element={<TransactionDetails />} />
            {/* ============== MyProfile ============= */}
            <Route path="MyProfile" element={<MyProfile />} />
            <Route path="AddClinic" element={<AddClinic />} />
            <Route path="AddTeam" element={<AddTeam />} />
            {/* ============== APPOINTMENT ============= */}
            <Route path="AtClinic" element={<AtClinic />} />
            <Route path="Video" element={<Video />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
