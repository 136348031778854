import React, { useEffect, useState } from "react";
import yourLogo from "../../assets/images/mainLogo.svg";
import edu_icon from "../../assets/images/icons/edu_icon.svg";
import exp_icon from "../../assets/images/icons/exp_icon.svg";
import regi_icon from "../../assets/images/icons/regi_icon.svg";
import sign_icon from "../../assets/images/icons/sign_icon.svg";
import clinic_img_1 from "../../assets/images/clinic_img_1.svg";
import clinic_img_2 from "../../assets/images/clinic_img_2.svg";
import clinic_img_3 from "../../assets/images/clinic_img_3.svg";
import clinic_img_4 from "../../assets/images/clinic_img_4.svg";
import docmunataion_icon from "../../assets/images/pet-shop-img/docmunataion_icon.svg";
import location_icon from "../../assets/images/icons/location_icon.svg";

import gender_icon from "../../assets/images/icons/gender_icon.svg";
import clinic_icon from "../../assets/images/pet-shop-img/shop_icon.svg";
import img_preview_delete_icon from "../../assets/images/icons/img_preview_delete_icon.svg";
import clinic_info_img from "../../assets/images/clinic_info_img.svg";
import { DarkButton } from "../commonComponents/commonComp";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import login_img from "../../assets/images/pet-shop-img/Login_img.svg";
import {
  simpleGetCallnoTokan,
  simplePostAuthCallNoTokan,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import Form from "react-bootstrap/Form";
import AutocompleteGoogleInput from "../../sharedComponent/AutocompleteGoogleInput";

const ClinicInfo = () => {
  const options = [
    {
      value: "OPD (Allopathy & Homeopathy)",
      label: "OPD (Allopathy & Homeopathy)",
    },
    { value: "Dental facility", label: "Dental facility" },
    { value: "Ward/ Indoor", label: "Ward/ Indoor" },
    { value: "Minor OT/ Dressing Room", label: "Minor OT/ Dressing Room" },
    { value: "Laboratory services", label: "Laboratory services" },
  ];
  const navigate = useNavigate();

  const [selected, setSelected] = useState(null);
  const [validated, setValidated] = useState(false);

  const [shopTypes, setShopTypes] = useState([]);
  const [ShopInfo, setShopInfo] = useState({
    pet_shop_registration_id: localStorage.getItem("pet_shop_registration_id"),
    shop_name: "",
    shop_address: "",
    type_of_pet_shop: null,
    shop_license_number: null,
    pet_shop_longitude: "",
    pet_shop_latitude: "",
    
  });
console.log("ShopInfo,", ShopInfo)
  const changeEveValue = (e) => {
    setShopInfo({
      ...ShopInfo,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      addShopDetails(event);
    }
    setValidated(true);
  };

  const addShopDetails = (event) => {
    event.preventDefault();
    simplePostAuthCallNoTokan(
      ApiConfig.ADD_SHOP_DETAILS,
      JSON.stringify(ShopInfo)
    )
      .then((res) => {
        console.log("res", res);
        // if (res.success) {

        notifySuccess(res.message);
        console.log("res", res.success);

        navigate("/VerificationSuccess");
        // }
      })
      .catch((err) => {
        notifyError(err.message);
      });
  };

  const fetchShopType = () => {
    simpleGetCallnoTokan(ApiConfig.GET_LIST_OF_LANGUAGAES)
      .then((res) => {
        if (res.success === true) {
          setShopTypes(res?.shop_type);
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchShopType();
  }, []);

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="authMain">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <img src={yourLogo} className="logoImg" alt="" />
              <div className="signinForm signUp general_info">
                <h1 className="signInTxt mb-2">Shop Information</h1>
                <p className="signInDesc">
                  If you already have an account register <br /> You can
                  <Link className="link_common" to="/">
                    Login here !
                  </Link>
                </p>
                <div className="inputBox">
                  <Form.Group className="inputBox">
                    <Form.Label htmlFor="" className="emailLabel">
                      Shop Name
                    </Form.Label>
                    <img src={clinic_icon} className="mailImg" alt="" />
                    <Form.Control
                      required
                      name="shop_name"
                      value={ShopInfo.shop_name}
                      onChange={changeEveValue}
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter your shop name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Provide Shop Name
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="inputBox">
                  <Form.Group className="inputBox">
                    <Form.Label htmlFor="" className="emailLabel">
                      Type of shop
                    </Form.Label>
                    <img src={clinic_icon} className="mailImg" alt="" />
                    <Form.Select
                      required
                      name="type_of_pet_shop"
                      value={ShopInfo.type_of_pet_shop}
                      onChange={changeEveValue}
                      className="form-select formInput"
                    >
                      <option value="">Select your shop type</option>
                      {shopTypes &&
                        shopTypes.map((data, index) => {
                          return (
                            <option
                              value={data?.type_of_pet_shop_id}
                              key={index}
                            >
                              {data?.type_of_pet_shop}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Select Type Of Shop
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="inputBox">
                  <Form.Group className="inputBox">
                    <Form.Label htmlFor="" className="emailLabel">
                      Shop Address
                    </Form.Label>
                    <img src={location_icon} className="mailImg" alt="" />
                    <AutocompleteGoogleInput
                      selectedValue={ShopInfo?.shop_address}
                      required={true}
                      className={"form-control formInput"}
                      placeholder={"Enter your address"}
                      onPlaceSelect={({ lat, lng, address }) => {
                        setShopInfo({
                          ...ShopInfo,
                          shop_address: address,
                          pet_shop_longitude: lng,
                          pet_shop_latitude: lat,
                        });
                      }}
                    />
                    {/* <Form.Control
                      required
                      name="shop_address"
                      value={ShopInfo.shop_address}
                      onChange={changeEveValue}
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter shop address"
                    /> */}
                    <Form.Control.Feedback type="invalid">
                      Please Add Shop Address
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="inputBox">
                  <Form.Group className="inputBox">
                    <Form.Label htmlFor="" className="emailLabel">
                      Shop License Number
                    </Form.Label>
                    <img src={docmunataion_icon} className="mailImg" alt="" />
                    <Form.Control
                      required
                      name="shop_license_number"
                      value={ShopInfo.shop_license_number}
                      onChange={changeEveValue}
                      type="number"
                      className="form-control formInput"
                      placeholder="Enter license number"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Add Shop License Number
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="login btn-width-100">
                  <DarkButton
                    classbtn={
                      "cx-btn-light me-3  btn-width-45 btn-height font-17 mb-5"
                    }
                    btn={"Back"}
                  />
                  <DarkButton
                    classbtn={
                      "cx-btn-dark  btn-height btn-width-50 font-17 mb-5"
                    }
                    btn={"Save & Proceed"}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="landingImg">
                <p className="contactNumber">+91 9509932234</p>
                <img src={login_img} alt="" />
                <p className="containstart">Sign In to Pet Shop App</p>
                <p className="letsstart">Lets get started</p>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ClinicInfo;
