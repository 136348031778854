import ApiConfig, { BASE_URL } from "../api/ApiConfig";
import { simpleGetCallWithToken } from "../api/ApiServices";
import { AppContext } from "./AppContext";
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import useSound from "use-sound";
import fanfareSfx from "../assets/sound/Notifica.mp3";
import { notificationMsg, notifySuccess } from "../sharedComponent/notify";

const AppState = (props) => {
  const [play, { stop }] = useSound(fanfareSfx);
  const [notificationCount, setNotificationCount] = useState(0);
  const [noticationList, setnoticationList] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [Dark, setDark] = useState("lightMode");
  const [Prescription, setPrescription] = useState(false);
  const [userData, setUserData] = useState({
    pet_shop_registration_id: localStorage.getItem("pet_shop_registration_id")
      ? localStorage.getItem("pet_shop_registration_id")
      : null,
    type_of_pet_shop_id: localStorage.getItem("type_of_pet_shop_id")
      ? localStorage.getItem("type_of_pet_shop_id")
      : null,
    user_id: localStorage.getItem("user_id")
      ? localStorage.getItem("user_id")
      : null,
    isLoggedIn: localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : null,
    pet_shop_image_path: localStorage.getItem("pet_shop_image_path")
      ? localStorage.getItem("pet_shop_image_path")
      : null,
    petshop_status_list: localStorage.getItem("petshop_status_list")
      ? JSON.parse(localStorage.getItem("petshop_status_list"))
      : null,
    subscription_id: localStorage.getItem("subscription_id")
      ? JSON.parse(localStorage.getItem("subscription_id"))
      : null,
    subscription_valid_upto: localStorage.getItem("subscription_valid_upto")
      ? JSON.parse(localStorage.getItem("subscription_valid_upto"))
      : null,
    is_login: localStorage.getItem("is_login")
      ? JSON.parse(localStorage.getItem("is_login"))
      : null,
  });
  // PayLoad Object For Subscription AUTHENTICATED API
  const [subscriptionAPIPayload, setSubscriptionAPIPayLoad] = useState({
    subscription_id: userData?.subscription_id,
    subscription_valid_upto: userData?.subscription_valid_upto,
    is_login: userData?.is_login ? true : false,
  });
  // PayLoad Object For Subscription AUTHENTICATED API
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({});

  let profileId = localStorage.getItem("pet_shop_registration_id");

  useEffect(() => {
    getProfileDet();
  }, [profileId]);

  const getProfileDet = () => {
    // setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_USER_PROFILE_DETAILS + `${profileId}`)
      .then((res) => {
        if (res.success === true) {
          setProfileData(res?.data);
          // setUserData(res?.data)

          setLoading(false);
        } else {
        }
      })
      .catch((err) => {});
  };

  const [updateImg, setUpdateImg] = useState({
    pet_shop_registration_id: userData.pet_shop_registration_id,
    profilePath: "",
  });
  let option = {
    timeout: 20000,
    query: {
      UID: userData.user_id,
    },
  };

  const [socket, setSocket] = useState();

  useEffect(() => {
    if (userData) {
      setSocket(
        io(`${BASE_URL}?UID=${userData.user_id}`, option)
      );
    }
  }, [userData]);

  const handleMouseEnter = () => {
    play();
  };

  useEffect(() => {
    console.log("notification call");

    // Check and request permissions for microphone and camera
    socket &&
      socket.on("notification", (data) => {
        console.log("data", data);
        handleMouseEnter();
        notificationMsg(data.text);
        // Handle the response from the "usercalling" event here
      });
    return () => {
      socket && socket.off("notification");
    };
  }, [socket]);

  var validNumber = new RegExp(/^\d*\.?\d*$/);

  var validEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return (
    <div>
      <AppContext.Provider
        value={{
          sidebar,
          setSidebar,
          Dark,
          setDark,
          Prescription,
          setPrescription,
          userData,
          setUserData,
          loading,
          setLoading,
          profileData,
          setProfileData,
          getProfileDet,
          updateImg,
          setUpdateImg,
          socket,
          setSocket,
          notificationCount,
          setNotificationCount,
          noticationList,
          setnoticationList,
          validNumber,
          validEmail,
          emailRegex,
          subscriptionAPIPayload, setSubscriptionAPIPayLoad
        }}
      >
        {props.children}
      </AppContext.Provider>
    </div>
  );
};

export default AppState;
