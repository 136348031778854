import React, { useContext, useEffect, useState } from "react";
import profile_img from "../../assets/images/main_profile_img.svg";
import status_available from "../../assets/images/headerIcons/status_available.svg";
import docs_icon_blue from "../../assets/images/icons/docs_icon_blue.svg";
import doc_delete_icon from "../../assets/images/icons/doc_delete_icon.svg";
import upload_icon from "../../assets/images/icons/upload_icon.svg";
import edit_icon from "../../assets/images/icons/edit_icon.svg";
import Axe from "../../assets/images/Axe.svg";
import barcode from "../../assets/images/barcode.svg";
// import barcode_download from "../../assets/images/barcode_download.svg";
import { motion } from "framer-motion";
import { DarkButton, DonutChart } from "../commonComponents/commonComp";
import { Link, useParams } from "react-router-dom";
import { Nav, NavItem, Tab } from "react-bootstrap";
import {
  deleteWithAuthCall,
  multipartPostCall,
  simpleGetCallWithToken,
  simplePostAuthCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import Form from "react-bootstrap/Form";
import { AppContext } from "../../context/AppContext";
import AutocompleteGoogleInput from "../../sharedComponent/AutocompleteGoogleInput";

const MyProfile = () => {
  const params = useParams();
  const {
    sidebar,
    setSidebar,
    profileData,
    setProfileData,
    updateImg,
    setUpdateImg,
    validNumber,
    emailRegex,
  } = useContext(AppContext);
  let profileId = localStorage.getItem("pet_shop_registration_id");
  let pet_shop_id = localStorage.getItem("type_of_pet_shop_id");
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [editClicked, setEditClicked] = useState(false);
  const [editBank, setEditBank] = useState(false);
  const [validateEmail, setValidateEmail] = useState(true);
  const [addBankClicked, setAddBankClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [profileData, setProfileData] = useState({});
  const [languageList, setLanguageList] = useState([]);
  console.log("languageList,", languageList);
  const [fileName, setFileName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  console.log("selectedImage", selectedImage);

  const [updateData, setUpdateData] = useState({
    type_of_pet_shop_id: profileId,
    owner_name: "",
    phone: null,
    email: "",
    shop_name: "",
    shop_type: "",
    shop_address: "",
    shop_license_number: null,
    certificates: "",
    longitude: "",
    latitude: "",
  });
  console.log("updateData", updateData);
  console.log("profileData", profileData);
  const [BankDetails, setBankDetails] = useState({
    pharmacy_bank_id: null,
    pharmacy_bank: "",
    account_number: null,
    account_holder_name: "",
    ifsc_code: null,
  });
  const [bankDet, setBankDet] = useState({
    pharmacy_bank_id: null,
    pharmacy_bank: "",
    account_number: null,
    account_holder_name: "",
    ifsc_code: null,
  });

  const [bankData, setbankData] = useState({
    pet_shop_registration_id: profileId,
    bank_name: "",
    account_number: "",
    account_holder: "",
    ifsc_code: "",
  });

  const [selectLanguage, setSelectLanguage] = useState({
    pet_shop_id: pet_shop_id,
    language_id: null,
  });

  const bankDataArr = profileData?.pet_shop_banks || [];
  const [editBankIndex, setEditBankIndex] = useState(null);
  const changeBankDet = (e) => {
    const { name, value } = e.target;

    setBankDet((prevDetail) => ({
      ...prevDetail,
      [name]: value,
    }));
  };

  const handleEdit = (index) => {
    const selectedBankDetail = bankDataArr[index];
    setBankDet(selectedBankDetail);
    setEditBankIndex(index);
  };

  const updateDetails = () => {
    let payload = {
      pet_shop_bank_id: bankDet?.pet_shop_bank_id,
      pet_shop_bank: bankDet?.pet_shop_bank,
      account_number: bankDet?.account_number,
      account_holder_name: bankDet?.account_holder_name,
      ifsc_code: bankDet?.ifsc_code,
    };
    simplePostAuthCall(ApiConfig.UPDATE_BANK_DETAILS, JSON.stringify(payload))
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          getProfileDet();
          setEditBankIndex(null);
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => notifyError(err));
  };


  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const handleChange = (e) => {
    if (e.target.name === "certificates") {
      setUpdateData({
        ...updateData,
        [e.target.name]: e.target.files[0],
      });
      setFileName(e.target.files[0]);
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setSelectedImage(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    } else setUpdateData({ ...updateData, [e.target.name]: e.target.value });
  };

  const changeEveVal = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };
  const changeBankVal = (e) => {
    setbankData({
      ...bankData,
      [e.target.name]: e.target.value,
    });
  };

  const getProfileDet = () => {
    // setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_USER_PROFILE_DETAILS + `${profileId}`)
      .then((res) => {
        if (res.success === true) {
          setProfileData(res?.data);
          console.log("res?.data,", res?.data);
          setUpdateData({
            ...updateData,
            owner_name: res?.data?.first_name + " " + res?.data?.last_name,
            phone: res?.data?.phone_number,
            email: res?.data?.email,
            shop_name: res?.data?.pet_shop_name,
            shop_type: res?.data?.type_of_pet_shop_id,
            shop_address: res?.data?.pet_shop_address,
            shop_license_number: res?.data?.pet_shop_license_number,
            longitude: res?.data?.pet_shop_location_coordinates?.x,
            latitude: res?.data?.pet_shop_location_coordinates?.y,
          });
          setBankDet({
            ...bankDet,
            pharmacy_bank: res?.data?.pet_shop_banks?.account_holder_name,
            account_number: res?.data?.pet_shop_banks?.account_number,
            account_holder_name: res?.data?.pet_shop_banks?.account_holder_name,
            ifsc_code: res?.data?.pet_shop_banks?.ifsc_code,
          });
          setSelectLanguage({
            ...selectLanguage,
            language_id: res?.data?.langauge_id,
          });
          setLoading(false);
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        notifyError(err.message);
      });
  };
  const [shopTypes, setShopTypes] = useState([]);
  const getLanguageList = () => {
    simpleGetCallWithToken(ApiConfig.GET_LIST_OF_LANGUAGAES)
      .then((res) => {
        if (res.success === true) {
          setLanguageList(res?.languages);
          setShopTypes(res?.shop_type);
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log("error =>", err);
      });
  };

  const updateApiData = (e) => {
    e.preventDefault();
    // setLoading(true);
    if (!validateEmail) {
      return notifyError(`Please provide a valid email`);
    }
    let dataForUpdate = new FormData();

    dataForUpdate.append(
      "pet_shop_registration_id",
      updateData.type_of_pet_shop_id
    );
    dataForUpdate.append("owner_name", updateData.owner_name);
    dataForUpdate.append("phone", updateData.phone);
    dataForUpdate.append("email", updateData.email);
    dataForUpdate.append("shop_name", updateData.shop_name);
    dataForUpdate.append("shop_address", updateData.shop_address);
    dataForUpdate.append("shop_type", updateData.shop_type);
    dataForUpdate.append("shop_license_number", updateData.shop_license_number);
    dataForUpdate.append("certificates", updateData.certificates);
    dataForUpdate.append("longitude", updateData.longitude);
    dataForUpdate.append("latitude", updateData.latitude);

    multipartPostCall(ApiConfig.UPDATE_PROFILE_IN_FORM_DATA, dataForUpdate)
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          getProfileDet();
          setLoading(false);
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteDocs = (id) => {
    deleteWithAuthCall(ApiConfig.DELETE_DOCS_WITH_ID + `${id}`)
      .then((res) => {
        notifySuccess(res.message);
        getProfileDet();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addLanguageBtn = () => {
    if (selectLanguage.language_id === null) {
      notifyError("Please Select atleast one language");
      return;
    } else {
      simplePostAuthCall(
        ApiConfig.ADD_EDIT_LANGUAGE,
        JSON.stringify(selectLanguage)
      )
        .then((res) => {
          if (res.success === true) {
            notifySuccess(res.message);
            getProfileDet();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const addBankDetails = (e) => {
    e.preventDefault();
    simplePostAuthCall(ApiConfig.ADD_BANK_DETAILS, JSON.stringify(bankData))
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          getProfileDet();
          setAddBankClicked(false);
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => console.log(err));
    console.log("Bank Data =>", bankData);
  };

  // useEffect(() => {
  //   if (updateImg) {
  //     changeProfile();
  //   }
  // }, [updateImg]);
console.log("updateImg,", updateImg)
  const changeProfile = (profilePath) => {
    let formData = new FormData();
    formData.append("pet_shop_registration_id", updateImg.pet_shop_registration_id);
    formData.append("profilePath", profilePath);

    multipartPostCall(ApiConfig.CHANGE_PROFILE_PHOTO, formData)
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          getProfileDet();
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getProfileDet();
    getLanguageList();
  }, []);

  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.3 }}
      className="myprofile_wrapper"
      id="cx-main"
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="myprofile">
            <div className="header_wrapper">
              <div className="header_top"></div>
              <div className="header_bottom">
                <img src={profileData?.pet_shop_image_path} alt="" />
                <div className="iconHolder">
                  <label htmlFor="fileTag">
                    <input
                      id="fileTag"
                      type="file"
                      onChange={(e) => {
                        // setUpdateImg({
                        //   ...updateImg,
                        //   profilePath: e.target.files[0],
                        // });
                        changeProfile(e.target.files[0])
                      }}
                      className="d-none"
                    />
                    <img
                      src={edit_icon}
                      style={{ cursor: "pointer" }}
                      className="editIcon"
                      id="fileTag"
                    />
                  </label>
                </div>
                <div className="profile_name">
                  <p className="name">
                    {profileData?.first_name} {profileData?.last_name}{" "}
                    <img src={status_available} alt="" />
                  </p>
                  {/* <p className="post">Orthopedic</p> */}
                </div>
              </div>

              <div className="profile_info_wrapper">
                <div className="daw_tabs">
                  <Tab.Container defaultActiveKey="0">
                    <div className="row">
                      <div className="col-lg-12">
                        <Nav variant="pills" className="">
                          <Nav.Item>
                            <Nav.Link eventKey="0">Profile</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="1">Bank Details</Nav.Link>
                          </Nav.Item>
                          {/* <Nav.Item>
                              <Nav.Link eventKey="2">Password Settings</Nav.Link>
                            </Nav.Item> */}
                          <Nav.Item>
                            <Nav.Link eventKey="3">Language Settings</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                      <div className="col-lg-12">
                        <Tab.Content>
                          <Tab.Pane eventKey="0">
                            <div className="profile_info_wrapper_inner">
                              <div className="info_title_wrapper">
                                <h3 className="info_title">
                                  Basic Information
                                </h3>
                                {editClicked ? (
                                  <img
                                    src={Axe}
                                    onClick={() => setEditClicked(false)}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={edit_icon}
                                    onClick={() => setEditClicked(true)}
                                    alt=""
                                  />
                                )}
                              </div>
                              <div className="profile_info_fields">
                                <div className="row mb-4">
                                  <div className="col-lg-3">
                                    <label htmlFor="">Owner Name</label>
                                    {editClicked ? (
                                      <input
                                        type="text"
                                        name="owner_name"
                                        onChange={changeEveVal}
                                        value={updateData.owner_name}
                                        className="form-control editedControl"
                                      />
                                    ) : (
                                      <p className="info_text">
                                        {profileData?.first_name}{" "}
                                        {profileData?.last_name}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-lg-3">
                                    <label htmlFor="">Contact Number</label>
                                    {editClicked ? (
                                      <input
                                        type="text"
                                        name="phone"
                                        onChange={(e) => {
                                          if (
                                            validNumber.test(e.target.value) &&
                                            e.target.value.toString().length <
                                              11
                                          ) {
                                            changeEveVal(e);
                                          }
                                        }}
                                        value={updateData.phone}
                                        className="form-control editedControl"
                                      />
                                    ) : (
                                      <p className="info_text">
                                        {profileData?.phone_number}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-lg-3">
                                    <label htmlFor="">Email</label>
                                    {editClicked ? (
                                      <input
                                        type="text"
                                        name="email"
                                        onChange={(e) => {
                                          setValidateEmail(
                                            emailRegex.test(e.target.value)
                                          );
                                          changeEveVal(e);
                                        }}
                                        value={updateData.email}
                                        className="form-control editedControl"
                                      />
                                    ) : (
                                      <p className="info_text">
                                        {profileData?.email}
                                      </p>
                                    )}
                                  </div>
                                  {/* <div className="col-lg-3">
                                      <label htmlFor="">Barcode</label>
                                      <div className="barcode_wrapper">
                                        <div className="barcode_item">
                                          <img src={barcode} alt="" />
                                        </div>
                                        <div className="barcode_item barcode_item_hovered">
                                          <div className="overlay"></div>
                                          <img src={barcode} alt="" />
                                          <img src={barcode_download} alt="" className="barcode_download" />
                                        </div>
                                      </div>
                                    </div> */}
                                </div>
                              </div>
                              <div className="info_title_wrapper">
                                <h3 className="info_title">Shop Information</h3>
                                {/* <img src={edit_icon} alt="" /> */}
                              </div>
                              <div className="profile_info_fields mb-3">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <label htmlFor="">Shop Name</label>
                                    {editClicked ? (
                                      <input
                                        type="text"
                                        name="shop_name"
                                        onChange={changeEveVal}
                                        value={updateData.shop_name}
                                        className="form-control editedControl"
                                      />
                                    ) : (
                                      <p className="info_text">
                                        {profileData?.pet_shop_name}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-lg-3">
                                    <label htmlFor="">Shop Type</label>
                                    {editClicked ? (
                                      <select
                                        name="shop_type"
                                        onChange={changeEveVal}
                                        value={updateData.shop_type}
                                        className="form-control editedControl"
                                      >
                                        <option value={""}>
                                          Select shop type
                                        </option>
                                        {shopTypes &&
                                          shopTypes.map((item, idx) => {
                                            return (
                                              <option
                                                key={"shopTypes" + idx}
                                                value={item.type_of_pet_shop_id}
                                              >
                                                {item?.type_of_pet_shop}
                                              </option>
                                            );
                                          })}
                                      </select>
                                    ) : (
                                      // <input
                                      //   type="text"
                                      //   name="shop_type"
                                      //   onChange={changeEveVal}
                                      //   value={updateData.shop_type}
                                      //   className="form-control editedControl"
                                      // />
                                      <p className="info_text">
                                        {shopTypes &&
                                          shopTypes.find(
                                            (item) =>
                                              item.type_of_pet_shop_id ===
                                              profileData?.type_of_pet_shop_id
                                          )?.type_of_pet_shop}
                                        {/* {profileData?.type_of_pet_shop_id} */}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-lg-3">
                                    <label htmlFor="">Address</label>
                                    {editClicked ? (
                                      <AutocompleteGoogleInput
                                        selectedValue={
                                          updateData.shop_address
                                            ? updateData.shop_address
                                            : ""
                                        }
                                        required={true}
                                        className={"form-control formInput"}
                                        placeholder={"Enter your address"}
                                        onPlaceSelect={({
                                          lat,
                                          lng,
                                          address,
                                        }) => {
                                          setUpdateData({
                                            ...updateData,
                                            shop_address: address,
                                            latitude: lat,
                                            longitude: lng,
                                          });
                                        }}
                                      />
                                    ) : (
                                      <p className="info_text">
                                        {profileData?.pet_shop_address}
                                      </p>
                                    )}
                                    {/* <input
                                        type="text"
                                        name="shop_address"
                                        onChange={changeEveVal}
                                        value={updateData.shop_address}
                                        className="form-control editedControl"
                                      /> 
                                     */}
                                  </div>
                                  <div className="col-lg-3">
                                    <label htmlFor="">License Number</label>
                                    {editClicked ? (
                                      <input
                                        type="text"
                                        name="shop_license_number"
                                        onChange={changeEveVal}
                                        value={updateData.shop_license_number}
                                        className="form-control editedControl"
                                      />
                                    ) : (
                                      <p className="info_text">
                                        {profileData?.pet_shop_license_number}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="info_title_wrapper">
                                <h3 className="info_title">Documents</h3>
                                {/* <img src={edit_icon}  alt="" /> */}
                              </div>
                              <div className="profile_info_fields">
                                {editClicked ? (
                                  <>
                                    <div className="profile_docs_wrapper">
                                      <div className="doc_item upload_doc_item">
                                        <form
                                          className="form-container"
                                          enctype="multipart/form-data"
                                        >
                                          <div className="upload-files-container">
                                            <div className="drag-file-area">
                                              <span className="material-icons-outlined upload-icon">
                                                <img src={upload_icon} />
                                              </span>
                                              <h3 className="dynamic-message">
                                                Drop your files here. <br />
                                                or
                                              </h3>
                                              <label className="label">
                                                <span className="browse-files">
                                                  <input
                                                    type="file"
                                                    accept="image/png, image/gif, image/jpeg, image/pdf, image/jpg"
                                                    name="certificates"
                                                    onChange={handleChange}
                                                    className="default-file-input"
                                                  />
                                                  <span className="browse-files-text">
                                                    {" "}
                                                    Browse
                                                  </span>
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                      {updateData.certificates !== "" ? (
                                        <div className="d-flex flex-column justify-content-center align-items-top">
                                          <div className="imgPreview">
                                            <img
                                              src={selectedImage}
                                              className="preImg"
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </>
                                ) : null}
                                <div className="profile_docs_wrapper">
                                  {profileData.pet_shop_certificates &&
                                  profileData.pet_shop_certificates.length >
                                    0 ? (
                                    <>
                                      {profileData.pet_shop_certificates.map(
                                        (data, index) => {
                                          return (
                                            <div
                                              className="doc_item"
                                              key={index}
                                            >
                                              <a
                                                href={`${data?.pet_shop_required_certificate_path}`}
                                                target="_blank"
                                              >
                                                <div className="doc_img_wrapper">
                                                  <img
                                                    src={docs_icon_blue}
                                                    alt=""
                                                  />
                                                </div>
                                              </a>
                                              <p className="info_text">
                                                {data?.certificate_name}
                                              </p>
                                              <div
                                                onClick={() =>
                                                  deleteDocs(
                                                    data?.pet_shop_certificate_id
                                                  )
                                                }
                                              >
                                                <img
                                                  src={doc_delete_icon}
                                                  className="doc_delete"
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : null}
                                  {/* <div className="doc_item">
                                      <div className="doc_img_wrapper">
                                        <img src={docs_icon_blue} alt="" />
                                      </div>
                                      <p className="info_text">Registration Certificate</p>
                                      <img src={doc_delete_icon} className="doc_delete" alt="" />
                                    </div>
                                    <div className="doc_item">
                                      <div className="doc_img_wrapper">
                                        <img src={docs_icon_blue} alt="" />
                                      </div>
                                      <p className="info_text">Registration Certificate</p>
                                      <img src={doc_delete_icon} className="doc_delete" alt="" />
                                    </div> */}
                                </div>
                              </div>
                              {editClicked ? (
                                <>
                                  <div className="btn_wrapper">
                                    <DarkButton
                                      classbtn={
                                        "cx-btn-light btn-width-50 font-14"
                                      }
                                      btn={"Cancel"}
                                      onClick={() => setEditClicked(false)}
                                    />
                                    <DarkButton
                                      classbtn={
                                        "cx-btn-dark  btn-width-50 font-14"
                                      }
                                      onClick={updateApiData}
                                      btn={"Update"}
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="1">
                            <div className="profile_info_wrapper_inner">
                              <div className="info_title_wrapper">
                                <h3 className="info_title">Bank Details</h3>
                              </div>
                              <div className="profile_info_fields">
                                {bankDataArr && bankDataArr.length > 0 ? (
                                  <>
                                    {bankDataArr.map((data, index) => {
                                      const isEditing = index === editBankIndex;
                                      return (
                                        <>
                                          <div className="d-flex justify-content-between align-items-center">
                                            <h1
                                              style={{
                                                fontSize: "17px",
                                              }}
                                              className="accountNum"
                                            >
                                              Account {index + 1}
                                            </h1>
                                            {isEditing ? (
                                              <img
                                                src={Axe}
                                                height="20"
                                                width="20"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  setEditBankIndex(null)
                                                }
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                src={edit_icon}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setEditBankIndex(index);
                                                  handleEdit(index);
                                                }}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <div className="row belowBorder">
                                            <div className="col-lg-3">
                                              <label htmlFor="">
                                                Bank Name
                                              </label>
                                              {isEditing ? (
                                                <input
                                                  type="text"
                                                  name="pet_shop_bank"
                                                  onChange={changeBankDet}
                                                  value={bankDet.pet_shop_bank}
                                                  className="form-control editedControl"
                                                />
                                              ) : (
                                                <p className="info_text">
                                                  {data?.pet_shop_bank}
                                                </p>
                                              )}
                                            </div>
                                            <div className="col-lg-3">
                                              <label htmlFor="">
                                                Account Number
                                              </label>
                                              {isEditing ? (
                                                <input
                                                  type="text"
                                                  name="account_number"
                                                  onChange={(e) => {
                                                    if (
                                                      validNumber.test(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      changeBankDet(e);
                                                    }
                                                  }}
                                                  value={bankDet.account_number}
                                                  className="form-control editedControl"
                                                />
                                              ) : (
                                                <p className="info_text">
                                                  {data?.account_number}
                                                </p>
                                              )}
                                            </div>
                                            <div className="col-lg-3">
                                              <label htmlFor="">
                                                Account Holder Name
                                              </label>
                                              {isEditing ? (
                                                <input
                                                  type="text"
                                                  name="account_holder_name"
                                                  onChange={changeBankDet}
                                                  value={
                                                    bankDet.account_holder_name
                                                  }
                                                  className="form-control editedControl"
                                                />
                                              ) : (
                                                <p className="info_text">
                                                  {data?.account_holder_name}
                                                </p>
                                              )}
                                            </div>
                                            <div className="col-lg-3">
                                              <label htmlFor="">
                                                IFSC / SWIFT Code
                                              </label>
                                              {isEditing ? (
                                                <input
                                                  type="text"
                                                  name="ifsc_code"
                                                  onChange={changeBankDet}
                                                  value={bankDet.ifsc_code}
                                                  className="form-control editedControl"
                                                />
                                              ) : (
                                                <p className="info_text">
                                                  {data?.ifsc_code}
                                                </p>
                                              )}
                                            </div>
                                            <div className="col-md-12 text-end my-2">
                                              {isEditing ? (
                                                <DarkButton
                                                  classbtn={
                                                    "cx-btn-dark shadow_none font-14 mb-3"
                                                  }
                                                  btn={"Update"}
                                                  onClick={updateDetails}
                                                />
                                              ) : null}
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </>
                                ) : null}
                              </div>
                              <div className="text-end">
                                {addBankClicked ? (
                                  <img
                                    src={Axe}
                                    height="25"
                                    width="25"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setAddBankClicked(false)}
                                    alt=""
                                  />
                                ) : (
                                  <DarkButton
                                    classbtn={
                                      "cx-btn-dark shadow_none font-14 mb-3"
                                    }
                                    btn={"+ Add Bank"}
                                    onClick={() => setAddBankClicked(true)}
                                  />
                                )}
                              </div>
                              {addBankClicked ? (
                                <div className="profile_info_fields">
                                  <form onSubmit={addBankDetails}>
                                    <div className="row borderedRow">
                                      <div className="col-lg-3">
                                        <label htmlFor="">Bank Name</label>
                                        <input
                                          type="text"
                                          required
                                          name="bank_name"
                                          value={bankData.bank_name}
                                          onChange={(e) => changeBankVal(e)}
                                          placeholder="Enter Bank Name"
                                          className="form-control"
                                        />
                                      </div>
                                      <div className="col-lg-3">
                                        <label htmlFor="">Account Number</label>
                                        <input
                                          type="text"
                                          required
                                          name="account_number"
                                          value={bankData.account_number}
                                          onChange={(e) => {
                                            if (
                                              validNumber.test(e.target.value)
                                            ) {
                                              changeBankVal(e);
                                            }
                                          }}
                                          placeholder="Enter Account Number"
                                          className="form-control"
                                        />
                                      </div>
                                      <div className="col-lg-3">
                                        <label htmlFor="">
                                          Account Holder Name
                                        </label>
                                        <input
                                          type="text"
                                          required
                                          name="account_holder"
                                          value={bankData.account_holder}
                                          onChange={(e) => changeBankVal(e)}
                                          placeholder="Enter Account Holder Name"
                                          className="form-control"
                                        />
                                      </div>
                                      <div className="col-lg-3">
                                        <label htmlFor="">
                                          IFSC / SWIFT Code
                                        </label>
                                        <input
                                          type="text"
                                          required
                                          name="ifsc_code"
                                          value={bankData.ifsc_code}
                                          onChange={(e) => changeBankVal(e)}
                                          placeholder="Enter IFSC/ SWIFT Code"
                                          className="form-control"
                                        />
                                      </div>
                                      <div className="col-md-12 text-end my-3">
                                        <DarkButton
                                          classbtn={
                                            "cx-btn-dark shadow_none font-14"
                                          }
                                          type="submit"
                                          btn={"+ Add Bank"}
                                          // onClick={addBankDetails}
                                        />
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              ) : null}
                            </div>
                          </Tab.Pane>
                          {/* <Tab.Pane eventKey="2">
                              <div className="profile_info_wrapper_inner">
                                <div className="info_title_wrapper">
                                  <h3 className="info_title">Update or Reset Password</h3>
                                  <img src={edit_icon} alt="" />
                                </div>
                                <div className="profile_info_fields">
                                  <div className="row">
                                    <div className="col-lg-6 mb-2">
                                      <label htmlFor="" className="pass_text">Email ID</label>

                                      <input type="text" placeholder="Enter your registered email ID" className="form-control" />
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                      <div className="d-flex justify-content-between">
                                        <label htmlFor="" className="pass_text">Verification Code</label>
                                        <button className="resend_btn">Resend</button>
                                      </div>
                                      <input type="text" placeholder="Enter verification code sent to your email ID..." className="form-control" />
                                    </div><div className="col-lg-6">
                                      <label htmlFor="" className="pass_text">New Password</label>

                                      <input type="text" placeholder="Enter New Password" className="form-control" />
                                    </div>
                                    <div className="col-lg-6">
                                      <label htmlFor="" className="pass_text">Confirm Password</label>
                                      <input type="text" placeholder="Enter Account Number" className="form-control" />
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="password_instructions">
                                        <p className="pass_title">Password Strength-</p>
                                        <ul>
                                          <li>Must have at least 8 character's</li>
                                          <li>Upper & Lower case letters</li>
                                          <li>A Symbol (# & % ₹)</li>
                                          <li>It should not be your name or phone number.</li>
                                        </ul>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane> */}
                          <Tab.Pane eventKey="3">
                            <div className="profile_info_wrapper_inner">
                              <div className="info_title_wrapper">
                                <h3 className="info_title">Select Language</h3>
                              </div>
                              <div className="profile_info_fields">
                                <div className="overflowController">
                                  <div className="language_wrapper">
                                    {languageList && languageList.length > 0 ? (
                                      <>
                                        {languageList.map((data, index) => {
                                          return (
                                            <div key={index}>
                                              <input
                                                type="radio"
                                                name="language_id"
                                                onChange={() =>
                                                  setSelectLanguage({
                                                    ...selectLanguage,
                                                    language_id:
                                                      data.language_id,
                                                  })
                                                }
                                                checked={
                                                  data.language_id ===
                                                  selectLanguage.language_id
                                                    ? true
                                                    : false
                                                }
                                                value={
                                                  selectLanguage.language_id
                                                }
                                                id={data.language_id}
                                              />
                                              <label htmlFor={data.language_id}>
                                                {data?.language}
                                              </label>
                                            </div>
                                          );
                                        })}
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-end">
                              <DarkButton
                                classbtn={
                                  "cx-btn-dark shadow_none font-14 mb-3"
                                }
                                btn={"+ Add Language"}
                                onClick={addLanguageBtn}
                              />
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default MyProfile;
