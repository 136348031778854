import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import ic_hospital from "../../assets/images/ic_hospital.svg";
import dog from "../../assets/images/dog.svg";
import ic_clock from "../../assets/images/ic_clock.svg";
import ic_right_arrow from "../../assets/images/ic_right_arrow.svg";

import Modal from "react-bootstrap/Modal";

import CreatableSelect from "react-select/creatable";
import ic_filter from "../../assets/images/ic_filter.svg";

import ic_back_arrow from "../../assets/images/ic_back_arrow.svg";
import PaginationComp from "../../sharedComponent/PaginationComp";
import { AppContext } from "../../context/AppContext";
import PrescriptionComp from "../../sharedComponent/PrescriptionComp";

import Star from "../../assets/images/pet-shop-img/Star.svg";
import no_review_icon from "../../assets/images/icons/no_review_icon.svg";
import pending from "../../assets/images/ic_pending.svg";
// import prescription_icon from "../../assets/images/icons/prescription_icon.svg";
import arrowleft from "../../assets/images/pet-shop-img/arrowleft.svg";
import green_check_icon from "../../assets/images/icons/green_check_icon.svg";
// import red_check_icon from "../../assets/images/icons/red_check_icon.svg";

import DocSYmbol from "../../assets/images/icons/DoctorSymbol.svg";
import Signature from "../../assets/images/icons/Signature.svg";
import EmailIcon from "../../assets/images/icons/mail.svg";
import CallIcon from "../../assets/images/icons/call.svg";
import {
  simpleGetCallWithToken,
  simplePostAuthCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import moment from "moment";
import { notifyError } from "../../sharedComponent/notify";
import Pagination from "../../sharedComponent/Pagination";

export const getDate = (inputDate) => {
  let date = new Date(inputDate);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  //dd-mm-yyyy
  //YYYY-MM-DD
  //MM-DD-YYYY
  //dd/mm/yyyy

  var formattedDate = `${day < 10 ? 0 : ""}${day}-${
    month < 10 ? 0 : ""
  }${month}-${year}`;

  return formattedDate;
};
const CustomerDetails = () => {
  const { loading, setLoading, userData, subscriptionAPIPayload } =
    useContext(AppContext);

  const [viewCalender, setviewCalender] = useState(true);
  const [viewList, setviewList] = useState(false);
  const [reqList, setreqList] = useState(true);
  const [completedList, setCompletedList] = useState(true);
  const [clickedOrder, setClickedOrder] = useState(false);
  const [viewAppointment, setviewAppointment] = useState(false);
  const [startdigno, setStartdigno] = useState(false);
  const [option, setoption] = useState([
    { value: "vanilla", label: "Vanilla", rating: "safe" },
    { value: "chocolate", label: "Chocolate", rating: "good" },
    { value: "strawberry", label: "Strawberry", rating: "wild" },
    { value: "salted-caramel", label: "Salted Caramel", rating: "crazy" },
  ]);
  const SelectStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "10px",
      border: "1px solid #A3AED0",
    }),
  };

  const [page, setPage] = useState(1);
  const [last_page, setlast_page] = useState(false);
  const [TotalData, setTotalData] = useState(0);
  const [ProductNameFilter, setProductNameFilter] = useState("");
  const [BrandFilter, setProductBrandFilter] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [CustomerList, setCustomerList] = useState([]);
  console.log("CustomerList,", CustomerList)
  const [CustomerDetails, setCustomerDetails] = useState([]);
  const [payment_details, setpayment_details] = useState([]);
  const [Customerprescription, setCustomerprescription] = useState({});
  const [CustomerprescriptionTable, setCustomerprescriptionTable] = useState(
    []
  );
  const [Orderproduct_summary, setCustomerproduct_summary] = useState([]);
  const [OrderproductViste, setOrderproductViste] = useState([]);
  const [OrderproductVisteToday, setOrderproductVisteToday] = useState([]);
  const [StatusId, setStatusId] = useState("");
  const [TotalViste, SetTotalViste] = useState("");
  const [CustomerproductReview, setCustomerproductReview] = useState([]);
  const [CustomerproductStatus, setCustomerproductStatus] = useState([]);
  const [CustomerproductImage, setCustomerproductImage] = useState([]);

  useEffect(() => {
    // if (ProductNameFilter === "") {
    //   setLoading(true);
    //   getDetails(page);
    // } else {
    //   getDetails(page);
    // }
    getDetails(1);
  }, [ProductNameFilter, BrandFilter]);

  const getDetails = (currentPage) => {
    simplePostAuthCall(
      ApiConfig.CUSTOMER_LIST +
        userData.type_of_pet_shop_id +
        "&page=" +
        currentPage +
        "&filter=" +
        ProductNameFilter,
      JSON.stringify(subscriptionAPIPayload)
    )
      .then((data) => {
        if (data.success) {
          setLoading(false);
          let total = data.total ? data.total : 0;
          setTotalData(total);
          setlast_page(data.last_page);

          let coursesData = data.data ? data.data : [];
          // let LearningData = coursesData.map((eachteam, index) => {
          //   return {
          //     ...eachteam,
          //     animal_name: eachteam.animal_name || "",
          //     phone_number: eachteam.phone_number || "",
          //   };
          // });
          const updatedArr =
            data.data &&
            data.data.map((item, idx) => {
              return {
                ...item,
                srno : 10 * (currentPage - 1)
              };
            });
            updatedArr && setCustomerList(updatedArr);
          // if (currentPage === 1) {
          //   setLoading(false);
          // } else {
          //   setCustomerList([...CustomerList, ...LearningData]);
          //   setLoading(false);
          // }
        } else {
          setCustomerList([]);
          setlast_page(false);
          notifyError(data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPageChange = ({ selectedPage }) => {
    selectedPage && getDetails(selectedPage + 1);
  };

  const getViste = (id) => {
    let newRequestBody = JSON.stringify({
      customer_id: id,
      pet_shop_id: Number(userData.type_of_pet_shop_id),
    });
    simplePostAuthCall(ApiConfig.ORDER_BY_VISTE, newRequestBody)
      .then((data) => {
        if (data.success) {
          setOrderproductViste(data.history);
          setOrderproductVisteToday(data.todaysOrders);
          SetTotalViste(data.total);
        } else {
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDetailsByeId = (id) => {
    setLoading(true);

    setStatusId(id);
    simpleGetCallWithToken(
      ApiConfig.CUSTOMER_DETALIS_BY_ID + id + "/" + userData.type_of_pet_shop_id
    )
      .then((data) => {
        if (data.success) {
          setCustomerDetails(data.data.order_details);
          setpayment_details(data.data.payment_details);
          //   setCustomerprescription(data.prescription);
          //   setCustomerprescriptionTable(data.prescription.prescriptions);
          setCustomerproduct_summary(data.product_summary);
          setCustomerproductReview(data.data.review);
          setCustomerproductStatus(data.status);
          setCustomerproductImage(data?.animal_images);
          getViste(data.data.customer_id);
        } else {
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <div
          className="appointment-wrapper CustomerDetails-wrapper"
          id="cx-main"
        >
          <div className="reportsTabs">
            <div className="search-filter-wrapper">
              <div className="search-filter-left">
                {viewAppointment === true ? (
                  <></>
                ) : (
                  <div className="row">
                    <div className="col-lg-5 mb-2">
                      <input
                        type="text"
                        className="form-control serach-input"
                        placeholder="Search by name..."
                        value={ProductNameFilter}
                        onChange={(e) => {
                          setProductNameFilter(e.target.value);
                        }}
                      />
                    </div>
                    {/* <div className="col-lg-1">
                <button className="fltr-btn">
                  <img src={ic_filter} alt="" />
                </button>
              </div> */}
                  </div>
                )}
              </div>
            </div>

            <div>
              {completedList && (
                <>
                  <div className="table-wrapper left-table ">
                    <table>
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Customer Name</th>
                          <th>Customer Photo</th>
                          <th>Order date</th>
                          {/* <th>Pet Age</th> */}
                          <th>Contact No.</th>
                          <th>Email</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {CustomerList && CustomerList.length > 0 ? (
                          CustomerList.map((itemlist, index) => {
                            return (
                              <tr key={"CustomerList" + index}>
                                <td>{itemlist.srno + index + 1}</td>
                                <td>{itemlist.customer_name}</td>
                                <td>
                                  <label htmlFor="" className="photo me-2">
                                    <img
                                      src={itemlist.profile_image_path}
                                      alt=""
                                    />
                                  </label>
                                  {/* {itemlist.animal_name} */}
                                </td>

                                <td>{itemlist.pet_shop_order_date}</td>
                                {/* <td>{itemlist.animal_age}</td> */}
                                <td>{itemlist.phone_number}</td>
                                <td>{itemlist.email}</td>
                                <td>
                                  <button
                                    className="view-details-btn"
                                    onClick={() => {
                                      setviewAppointment(true);
                                      setviewList(false);
                                      setCompletedList(false);
                                      getDetailsByeId(
                                        itemlist.pet_shop_order_id
                                      );
                                    }}
                                  >
                                    View Details
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                    {CustomerList.length ? (
                      ""
                    ) : (
                      <p
                        style={{
                          fontSize: "20px",
                          marginTop: "200px",
                        }}
                        className=" text-center justify-content-center align-items-center"
                      >
                        Oops! It doesn't look like there are any Customer ?
                      </p>
                    )}
                    {/* <PaginationComp /> */}
                  </div>
                  {CustomerList && CustomerList.length > 0 && (
                    <Pagination
                      // pageCount={8}
                      pageCount={totalPages}
                      onPageChange={onPageChange}
                    />
                  )}
                </>
              )}
              {viewAppointment && (
                <div className="OrderInner">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="app-result-arrow-top">
                        <img
                          src={ic_back_arrow}
                          alt=""
                          onClick={() => {
                            setviewList(true);
                            setviewAppointment(false);
                            setreqList(true);
                            setCompletedList(true);
                          }}
                        />
                        <label htmlFor="">
                          Showing result for : <span>{StatusId}</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-8 mt-4">
                      <div className="orderInnerTabSection">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="1"
                        >
                          <Nav
                            variant="pills"
                            id="newTabMai"
                            className="tob_nav_pills d-flex"
                          >
                            <Nav.Item>
                              <Nav.Link eventKey="1">Order Details</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="2">Status</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="3">Payment Details</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="4">Review & Ratings</Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="1">
                              <div className="innerContain">
                                {CustomerDetails &&
                                CustomerDetails.length > 0 ? (
                                  CustomerDetails.map((itemlist, index) => {
                                    return (
                                      <div className="row">
                                        <div className="col-lg-6 borderLeft">
                                          <table>
                                            <tr>
                                              <td className="labelName">
                                                Order ID
                                              </td>
                                              <td className="ContainName">
                                                #{itemlist.pet_shop_order_id}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                Order Date
                                              </td>
                                              <td className="ContainName">
                                                {getDate(
                                                  itemlist.pet_shop_order_date
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                Delivery Date
                                              </td>
                                              <td className="ContainName">
                                                {getDate(
                                                  itemlist.delivery_date
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                No. of Items
                                              </td>
                                              <td className="ContainName">
                                                {itemlist.no_of_items}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName pb-0">
                                                Total Amount
                                              </td>
                                              <td className="ContainName pb-0">
                                                ₹{itemlist.total_amount}
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                        <div className="col-lg-6 ps-5">
                                          <table>
                                            <tr>
                                              <td className="labelName">
                                                Customer Name
                                              </td>
                                              <td className="ContainName">
                                                {itemlist.customer_first_name}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                Contact Number
                                              </td>
                                              <td className="ContainName">
                                                {itemlist.contact_number}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                Email ID
                                              </td>
                                              <td className="ContainName">
                                                {itemlist.email_id}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName">
                                                {/* Pet Type */}
                                              </td>
                                              <td className="ContainName">
                                                {/* {itemlist.pet_type} */}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="labelName pb-0">
                                                {/* Pet Age */}
                                              </td>
                                              <td className="ContainName pb-0">
                                                {/* {itemlist.pet_age} */}
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="2">
                              <div className="innerContain">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <table>
                                      {/* <tr>
                                    <td className="labelName p-0">Order ID</td>
                                    <td className="ContainName p-0">#{itemlist.}</td>
                                  </tr> */}
                                    </table>
                                  </div>
                                  {/* <div className="col-lg-6 d-flex justify-content-end">
                                <select
                                  class="form-select"
                                  aria-label="Default select example"
                                >
                                  <option selected>In Progress</option>
                                  <option value="1">Complete</option>
                                  <option value="2">Cancelled</option>
                                </select>
                                <button className="cx-btn-dark">Update</button>
                              </div> */}
                                  <div className="col-lg-12">
                                    <div className="status_table_wrapper">
                                      <div className="table-wrapper ReportSiteTable table_wrapper_pagination">
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>Date</th>
                                              <th>Status</th>
                                              <th>User</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {CustomerproductStatus &&
                                            CustomerproductStatus.length > 0 ? (
                                              CustomerproductStatus.map(
                                                (itemlist, index) => {
                                                  return (
                                                    <tr>
                                                      <td>{itemlist.date}</td>
                                                      <td>{itemlist.status}</td>
                                                      <td>{itemlist.user}</td>
                                                    </tr>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <></>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="3">
                              <div className="innerContain">
                                <div className="row">
                                  <div className="col-lg-6 borderLeft">
                                    <table>
                                      {payment_details &&
                                      payment_details.length > 0 ? (
                                        payment_details.map(
                                          (itemlist, index) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td className="labelName">
                                                    Order ID
                                                  </td>
                                                  <td className="ContainName">
                                                    #
                                                    {itemlist.pet_shop_order_id}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="labelName">
                                                    Order Date
                                                  </td>
                                                  <td className="ContainName">
                                                    {getDate(
                                                      itemlist.pet_shop_order_date
                                                    )}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="labelName">
                                                    Delivery Date
                                                  </td>
                                                  <td className="ContainName">
                                                    {" "}
                                                    {getDate(
                                                      itemlist.delivery_date
                                                    )}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="labelName">
                                                    No. of Items
                                                  </td>
                                                  <td className="ContainName">
                                                    {itemlist.no_of_items}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="labelName pb-0">
                                                    Total Amount
                                                  </td>
                                                  <td className="ContainName pb-0">
                                                    ₹{itemlist.total_amount}
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </table>
                                  </div>
                                  <div className="col-lg-6 ps-4">
                                    <table>
                                      {payment_details &&
                                      payment_details.length > 0 ? (
                                        payment_details.map(
                                          (itemlist, index) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td className="labelName">
                                                    Mode Of Payment
                                                  </td>
                                                  <td className="ContainName">
                                                    {itemlist.payment_mode}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="labelName">
                                                    Transaction ID
                                                  </td>
                                                  <td className="ContainName">
                                                    #{itemlist.transaction_id}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="labelName">
                                                    Transaction Date
                                                  </td>
                                                  <td className="ContainName">
                                                    {moment(
                                                      itemlist.transaction_date
                                                    ).format("DD-MM-YYYY")}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="labelName">
                                                    Payment Status
                                                  </td>
                                                  <td className="ContainName">
                                                    {itemlist.paymet_status}
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="4">
                              <div className="review_ratings_wrapper">
                                {CustomerproductReview &&
                                CustomerproductReview.length > 0 ? (
                                  CustomerproductReview.map(
                                    (itemlist, index) => {
                                      return (
                                        <>
                                          <div className="review_title_wrapper">
                                            <p className="review_title">
                                              {itemlist.review_title}
                                            </p>
                                            <div className="rating_star_wrapper">
                                              <img src={Star} alt="" />
                                              <span>{itemlist.rating}</span>
                                            </div>
                                          </div>
                                          <div className="review_description">
                                            <p className="review_text">
                                              {itemlist.review}
                                            </p>
                                            <div className="review_img_wrapper">
                                              {/* <img src={review1} alt="" />
                                <img src={review2} alt="" />
                                <img src={review3} alt="" />
                                <img src={review4} alt="" /> */}
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="review_ratings_wrapper no_reviews">
                                <img src={no_review_icon} alt="" />
                                <p className="no_review_text">
                                  No Reviews Found...
                                </p>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>

                      <div className="prescription_wrapper">
                        <p className="pw_title">Prescription</p>
                        {/* <div
                      className="pw_card"
                      onClick={() => setClickedOrder(true)}
                    >
                      <img src={prescription_icon} alt="" />
                      <p className="pwc_text">{getDate()}</p>
                    </div> */}
                      </div>
                      <div className="product_summary_wrapper ">
                        <div className="table-wrapper ReportSiteTable table_wrapper_pagination dasboard-cust-tb-wrapper mb-3">
                          <div className="lc_top">
                            <div className="lct_header_wrapper">
                              <p>Product Summary</p>
                            </div>
                          </div>
                          <table>
                            <thead>
                              <tr>
                                <th>Sr. No</th>
                                <th>Particular</th>
                                <th>Quantity</th>
                                <th>Rate</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Orderproduct_summary &&
                              Orderproduct_summary.length > 0 ? (
                                Orderproduct_summary.map((itemlist, index) => {
                                  return (
                                    <tr>
                                      <td>1</td>
                                      <td>
                                        <label htmlFor="" className="photo">
                                          <img
                                            src={itemlist.product_image_path}
                                            alt=""
                                          />
                                          {itemlist.product}
                                        </label>
                                      </td>
                                      <td>{itemlist.quantity}</td>
                                      <td>₹{itemlist.product_rate}</td>
                                      <td>₹{itemlist.amount}</td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div className="order_status_wrapper_bottom order_complete">
                    <img src={approved} alt="" />
                    <p>Order Successfully Completed !</p>
                  </div> */}
                    </div>
                    <div className="col-lg-4 mt-4">
                      {/* <div className="photograph_wrapper">
                    <div class="lc_top">
                      <div class="lct_header_wrapper">
                        <p>Pet Photographs (4)</p>
                      </div>
                    </div>
                    <div className="photograph_wrapper_inner">
                      <div className="row">
                        <div className="col-lg-8">
                          <img
                            src={
                             
                              CustomerproductImage[0]?.animal_photograph_path
                            }
                            alt=""
                          />
                        </div>
                        <div className="col-lg-4">
                          <div className="right_photos_list">
                            {CustomerDetails[1]?.animal_photograph_path ===
                              null ||
                            CustomerDetails[1]?.animal_photograph_path ===
                              "" ? (
                              <></>
                            ) : (
                              <>
                                <img
                                  src={
                                   
                                    CustomerproductImage[1]
                                      ?.animal_photograph_path
                                  }
                                  alt=""
                                />
                              </>
                            )}
                            {CustomerDetails[2]?.animal_photograph_path ===
                              null ||
                            CustomerDetails[2]?.animal_photograph_path ===
                              "" ? (
                              <></>
                            ) : (
                              <>
                                <img
                                  src={
                                   
                                    CustomerproductImage[2]
                                      ?.animal_photograph_path
                                  }
                                  alt=""
                                />
                              </>
                            )}
                            {CustomerDetails[3]?.animal_photograph_path ===
                              null ||
                            CustomerDetails[3]?.animal_photograph_path ===
                              "" ? (
                              <></>
                            ) : (
                              <>
                                <img
                                  src={
                                   
                                    CustomerproductImage[3]
                                      ?.animal_photograph_path
                                  }
                                  alt=""
                                />
                              </>
                            )}
                         
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                      <div className="right">
                        <div className="visits-tab-wrapper">
                          <div class="lc_top">
                            <div class="lct_header_wrapper">
                              <p>Previous Orders ({TotalViste})</p>
                            </div>
                          </div>
                          <div className="today-heading mt-3">
                            <label htmlFor="" className="key">
                              Today
                            </label>
                            <div className="line"></div>
                          </div>
                          <>
                            {OrderproductVisteToday &&
                            OrderproductVisteToday.length > 0 ? (
                              OrderproductVisteToday.map((itemlist, index) => {
                                console.log(itemlist);
                                return (
                                  <>
                                    <div className="app-time-date active mainBoxy">
                                      {itemlist.visits &&
                                      itemlist.visits.length > 0 ? (
                                        itemlist.visits.map((list, index) => {
                                          return (
                                            <>
                                              <div
                                                className="left-app-time-date boxyFormat"
                                                onClick={() => {
                                                  getDetailsByeId(
                                                    list.pet_shop_order_id
                                                  );
                                                }}
                                              >
                                                <div className="firstDiv">
                                                  <label
                                                    htmlFor=""
                                                    className="value"
                                                  >
                                                    Order ID : #
                                                    {list.pet_shop_order_id}
                                                  </label>
                                                  <br />
                                                  <label
                                                    htmlFor=""
                                                    className="status"
                                                  >
                                                    {list.total_amount} |{" "}
                                                    {/* <img
                                                      src={green_check_icon}
                                                      alt=""
                                                    />{" "} */}
                                                    {list.status}
                                                  </label>
                                                </div>
                                                <div className="right-app-time-date">
                                                  <img
                                                    src={ic_right_arrow}
                                                    alt=""
                                                  />
                                                </div>
                                                {/* <div>
                                                </div> */}
                                              </div>
                                            </>
                                          );
                                        })
                                      ) : (
                                        <>No data </>
                                      )}
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <p className="scheule_tody">No Schedule Today</p>
                            )}

                            {OrderproductViste &&
                            OrderproductViste.length > 0 ? (
                              OrderproductViste.map((itemlist, index) => {
                                console.log(itemlist);
                                return (
                                  <>
                                    <div className="today-heading">
                                      <label
                                        htmlFor=""
                                        className="key line-style"
                                      >
                                        {itemlist.visit_date}
                                      </label>

                                      {/* <div className="line"></div> */}
                                    </div>

                                    {itemlist.visits &&
                                    itemlist.visits.length > 0 ? (
                                      itemlist.visits.map((list, index) => {
                                        console.log(itemlist);
                                        return (
                                          <>
                                            <div
                                              className="app-time-date"
                                              onClick={() => {
                                                getDetailsByeId(
                                                  list.pet_shop_order_id
                                                );
                                              }}
                                            >
                                              <div className="left-app-time-date">
                                                <label
                                                  htmlFor=""
                                                  className="value"
                                                >
                                                  Order ID : #
                                                  {list.pet_shop_order_id}
                                                </label>
                                                <div>
                                                  <label
                                                    htmlFor=""
                                                    className="status"
                                                  >
                                                    ₹ {list.total_amount} |{" "}
                                                    {/* <img
                                                      src={green_check_icon}
                                                      alt=""
                                                    />{" "} */}
                                                    {list.status}
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="right-app-time-date">
                                                <img
                                                  src={ic_right_arrow}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              })
                            ) : (
                              <>
                                <p className="scheule_tody">No history</p>
                              </>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <PrescriptionComp />
            </div>
          </div>
          {/* Modal For Order */}
          <Modal
            show={clickedOrder}
            onHide={() => setClickedOrder(false)}
            size="lg"
          >
            <Modal.Body>
              <div className="orderModalInvoice">
                <div className="modalHeading">
                  <div className="upperBlue">
                    <h1 className="docName">
                      Dr.{Customerprescription.doctor_name}{" "}
                    </h1>
                    <label className="domainLab"> </label>
                    <label className="certificateLab">
                      Certifcation {Customerprescription.certification_no}
                    </label>
                  </div>
                  <div className="docSymbolBox">
                    <img src={DocSYmbol} className="docSymImg" alt="" />
                  </div>
                </div>
                <div className="modalBody">
                  <div className="detailsFill">
                    <div className="labelTxt">
                      <label className="widthFixed">Patient Name:</label>
                    </div>
                    <input
                      type="text"
                      value={Customerprescription.animal_name}
                      className="form-control formSolution"
                    />
                  </div>
                  <div className="addressFlex">
                    <div className="labelTxt">
                      <label className="keyQuestion">Address:</label>
                    </div>
                    <input
                      type="text"
                      value={Customerprescription.customer_address}
                      className="form-control formSolution"
                    />
                  </div>
                  <div className="dateNdAgeFlex">
                    <div className="ageBox">
                      <label className="ageLab">Age:</label>
                      <input
                        type="text"
                        value={Customerprescription.animal_age}
                        className="form-control formSolution"
                      />
                    </div>
                    <div className="dateBox">
                      <label className="ageLab">Date:</label>
                      <input
                        type="text"
                        value={getDate(Customerprescription.date)}
                        className="form-control formSolution"
                      />
                    </div>
                  </div>
                  <div className="addressFlex">
                    <div className="labelTxt">
                      <label className="keyQuestion">Diagnosis:</label>
                    </div>
                    <input
                      type="text"
                      value={Customerprescription.appointment_desc}
                      className="form-control formSolution"
                    />
                  </div>
                  <div className="tableAndLandingBg">
                    <div className="backGroundPic"></div>
                    <div className="innerTable">
                      <table className="modalTable">
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Prescription</th>
                            <th>Quantity</th>
                            <th>Dose</th>
                          </tr>
                        </thead>
                        <tbody>
                          {CustomerprescriptionTable &&
                          CustomerprescriptionTable.length > 0 ? (
                            CustomerprescriptionTable.map((itemlist, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{itemlist.prescription}</td>
                                    <td>{itemlist.quantity}</td>
                                    <td>{itemlist.dose}</td>
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="signatureContent">
                    <div className="sigBox">
                      <label htmlFor="sigNatureFor" className="signaturePad">
                        <img src={Signature} alt="" />
                        <input
                          type="file"
                          id="sigNatureFor"
                          className="d-none"
                        />
                      </label>
                      <label className="belowTxt">SIGNATURE</label>
                    </div>
                  </div>
                </div>
                <div className="belowDetails">
                  <div className="imgWrapper">
                    <div className="row heightAuto">
                      <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                        <div className="txt">
                          <div className="innerTxt">Contact</div>
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-12 p-0">
                        <div className="allCOntact">
                          <div className="emailAndCall">
                            <div className="callNnumber">
                              <img src={CallIcon} alt="" />
                              <label className="numberInn">
                                55 47 79 94 15,55 47 79 94 18
                              </label>
                            </div>
                            <div className="emialInn">
                              <img src={EmailIcon} alt="" />
                              <label className="numberInn">
                                55 47 79 94 15,55 47 79 94 18
                              </label>
                            </div>
                          </div>
                          <div className="locationInn">
                            <img src={Location} alt="" />
                            <label className="locationTxt">
                              125/2, Kalas Road, Vishrantwadi, Pune - 411041.
                              Maharashtra, India
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default CustomerDetails;
