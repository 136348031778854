// const PAYMENT_URL = "https://api.theanimalsapp.com:3000/api/paymentRoute/";


// ================================ LOCAL SERVER =============================== //
// export const BASE_URL = "http://192.168.1.20:3000/"; // VINOD PC IP
// ================================ LOCAL SERVER =============================== //

// ================================= DEVELOPMENT SERVER ======================== //
// export const BASE_URL = "https://animal-api.app.redbytes.in:3000/";
// ================================= DEVELOPMENT SERVER ======================== //

// ================================= PRODUCTION SERVER ======================== //
export const BASE_URL = "https://api.theanimalsapp.com:3000/";
// ================================= PRODUCTION SERVER ======================== //

const PETSHOP_PATH = "api/petshop/";
const PAYMENT_PATH = "api/paymentRoute/";

const ApiConfig = {
  REGISTER_USER: BASE_URL + PETSHOP_PATH + "registration",
  GET_OTP: BASE_URL + PETSHOP_PATH + "sendOtp",
  FORGET_PASSWORD: BASE_URL + PETSHOP_PATH + "forgotPassword",
  RESET_PASSWORD: BASE_URL + PETSHOP_PATH + "resetPassword",
  LOGIN_USER: BASE_URL + PETSHOP_PATH + "login",
  LOGIN_WITH_OTP: BASE_URL + PETSHOP_PATH + "loginWithOTP",
  SEND_OTP_FOR_LOGIN: BASE_URL + PETSHOP_PATH + `sendOtp?loginOTP=${true}`,
  ADD_BANK_DETAILS: BASE_URL + PETSHOP_PATH + "addBank",
  ADD_SHOP_DETAILS: BASE_URL + PETSHOP_PATH + "addPetShop",
  GET_USER_PROFILE_DETAILS: BASE_URL + PETSHOP_PATH + "getProfileDetails/",
  GET_LIST_OF_LANGUAGAES: BASE_URL + PETSHOP_PATH + "profileLookUpData",
  UPDATE_PROFILE_IN_FORM_DATA: BASE_URL + PETSHOP_PATH + "updateProfile",
  DELETE_DOCS_WITH_ID: BASE_URL + PETSHOP_PATH + "deleteDocument/",
  ADD_EDIT_LANGUAGE: BASE_URL + PETSHOP_PATH + "addEditLanguage",
  CHANGE_PROFILE_PHOTO: BASE_URL + PETSHOP_PATH + "changeProfileImage",
  UPDATE_BANK_DETAILS: BASE_URL + PETSHOP_PATH + "updateBank",
  // Notication ///
  Notification_LIST: `${BASE_URL}api/pharmacy/getNotification/`,
  // ============================ HEADER SECTION ======================== //
  UPDATE_PETSHOP_STATUS: BASE_URL + PETSHOP_PATH + "changePetShopStatus",
  DASHBORD_LIST: BASE_URL + PETSHOP_PATH + "getDashboardData?pet_shop_id=",

  // Inventory
  INVENTORY_PRODUCT_LIST:
    BASE_URL + PETSHOP_PATH + "getProductsList?pet_shop_id=",
  INVENTORY_PRODUCT_CATEGORYLIST:
    BASE_URL + PETSHOP_PATH + "getCategoryList?pet_shop_id=",
  ENABLE_DISABLED: BASE_URL + PETSHOP_PATH + "disable-product",
  ADD_INVENTORY_DROPDOWN: BASE_URL + PETSHOP_PATH + "getCategoryLookUpData",
  ADD_PRODUCT_DROPDOWN: BASE_URL + PETSHOP_PATH + "getProductLookUpData",
  ADD_PRODUCT: BASE_URL + PETSHOP_PATH + "addProduct",
  EDIT_BYE_DETAILS: BASE_URL + PETSHOP_PATH + "petShopProduct/",
  PRODUCT_EDIT: BASE_URL + PETSHOP_PATH + "editProduct",

  //Order
  ORDER_DETALIS: BASE_URL + PETSHOP_PATH + "orders?pet_shop_id=",
  ORDER_DETALIS_BY_ID: BASE_URL + PETSHOP_PATH + "orders/",
  ORDER_BY_VISTE: BASE_URL + PETSHOP_PATH + "getVisits",
  GET_STATUS_DROPDOWN: BASE_URL + PETSHOP_PATH + "getOrderStatusLookUpData",
  UPDATE_STATUS: BASE_URL + PETSHOP_PATH + "updateOrderStatus",

  /// Customer

  CUSTOMER_LIST: BASE_URL + PETSHOP_PATH + "customers?pet_shop_id=",
  CUSTOMER_DETALIS_BY_ID: BASE_URL + PETSHOP_PATH + "customers/",

  /// SELES
  SELES_DALIY_REPORT: BASE_URL + PETSHOP_PATH + "dailySalesReport",
  SELES_MONTHE_REPOT: BASE_URL + PETSHOP_PATH + "monthlySalesReport",
  FETCH_PAYMENT_ANALYTICS_DETAILS: BASE_URL + PETSHOP_PATH + "paymentAnalysis",
  // Inventory
  INVENTORY_REPORT_DAILT: BASE_URL + PETSHOP_PATH + "dailyInventoryReport",
  INVENTORY_MONTHE_REPORT: BASE_URL + PETSHOP_PATH + "monthlyInventoryReport",

  // Transaction
  TRANSACTION_RECENT_LIST:
    BASE_URL + PETSHOP_PATH + "getRecentTransactions?pet_shop_id=",
  TRANSACTION_UPCOMING_LIST: BASE_URL + PETSHOP_PATH + "getUpcomingPayments/",

  // SUBSCRIPTIONS APIs
  SUBSCRIPTIONS_APIS: BASE_URL + PETSHOP_PATH + "subscriptions",
  REQUEST_SUBSCRIPTIONS: BASE_URL + PETSHOP_PATH + "requestSubscription",
  // SUBSCRIPTIONS APIs

  // Payment Withdraw api
  GET_PAYMENT_DROPDOWN: BASE_URL + PAYMENT_PATH + "bankAccounts",
  POST_WITHDRAW_REQUEST: BASE_URL + PAYMENT_PATH + "withdrawRequest",
};

export default ApiConfig;
